import { Injectable, OnDestroy } from '@angular/core';

import { ShareMyBetConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { NativeAppService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BridgeEvent } from '../event-bridge/event-bridge.service';
import { ToasterService } from '../modal/toaster/toaster.service';

@Injectable()
export class ShareMyBetNativeMessageService implements OnDestroy {
    private subscription: Subscription | undefined;

    constructor(
        private toaster: ToasterService,
        private nativeAppService: NativeAppService,
        private sitecore: Sitecore,
        private shareMyBetConfig: ShareMyBetConfig,
    ) {}

    listen(): void {
        this.subscription = this.nativeAppService.eventsFromNative
            .pipe(filter((e) => e.eventName === BridgeEvent.BetSharedSuccessfully))
            .subscribe(() => this.showToasterMessage());
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    private showToasterMessage(): void {
        this.toaster.open(this.sitecore.shareMyBet.messages.ShareSuccess, { duration: this.shareMyBetConfig.globalErrorVisibilityTime });
    }
}
