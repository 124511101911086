import { Injectable } from '@angular/core';

import { BetType } from '@bpos/common';
import { SportConstant } from '@frontend/sports/common/base-utils';
import { EarlyPayoutConfig } from '@frontend/sports/common/client-config-data-access';

import { UserService } from '../../user/services/user.service';
import { EarlyPayoutFailedResponse } from '../models/early-payout';
import { EarlyPayoutConst, EarlyPayoutErrorTypes, EarlyPayoutStates, PayoutError } from '../models/early-payout-types';
import { EarlyPayout, MyBetsBetSlip, MyBetsBetslipBase } from '../models/my-bets-viewmodels';

@Injectable({ providedIn: 'root' })
export class EarlyPayoutUtils {
    constructor(
        private epConfig: EarlyPayoutConfig,
        private m2User: UserService,
    ) {}

    isEarlyPayoutAvailable(betslip: MyBetsBetSlip): boolean {
        return this.isEarlyPayoutAllowed() && this.isEarlyPayoutAvailableForBetslip(betslip);
    }

    isEarlyPayoutAllowed(): boolean {
        return this.epConfig.isEarlyPayoutEnabled && !this.m2User.bettingSettings.earlyPayoutDeactivated && this.m2User.isEarlyPayoutAllowed;
    }

    isEditBetDisabled(betslip: MyBetsBetSlip): boolean {
        return (
            betslip.isFreeBet ||
            !!betslip.sgpType ||
            (!!betslip.earlyPayout && betslip.earlyPayout.earlyPayoutPossible && !betslip.earlyPayout.editBetPossible)
        );
    }

    private isEarlyPayoutAvailableForBetslip(betslip: MyBetsBetSlip): boolean {
        return Boolean(betslip.earlyPayout && betslip.isPotentialEP && !betslip.hideEP);
    }

    onlyAllowedBetTypes(betslip: MyBetsBetslipBase): boolean {
        if (betslip instanceof MyBetsBetSlip) {
            return betslip.bets.every(
                (b) =>
                    b.sportId !== SportConstant.Horses &&
                    (b.resultInfo ||
                        (b.optionMarketInfo && b.optionMarketInfo.betType !== BetType.EachWay) ||
                        (b.betBuilderInfo && b.betBuilderInfo.betBuilderType === 'Ogp')),
            );
        }

        return false;
    }

    handleEarlyPayoutPlaceError(response: EarlyPayoutFailedResponse, earlyPayout?: EarlyPayout): void {
        if (!response || !response.error) {
            return;
        }

        const error = response.error;
        const acceptMode = (earlyPayout && earlyPayout.acceptMode) || EarlyPayoutConst.ModeHigher;
        earlyPayout = earlyPayout || new EarlyPayout();

        if (error.type !== EarlyPayoutErrorTypes.CashoutAmountChanged) {
            earlyPayout.state = EarlyPayoutStates.Disabled;
            earlyPayout.error = error;
        } else if (response.possibleAmountWithoutStake !== undefined && response.possibleAmountWithoutStake <= 0) {
            // When error is CashoutAmountChanged, bet is placed with freeBet (possibleAmountWithoutStake has value)
            // and possibleAmountWithoutStake has changed to lower or equal to Stake
            earlyPayout.state = EarlyPayoutStates.Disabled;
            earlyPayout.error = <PayoutError>{ type: EarlyPayoutErrorTypes.CashoutAmountLowerOrEqualToFreeBet };
        } else {
            earlyPayout.value = error.possibleAmount || 0;
            earlyPayout.valueWithoutStake = response.possibleAmountWithoutStake;
            earlyPayout.state = EarlyPayoutStates.Allowed;
            earlyPayout.error = error;
        }

        earlyPayout.acceptMode = acceptMode;
    }
}
