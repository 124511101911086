import { Deferred } from '@frontend/sports/common/base-utils';

import { EPSDetailedLogger } from '../../logging/eps-detailed-logger.service';
import { LoggerFactory } from '../../logging/logger-factory.service';
import { SportsRemoteLogger } from '../../logging/sports-remote-logger.service';
import { UserService } from '../../user/services/user.service';
import { EarlyPayoutPossibility, EarlyPayoutsWithBetslip } from '../models/early-payout';
import { EarlyPayoutConst, EarlyPayoutStates } from '../models/early-payout-types';
import { EarlyPayout, MyBetsBetslipBase } from '../models/my-bets-viewmodels';
import { EarlyPayoutSubmitService } from './early-payout-submit.service';
import { EarlyPayoutSubscriptionMapperService } from './early-payout-subscription-mapper.service';
import { EarlyPayoutSubscriptionService } from './early-payout-subscription.service';
import { EarlyPayoutService } from './early-payout.service';
import { EarlyPayoutHelpers } from './my-bets-earlypayout-helpers';

export interface IMyBetsEarlyPayoutSubscription {
    dropEarlyPayoutSubscription(): void;
}

export abstract class MyBetsEarlyPayoutBaseService implements IMyBetsEarlyPayoutSubscription {
    protected cancelToken: Deferred<void> | null = null;
    protected callback?: (ep: EarlyPayout) => void;
    protected cancelEnhancementToken: Deferred<void> | null = null;
    protected readonly logger: SportsRemoteLogger;

    constructor(
        protected earlyPayoutService: EarlyPayoutService,
        protected epSubService: EarlyPayoutSubscriptionService,
        protected epHelpers: EarlyPayoutHelpers,
        protected loggerFactory: LoggerFactory,
        protected msUser: UserService,
        protected earlyPayoutSubmitService: EarlyPayoutSubmitService,
        protected epsLogger: EPSDetailedLogger,
        protected earlyPayoutSubscriptionMapperService: EarlyPayoutSubscriptionMapperService,
    ) {
        this.cancelToken = new Deferred<void>();
        this.cancelEnhancementToken = new Deferred<void>();
        this.logger = loggerFactory.getLogger('MyBetsEarlyPayoutService');
    }

    protected abstract isPotentialEarlyPayoutable(betslip: MyBetsBetslipBase): boolean;

    protected abstract prepareEarlyPayouts(
        earlyPayouts: EarlyPayoutPossibility[],
        potentialEPbetslips: MyBetsBetslipBase[],
        callback: (ep: EarlyPayoutsWithBetslip) => void,
    ): Promise<void>;

    private mapEarlyPayoutPossibility(ep: EarlyPayoutPossibility, betslipEp: EarlyPayout | undefined): EarlyPayout {
        return <EarlyPayout>{
            value: ep.earlyPayoutValue,
            state: EarlyPayoutStates.Allowed,
            acceptMode: this.getDefaultUserAcceptMode(),
            earlyPayoutPossible: ep.earlyPayoutPossible,
            editBetPossible: (betslipEp && betslipEp.editBetPossible) || ep.editBetPossible,
            resultedPicks: ep.resultedPicks,
            error: ep.earlyPayoutError,
        };
    }

    protected callbackFunction = (ep: EarlyPayoutsWithBetslip): void => {
        if (this.callback) {
            this.callback(this.mapEarlyPayoutPossibility(ep.earlyPayout, ep.betslip.earlyPayout));
        }
    };

    async mapEarlyPayoutAmount(betslips: MyBetsBetslipBase[], callback?: (ep: EarlyPayout) => void): Promise<void> {
        this.cancelToken = this.refreshCancelationToken();
        this.callback = callback;

        const potentialEPbetslips: MyBetsBetslipBase[] = [];
        const betslipIds: string[] = [];
        for (const betslip of betslips) {
            betslip.isPotentialEP = this.isPotentialEarlyPayoutable(betslip);
            if (betslip.isPotentialEP) {
                potentialEPbetslips.push(betslip);
                betslipIds.push(betslip.betslipRealId);
            }
        }

        if (betslipIds.length) {
            try {
                const result = await this.earlyPayoutService.checkEarlyPayoutAndSubscribe(betslipIds, this.cancelToken);
                if (result.earlyPayouts) {
                    result.earlyPayouts.map(
                        (ep) => (ep.earlyPayoutError = this.earlyPayoutSubscriptionMapperService.mapToEarlyPayoutError(ep.earlyPayoutError)),
                    );
                    await this.prepareEarlyPayouts(result.earlyPayouts, potentialEPbetslips, this.callbackFunction);
                }
            } catch (error: any) {
                if (error && error.status === -1) {
                    // request was probably canceled
                    return;
                }
                this.logger.error(error, 'Failed to load early payout values.');
            }
        }
    }

    protected adjustPayoutStatus(potentialEPbetslips: MyBetsBetslipBase[]): void {
        potentialEPbetslips.forEach((betslip) => this.earlyPayoutSubmitService.adjustPayoutStatus(betslip));
    }

    protected async enhanceEligibleBetslips(eligibleBetslips: EarlyPayoutPossibility[]): Promise<EarlyPayoutPossibility[]> {
        if (!eligibleBetslips.length) {
            return [];
        }

        const betNumbers = eligibleBetslips.map((slip) => slip.betNumber);

        this.cancelEnhancementToken = this.refreshCancelationEnhancementToken();

        try {
            const result = await this.earlyPayoutService.enhanceEarlyPayout(betNumbers, this.cancelEnhancementToken);
            if (result && result.length) {
                for (const current of result) {
                    const index = eligibleBetslips.findIndex((el) => el.betNumber === current.betNumber);

                    eligibleBetslips[index].autoCashOutValue = current.autoCashoutValue || 0;
                    eligibleBetslips[index].autoCashoutNotificationValue = current.autoCashoutNotificationValue || 0;
                }
            }

            eligibleBetslips.forEach((r) => this.epsLogger.logCheckReponse(r));

            return eligibleBetslips;
        } catch (error: any) {
            if (error && error.status === -1) {
                return eligibleBetslips;
            }
            this.logger.error(error, 'Failed to enhance early payout values.');

            return eligibleBetslips;
        }
    }

    protected mapEarlyPayouts(
        earlyPayouts: EarlyPayoutPossibility[],
        potentialEPbetslips: MyBetsBetslipBase[],
        callback: (ep: EarlyPayoutsWithBetslip) => void,
    ): void {
        this.epHelpers.mapPermanentlyDisabledEarlyPayouts(earlyPayouts, potentialEPbetslips);
        this.epHelpers.mapDisabledEarlyPayouts(potentialEPbetslips);
        this.epHelpers.mapHiddenEarlyPayouts(potentialEPbetslips);

        const eligibleEPbetslips = this.mapMatchedEpBetslips(potentialEPbetslips, earlyPayouts, true);
        const notEligibleEPbetslips = this.mapMatchedEpBetslips(potentialEPbetslips, earlyPayouts, false);
        this.epSubService.subscribeEarlyPayoutChanges(potentialEPbetslips, eligibleEPbetslips.concat(notEligibleEPbetslips), true, callback);

        this.adjustPayoutStatus(potentialEPbetslips);
    }

    protected mapMatchedEpBetslips(
        potentialEPbetslips: MyBetsBetslipBase[],
        earlyPayouts: EarlyPayoutPossibility[],
        eligibleBetslips: boolean,
    ): MyBetsBetslipBase[] {
        if (!earlyPayouts || !earlyPayouts.length) {
            return [];
        }

        const betsEpToMatch = eligibleBetslips
            ? earlyPayouts.filter((ep) => !!ep.earlyPayoutPossible)
            : earlyPayouts.filter((ep) => !ep.earlyPayoutPossible);
        const betslipWithEPBetslip = this.epHelpers.mapEarlyPayoutsWithBetslip(potentialEPbetslips, betsEpToMatch);

        betslipWithEPBetslip.forEach((match) => {
            if (eligibleBetslips) {
                match.betslip.earlyPayout = <EarlyPayout>{
                    value: match.earlyPayout.earlyPayoutValue,
                    valueWithoutStake: this.epHelpers.getValueWithoutFreeBetStake(
                        match.betslip.stake.value,
                        match.earlyPayout.earlyPayoutValue,
                        match.betslip.isFreeBet,
                    ),
                    state: EarlyPayoutStates.Allowed,
                    acceptMode: this.getDefaultUserAcceptMode(),
                    earlyPayoutPossible: match.earlyPayout.earlyPayoutPossible,
                    editBetPossible: match.earlyPayout.editBetPossible,
                    autoCashoutNotificationValue: match.earlyPayout.autoCashoutNotificationValue,
                    autoCashoutNotificationValueWithoutStake: this.epHelpers.getValueWithoutFreeBetStake(
                        match.betslip.stake.value,
                        match.earlyPayout.autoCashoutNotificationValue,
                        match.betslip.isFreeBet,
                    ),
                    autoCashOutValue: match.earlyPayout.autoCashOutValue,
                    autoCashOutValueWithoutStake: this.epHelpers.getValueWithoutFreeBetStake(
                        match.betslip.stake.value,
                        match.earlyPayout.autoCashOutValue,
                        match.betslip.isFreeBet,
                    ),
                    resultedPicks: match.earlyPayout.resultedPicks || [],
                    error: match.earlyPayout.earlyPayoutError,
                };
            } else {
                match.betslip.earlyPayout = match.betslip.earlyPayout || new EarlyPayout();
                match.betslip.earlyPayout.earlyPayoutPossible = match.earlyPayout.earlyPayoutPossible;
                match.betslip.earlyPayout.editBetPossible = !!match.earlyPayout.editBetPossible;
                match.betslip.earlyPayout.autoCashoutNotificationValue = match.earlyPayout.autoCashoutNotificationValue;
                match.betslip.earlyPayout.autoCashoutNotificationValueWithoutStake = this.epHelpers.getValueWithoutFreeBetStake(
                    match.betslip.stake.value,
                    match.earlyPayout.autoCashoutNotificationValue,
                    match.betslip.isFreeBet,
                );
                match.betslip.earlyPayout.autoCashOutValue = match.earlyPayout.autoCashOutValue;
                match.betslip.earlyPayout.autoCashOutValueWithoutStake = this.epHelpers.getValueWithoutFreeBetStake(
                    match.betslip.stake.value,
                    match.earlyPayout.autoCashOutValue,
                    match.betslip.isFreeBet,
                );
                match.betslip.earlyPayout.resultedPicks = match.earlyPayout.resultedPicks || [];
                match.betslip.earlyPayout.error = match.earlyPayout.earlyPayoutError;
            }
        });

        return betslipWithEPBetslip.map((slip: EarlyPayoutsWithBetslip) => slip.betslip);
    }

    private getDefaultUserAcceptMode(): string {
        return this.msUser.bettingSettings.earlyPayoutAcceptanceMode === EarlyPayoutConst.ModeAny
            ? EarlyPayoutConst.ModeAny
            : EarlyPayoutConst.ModeHigher;
    }

    protected refreshCancelationToken(): Deferred<void> {
        if (this.cancelToken) {
            this.cancelToken.resolve();
        }

        return new Deferred<void>();
    }

    private refreshCancelationEnhancementToken(): Deferred<void> {
        if (this.cancelEnhancementToken) {
            this.cancelEnhancementToken.resolve();
        }

        return new Deferred<void>();
    }

    private dropCancelationToken(): void {
        if (this.cancelToken) {
            this.cancelToken.resolve();
        }
        this.cancelToken = null;

        if (this.cancelEnhancementToken) {
            this.cancelEnhancementToken.resolve();
        }
        this.cancelEnhancementToken = null;
    }

    dropEarlyPayoutSubscription(): void {
        this.dropCancelationToken();
        this.epSubService.unsubscribeEarlyPayoutService();
    }
}
