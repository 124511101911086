import { Injectable } from '@angular/core';

import { ISavedState, StateLoader, StateSaver } from '../store-persist/state-storage.model';
import { IShareMyBetState, ISharingState, defaultShareMyBetState } from './share-my-bet.state';

export interface IShareMyBetStateStorage {
    sharingStates: { [key: string]: ISharingState };
}

export interface IShareMyBetSavedState extends ISavedState {
    shareMyBet: IShareMyBetStateStorage;
}

export function isShareMyBetSavedState(savedState: ISavedState | undefined): savedState is IShareMyBetSavedState {
    return !!savedState && 'shareMyBet' in savedState;
}

@Injectable()
export class ShareMyBetStateSaver implements StateSaver<IShareMyBetState, IShareMyBetStateStorage> {
    save(state: IShareMyBetState): IShareMyBetStateStorage {
        return {
            sharingStates: state.sharingStates,
        };
    }
}

@Injectable()
export class ShareMyBetStateLoader implements StateLoader<IShareMyBetStateStorage, IShareMyBetState> {
    load(state: IShareMyBetStateStorage | undefined): IShareMyBetState {
        if (!state) {
            return { ...defaultShareMyBetState };
        }

        return {
            ...defaultShareMyBetState,
            sharingStates: state.sharingStates,
        };
    }
}
