import { Injectable } from '@angular/core';

import { OddsFormatSettings, SportsUserSettings } from '@frontend/sports/common/client-config-data-access';
import { ApiService } from '@frontend/sports/common/core/feature/http';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { OddsDisplayFormat } from '@frontend/sports/types/models/user-settings';
import { ClientConfigService } from '@frontend/vanilla/core';
import { firstValueFrom, from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { IUserBettingSettings, UserEvents, UserSettingsResponse } from '../model/user.models';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class UserSettingsService {
    static readonly NOT_AUTHENTICATED = 'NotAuthenticated';
    constructor(
        private userService: UserService,
        private apiService: ApiService,
        protected dispatcher: DispatcherService,
        private clientConfig: ClientConfigService,
    ) {}

    /**
     * Set a value for a specific setting. All properties are optional, it can be used to set any setting independently:
     *
     * UserSettings.set({ earlyPayoutAcceptanceMode: "AcceptAnyChanges" });
     *
     * @param settings
     * @returns
     *
     * @memberOf UserSettings
     */
    async set(settings: Partial<IUserBettingSettings>): Promise<UserSettingsResponse> {
        if (!this.userService.isAuthenticated) {
            throw new Error(UserSettingsService.NOT_AUTHENTICATED);
        }

        const apiResult = await firstValueFrom(
            this.apiService.post<UserSettingsResponse>('usersettings', { settings }).pipe(
                switchMap((result: UserSettingsResponse) => {
                    return from(this.clientConfig.reload([SportsUserSettings, OddsFormatSettings])).pipe(map(() => result));
                }),
            ),
        );

        this.dispatcher.dispatch(UserEvents.M2UserClaimsChanged);

        return apiResult;
    }

    async setOddsFormat(format: OddsDisplayFormat): Promise<UserSettingsResponse> {
        if (!this.userService.isAuthenticated) {
            throw new Error('NotAuthenticated');
        }

        const apiResult = await firstValueFrom(
            this.apiService.post<UserSettingsResponse>('usersettings/saveoddsformat', { format }).pipe(
                switchMap((result: UserSettingsResponse) => {
                    if (result.oddsFormatLimitReached) {
                        return of(result);
                    }

                    return from(this.clientConfig.reload([SportsUserSettings, OddsFormatSettings])).pipe(map(() => result));
                }),
            ),
        );

        this.dispatcher.dispatch(UserEvents.M2UserClaimsChanged);

        return apiResult;
    }
}
