import { Injectable } from '@angular/core';

import { LoadingIndicatorService } from '@frontend/vanilla/core';

import { LoggerFactory } from '../../logging/logger-factory.service';
import { SportsRemoteLogger } from '../../logging/sports-remote-logger.service';
import { ModalDialogService } from '../../modal/dialog/modal-dialog.service';
import { trackingConstants } from '../../tracking/tracking.models';
import { TrackingService } from '../../tracking/tracking.service';
import { EarlyPayoutAcceptance } from '../../user/model/early-payout-acceptance';
import { UserSettingsService } from '../../user/services/user-settings.service';
import { UserService } from '../../user/services/user.service';

export enum EarlyPayoutTACSource {
    MyBets,
    EventView,
    EditBet,
}

export interface IEarlyPayoutTACTrackingData {
    source: EarlyPayoutTACSource;
    forLiveBet: boolean;
}

const loadTacDialog = () => import('./tac-dialog/tac-dialog.component').then((m) => m.MyBetsEarlyPayoutTacDialogComponent);

@Injectable({ providedIn: 'root' })
export class EarlyPayoutTacService {
    private readonly logger: SportsRemoteLogger;

    constructor(
        private user: UserService,
        private modalDialog: ModalDialogService,
        private loadingIndicator: LoadingIndicatorService,
        private userSettings: UserSettingsService,
        private tracker: TrackingService,
        loggerFactory: LoggerFactory,
    ) {
        this.logger = loggerFactory.getLogger('EarlyPayoutTacService');
    }

    get hasUserAcceptedTAC(): boolean {
        return this.user.earlyPayoutAcceptance !== null;
    }

    async acceptEarlyPayoutTaC(trackingData: IEarlyPayoutTACTrackingData): Promise<boolean> {
        try {
            const accepted = await this.modalDialog.openDialog(await loadTacDialog(), {
                settings: { showDialogHeader: true, cssClass: 'early-payout-tac-dialog' },
            }).result;
            if (accepted) {
                return await this.tryUpdateUserSettings(trackingData);
            }
        } catch (e) {
            this.logger.error(e);
        }

        return false;
    }

    private trackingContext(source: EarlyPayoutTACSource): string {
        switch (source) {
            case EarlyPayoutTACSource.EditBet:
                return '_editBet';
            case EarlyPayoutTACSource.EventView:
                return '_eventView';
            case EarlyPayoutTACSource.MyBets:
                return '_myBets';
        }
    }

    private async tryUpdateUserSettings(trackingData: IEarlyPayoutTACTrackingData): Promise<boolean> {
        const trackingContext = this.trackingContext(trackingData.source);
        const loadingIndicator = this.loadingIndicator.start();
        let result = false;
        try {
            await this.userSettings.set({ earlyPayoutAcceptanceMode: EarlyPayoutAcceptance.Higher });
            this.tracker.track(trackingConstants.EVENT_PAGE_VIEW, {
                [trackingConstants.PAGE_NAME]:
                    (trackingData.forLiveBet ? 'LIVE/EarlyPayout_TCSuccessful' : 'MAIN/EarlyPayout_TCSuccessful') + trackingContext,
            });
            result = true;
        } catch (error) {
            this.tracker.track(trackingConstants.EVENT_PAGE_VIEW, {
                [trackingConstants.PAGE_NAME]: (trackingData.forLiveBet ? 'LIVE/EarlyPayout_TCError' : 'MAIN/EarlyPayout_TCError') + trackingContext,
            });
            this.logger.error(error, 'Failed to accept Early payout TAC');
        }
        loadingIndicator.done();

        return result;
    }
}
