import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';

import { HelpBoxType } from './help-box-type';
import { HelpBoxService } from './help-box.service';

@Component({
    selector: 'ms-help-box-badge',
    template: `
        @if (isEnabled()) {
            <span>{{ badgeText() }}</span>
        }
    `,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpBoxBadgeComponent {
    boxType = input.required<HelpBoxType>();
    isShown = input<boolean>(false);
    private hbService = inject(HelpBoxService);
    readonly isEnabled = computed(() => this.isShown() || this.hbService.isHelpBoxEnabled(this.boxType()));
    readonly badgeText = computed(() => this.hbService.getHelpBoxBadgeData(this.boxType()));
}
