import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EventScoreboard } from '../event-model/model/scoreboard.model';

@Component({
    selector: 'ms-event-timer',
    templateUrl: 'event-timer.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventTimerComponent {
    @Input() live: boolean;
    @Input() start: Date;
    @Input() scoreboard: EventScoreboard;
    @Input() isDynamicTimer: boolean;
    @Input() intervalForRedPrematchTimer: number;
    @Input() calendarInfoDisplay = false;
    @Input() timeValue?: string;
    @Input() eventId?: number;
}
