import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ApiService } from '@frontend/sports/common/core/feature/http';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';

import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';
import { Action, AlertEvent, Subscription, Subscriptions, Type } from './model';
import { SubscriptionStreamService } from './subscription-stream.service';

@Injectable({ providedIn: 'root' })
export class AlertSubscriptionService {
    private subscriptionsSubject = new BehaviorSubject<Subscriptions>({});
    private readonly logger: SportsRemoteLogger;

    event: Observable<Subscriptions> = this.subscriptionsSubject.asObservable();

    constructor(
        private apiService: ApiService,
        private streamService: SubscriptionStreamService,
        private trackingService: TrackingService,
        loggerFactory: LoggerFactory,
    ) {
        this.streamService.data$.pipe(takeUntilDestroyed()).subscribe(this.processData);
        this.logger = loggerFactory.getLogger('AlertSubscriptionService');
    }

    private processData = (data: Subscription[]) => {
        const subscriptions = {};
        if (data) {
            data.forEach((item) => {
                subscriptions[item.event.id] = item;
            });
        }

        this.propagate(subscriptions);
    };

    private sendSubscriptions(subscriptionsToUpdate: Subscription[]): Promise<void> {
        return firstValueFrom(this.apiService.post<void>('nativealerts/updateSubscriptions', subscriptionsToUpdate));
    }

    updateSubscriptions(subscriptionsToUpdate: Subscription[]): Promise<Subscription[]> {
        subscriptionsToUpdate.forEach((subscription) => {
            subscription.action = subscription.scores && subscription.scores.length > 0 ? Action.Subscribe : Action.Unsubscribe;
        });

        this.sendSubscriptions(subscriptionsToUpdate).catch((error) => {
            if (error.errorCode === 'Unauthenticated') {
                this.streamService.stopGettingSubscriptions();
            } else {
                this.logger.error(error, 'Update Native Alerts Subscriptions failed.');
            }
        });

        return this.updateLocalCollection(subscriptionsToUpdate);
    }

    private updateLocalCollection = (subscriptionsToUpdate: Subscription[]): Promise<Subscription[]> => {
        return new Promise((resolve) => {
            const subscriptions = this.subscriptionsSubject.getValue();
            subscriptionsToUpdate.forEach((newSubscription) => {
                if (newSubscription.action === Action.Subscribe) {
                    this.trackSubscription(newSubscription);
                    subscriptions[newSubscription.event.id] = newSubscription;
                } else {
                    delete subscriptions[newSubscription.event.id];
                }
            });
            this.propagate(subscriptions);
            resolve(subscriptionsToUpdate);
        });
    };

    private trackSubscription(item: Subscription): void {
        if (item.tracking) {
            this.trackingService.track(trackingConstants.EVENT_ALERT_ENABLED, {
                [trackingConstants.PAGE_NAME]: `${item.tracking}_E${item.event.id}_S${item.sportId}`,
            });
            delete item.tracking;
        }
    }

    private propagate(newSubscriptions: Subscriptions): void {
        this.subscriptionsSubject.next(newSubscriptions);
    }

    unsubscribeAll(subscriptionsToRemove: Subscription[]): Promise<Subscription[]> {
        subscriptionsToRemove.forEach((subscription) => {
            subscription.scores = [];
            subscription.action = Action.Unsubscribe;
        });

        this.sendSubscriptions(subscriptionsToRemove);

        return this.updateLocalCollection(subscriptionsToRemove);
    }

    private getSubscriptionForEvent(id: string): Subscription {
        return this.subscriptionsSubject.getValue()[id];
    }

    private getSubscriptionForGroup(groupId?: number): Subscription | undefined {
        let subscription;
        const currentSubscriptions = this.subscriptionsSubject.getValue();
        Object.keys(currentSubscriptions).forEach((key) => {
            if (currentSubscriptions[key].event.groupId === groupId) {
                subscription = currentSubscriptions[key];
            }
        });

        return subscription;
    }

    getSubscription(id: string, groupId?: number): Subscription {
        let subscription: any;

        subscription = this.getSubscriptionForEvent(id);
        if (!subscription && <number>groupId > 0) {
            subscription = this.getSubscriptionForGroup(groupId);
        }

        return subscription;
    }

    getSubscriptions(events: AlertEvent[]): Subscription[] {
        return events.map((e) => this.getSubscription(e.eventId, e.eventGroupId)).filter((s) => s);
    }

    createNewSubscription(alertEvent: AlertEvent): Subscription {
        return {
            action: Action.Subscribe,
            event: {
                id: alertEvent.eventId,
                groupId: alertEvent.eventGroupId,
                idType: alertEvent.live ? Type.Live : Type.NonLive,
            },
            league: {
                id: alertEvent.leagueId.toString(),
                groupId: alertEvent.leagueGroupId,
                idType: Type.NonLive, // hardcoded per instructions from CNS team
            },
            scores: [],
            sportId: alertEvent.sportId,
        };
    }
}
