import { Injectable } from '@angular/core';

import { noopAction } from '@frontend/sports/common/base-utils';
import { ShareMyBetConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { DeviceService, WindowRef } from '@frontend/vanilla/core';
import { Actions as EffectActions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from, of, pipe } from 'rxjs';
import { concatMap, debounceTime, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ClipboardService } from '../common/browser/clipboard.service';
import { MobileHelper } from '../common/mobile-helper.service';
import { StringInterpolationPipe } from '../common/string-interpolation.pipe';
import { EventBridgeService } from '../event-bridge/event-bridge.service';
import { ButtonType } from '../modal/dialog/modal-dialog.model';
import { ToasterService } from '../modal/toaster/toaster.service';
import { BetslipConstants } from '../navigation-core/pick-uri.model';
import { ShareMyBetApiActions } from '../share-my-bet-api/actions';
import StoragePersister from '../store-persist/storage-persister';
import { ShareMyBetStateLoader, isShareMyBetSavedState } from './share-my-bet-state-storage';
import { ShareMyBetActions } from './share-my-bet.actions';
import { ShareMyBetTracking } from './share-my-bet.models';
import { ShareMyBetService } from './share-my-bet.service';
import { IPlacedBetInfo, IShareMyBetRootState, sharingStateSelector } from './share-my-bet.state';

@Injectable()
export class ShareMyBetEffect implements OnInitEffects {
    private RECEIVED_BET_MESSAGE_VISIBILITY_TIME_MS = 6000;
    private COPIED_BUTTON_VISIBILITY_TIME_MS = 3000;

    constructor(
        private actions$: EffectActions,
        private store: Store<IShareMyBetRootState>,
        private shareMyBetService: ShareMyBetService,
        public shareMyBetConfig: ShareMyBetConfig,
        private deviceService: DeviceService,
        private toaster: ToasterService,
        private windowRef: WindowRef,
        public sitecore: Sitecore,
        private interpolationPipe: StringInterpolationPipe,
        private clipboardService: ClipboardService,
        private eventBridgeservice: EventBridgeService,
        private mobileHelper: MobileHelper,
        private persister: StoragePersister,
        private stateLoader: ShareMyBetStateLoader,
    ) {}

    initGenerateShortUri$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ShareMyBetActions.initGenerateShortUri),
            concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(sharingStateSelector(action.payload.betslipId))))),
            concatMap(([action, sharingState]) => {
                if (sharingState?.uri) {
                    return [ShareMyBetActions.share({ payload: { uri: sharingState.uri, betInfo: sharingState.betInfo } })];
                }

                return [ShareMyBetActions.generateShortUri({ payload: action.payload })];
            }),
        );
    });

    generateShortUri$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ShareMyBetActions.generateShortUri),
            concatMap((action) =>
                this.shareMyBetService.shortenUri(action.payload).pipe(
                    switchMap((uri: string) => {
                        const showBetSharingConfirmationOnlyOnFirstBetShare = this.shareMyBetConfig.showBetSharingConfirmationOnlyOnFirstBetShare;
                        const isComplianceShown = showBetSharingConfirmationOnlyOnFirstBetShare && this.shareMyBetService.isComplianceShown();
                        const showConfirmationDialog = !isComplianceShown && this.deviceService.isMobile;

                        if (showConfirmationDialog) {
                            return from(this.shareMyBetService.getComplianceDialog().result).pipe(
                                concatMap((button: ButtonType) => {
                                    if (button === ButtonType.Cancel) {
                                        return [];
                                    }

                                    if (showBetSharingConfirmationOnlyOnFirstBetShare) {
                                        this.shareMyBetService.setComplianceShown();
                                    }
                                    return of(ShareMyBetActions.share({ payload: { uri, betInfo: action.payload } }));
                                }),
                            );
                        }
                        return [ShareMyBetActions.share({ payload: { uri, betInfo: action.payload } })];
                    }),
                ),
            ),
        );
    });

    share$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ShareMyBetActions.share),
            concatMap((action) => {
                if (!this.shareMyBetService.isOnboardingShown()) {
                    this.shareMyBetService.setOnboardingShown();
                }

                const betInfo = action.payload.betInfo;

                if (this.deviceService.isMobile) {
                    const shareData = {
                        title: this.sitecore.shareMyBet.messages.Title,
                        url: action.payload.uri,
                        text: this.getShareText(betInfo),
                    };

                    if (this.mobileHelper.isAndroidWrapper) {
                        this.eventBridgeservice.notifyBetSharing(shareData);

                        return [];
                    }

                    if (!this.windowRef.nativeWindow.navigator.share) {
                        this.shareMyBetService.showNotSupportedDialog();

                        return [];
                    }

                    this.windowRef.nativeWindow.navigator.share(shareData).then(() => {
                        if (!this.deviceService.isiOS) {
                            this.showToasterMessage();
                        }

                        this.shareMyBetService.doSenderTracking(ShareMyBetTracking.BetShared, betInfo.location, betInfo.betslipId);
                    });

                    return [];
                }

                return [ShareMyBetActions.openCopyLinkDialog({ betslipId: betInfo.betslipId })];
            }),
        );
    });

    receiveBet$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ShareMyBetActions.receiveBet),
            pipe(
                debounceTime(this.RECEIVED_BET_MESSAGE_VISIBILITY_TIME_MS),
                map(() => ShareMyBetActions.removeReceiveBetMessage()),
            ),
        );
    });

    handleShareBet$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ShareMyBetApiActions.handleShareBet),
            concatMap((action) => {
                const uri = action.payload.uri;
                const refbet = decodeURIComponent(uri.search.get(ShareMyBetTracking.RefBet) || ShareMyBetTracking.NotApplicable);

                if (action.payload.pickCount) {
                    if (refbet === ShareMyBetTracking.NotApplicable) {
                        return [];
                    }

                    this.shareMyBetService.setRefbet(refbet);

                    return [ShareMyBetActions.receiveBet({ pickCount: action.payload.pickCount })];
                }

                this.shareMyBetService.showUriExpiredDialog(refbet);

                return [];
            }),
        );
    });

    copyShortUri$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ShareMyBetActions.copyShortUri),
            tap((action) => {
                this.clipboardService.copyFromContent(action.payload.uri);
            }),
            debounceTime(this.COPIED_BUTTON_VISIBILITY_TIME_MS),
            map((action) => ShareMyBetActions.removeCopied({ betslipId: action.payload.betslipId })),
        );
    });

    // handleSaveToStorageEffect$ = createEffect(() =>
    // 	this.actions$.pipe(
    // 		filter(action => action.type.startsWith('@share-my-bet')),
    // 		debounceTime(ShareMyBetEffect.SAVE_STATE_DEBOUNCE_TIME),
    // 		withLatestFrom(this.store.pipe(select(shareMyBetStateSelector))),
    // 		map(([actions, state]) => {
    // 			const savedState = this.stateSaver.save(state);
    //
    // 			// return RootActions.saveState({
    // 			// 	payload: {
    // 			// 		shareMyBet: savedState,
    // 			// 	} as IShareMyBetSavedState,
    // 			// });
    // 		})
    // 	)
    // );

    ngrxOnInitEffects(): Action {
        const state = this.persister.load();
        if (isShareMyBetSavedState(state)) {
            const shareMyBetState = this.stateLoader.load(state.shareMyBet);

            return ShareMyBetActions.populateFromStorage({ payload: shareMyBetState });
        }

        return noopAction;
    }

    private getShareText(betInfo: IPlacedBetInfo): string {
        let translation: string;
        let shareText: string;

        if (betInfo.betType === BetslipConstants.Single) {
            translation = this.sitecore.shareMyBet.messages.SingleBetShareText;
            shareText = this.interpolationPipe.transform(translation, {
                eventName: betInfo.eventName,
            });
        } else if (betInfo.betType === BetslipConstants.Combo) {
            translation = this.sitecore.shareMyBet.messages.MultiBetShareText;
            shareText = this.interpolationPipe.transform(translation, {
                pickCount: betInfo.pickCount,
            });
        } else {
            translation = this.sitecore.shareMyBet.messages.SystemBetShareText;
            shareText = this.interpolationPipe.transform(translation, {
                systemType: betInfo.systemType,
            });
        }

        return shareText;
    }

    private showToasterMessage(): void {
        this.toaster.open(this.sitecore.shareMyBet.messages.ShareSuccess, { duration: this.shareMyBetConfig.globalErrorVisibilityTime });
    }
}
