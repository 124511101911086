import { FixtureBase, Game, Option, OptionMarket, Participant } from '@cds/betting-offer';
import { FixtureAddons } from '@cds/betting-offer/add-ons';
import { MessageEnvelope, MessageType } from '@cds/push';
import { BetBuilderExpiringSubscriptionCommand, BetBuilderOfferUpdateCommand } from '@cds/push/bet-builder-commands';
import { CashoutNotification } from '@cds/push/cashout-commands';
import {
    FixtureCommandBase,
    FixtureUpdateCommand,
    GameDeleteCommand,
    GameUpdateCommand,
    OptionMarketDeleteCommand,
    OptionMarketUpdateCommand,
    ParticipantUpdateCommand,
    PatchScoreboardCommand,
    PlayerStatsCommand,
    SlimScoreboardCommand,
} from '@cds/push/fixture-commands';
import { OverAskOfferUpdateCommand } from '@cds/push/over-ask-commands';
import { merge } from 'lodash-es';

export interface FixtureMessageEnvelope extends MessageEnvelope {
    payload: FixtureCommandBase;
}

export interface GameUpdateMessageEnvelope extends FixtureMessageEnvelope {
    payload: GameUpdateCommand;
}

export interface GameDeleteMessageEnvelope extends FixtureMessageEnvelope {
    payload: GameDeleteCommand;
}

export interface OptionMarketUpdateMessageEnvelope extends FixtureMessageEnvelope {
    payload: OptionMarketUpdateCommand;
}
export interface FixtureUpdateMessageEnvelope extends FixtureMessageEnvelope {
    payload: FixtureUpdateCommand;
}

export interface OptionMarketDeleteMessageEnvelope extends FixtureMessageEnvelope {
    payload: OptionMarketDeleteCommand;
}

export interface ParticipantUpdateMessageEnvelope extends FixtureMessageEnvelope {
    payload: ParticipantUpdateCommand;
}

export interface ScoreboardPatchMessageEnvelope extends FixtureMessageEnvelope {
    payload: PatchScoreboardCommand;
}

export interface ScoreboardSlimMessageEnvelope extends FixtureMessageEnvelope {
    payload: SlimScoreboardCommand;
}

export interface PlayerStatsUpdateMessageEnvelope extends FixtureMessageEnvelope {
    payload: PlayerStatsCommand;
}

export interface CashoutUpdateMessageEnvelope extends MessageEnvelope {
    payload: CashoutNotification;
}

export interface OverAskUpdateMessageEnvelope extends MessageEnvelope {
    payload: OverAskOfferUpdateCommand;
}

export interface BetBuilderOfferUpdateMessageEnvelope extends MessageEnvelope {
    payload: BetBuilderOfferUpdateCommand;
}

export interface BetBuilderExpiringSubscriptionEnvelope extends MessageEnvelope {
    payload: BetBuilderExpiringSubscriptionCommand;
}

export function isFixtureUpdateEnvelope(envelope: MessageEnvelope): envelope is FixtureMessageEnvelope {
    return (
        isGameUpdateEnvelope(envelope) ||
        isGameDeleteEnvelope(envelope) ||
        isOptionMarketUpdateEnvelope(envelope) ||
        isOptionMarketDeleteEnvelope(envelope) ||
        isParticipantUpdateEnvelope(envelope) ||
        isScoreboardPatchEnvelope(envelope) ||
        isScoreboardSlimEnvelope(envelope) ||
        isFixtureSlimUpdateEnvelope(envelope) ||
        isPlayerStatsUpdateEnvelope(envelope)
    );
}

export function isGameDeleteEnvelope(envelope: MessageEnvelope): envelope is GameDeleteMessageEnvelope {
    return envelope.messageType === MessageType.GameDelete;
}

export function isGameUpdateEnvelope(envelope: MessageEnvelope): envelope is GameUpdateMessageEnvelope {
    return !!(envelope.messageType === MessageType.GameUpdate && (envelope.payload as GameUpdateCommand).game);
}

export function isOptionMarketDeleteEnvelope(envelope: MessageEnvelope): envelope is OptionMarketDeleteMessageEnvelope {
    return envelope.messageType === MessageType.OptionMarketDelete;
}

export function isOptionMarketUpdateEnvelope(envelope: MessageEnvelope): envelope is OptionMarketUpdateMessageEnvelope {
    return !!(envelope.messageType === MessageType.OptionMarketUpdate && (envelope.payload as OptionMarketUpdateCommand).optionMarket);
}

export function isOptionMarketEnvelope(envelope: MessageEnvelope): envelope is OptionMarketUpdateMessageEnvelope | OptionMarketDeleteMessageEnvelope {
    return isOptionMarketUpdateEnvelope(envelope) || isOptionMarketDeleteEnvelope(envelope);
}

export function isFixtureSlimUpdateEnvelope(envelope: MessageEnvelope): envelope is FixtureUpdateMessageEnvelope {
    return !!(envelope.messageType === MessageType.FixtureUpdate);
}

export function isParticipantUpdateEnvelope(envelope: MessageEnvelope): envelope is ParticipantUpdateMessageEnvelope {
    return !!(envelope.messageType === MessageType.ParticipantUpdate && (envelope.payload as ParticipantUpdateCommand).participant);
}

export function isScoreboardPatchEnvelope(envelope: MessageEnvelope): envelope is ScoreboardPatchMessageEnvelope {
    return !!(envelope.messageType === MessageType.ScoreboardPatch && (envelope.payload as PatchScoreboardCommand).patch);
}

export function isScoreboardSlimEnvelope(envelope: MessageEnvelope): envelope is ScoreboardSlimMessageEnvelope {
    return !!(envelope.messageType === MessageType.ScoreboardSlim && (envelope.payload as SlimScoreboardCommand).scoreboard);
}

export function isPlayerStatsUpdateEnvelope(envelope: MessageEnvelope): envelope is PlayerStatsUpdateMessageEnvelope {
    return !!(envelope.messageType === MessageType.PlayerStatsUpdate && (envelope.payload as PlayerStatsCommand).hybridPlayerStats);
}

export function isCashoutUpdateEnvelope(envelope: MessageEnvelope): envelope is CashoutUpdateMessageEnvelope {
    return envelope.messageType === MessageType.CashoutUpdate;
}

export function isOverAskUpdateEnvelope(envelope: MessageEnvelope): envelope is OverAskUpdateMessageEnvelope {
    return envelope.messageType === MessageType.OverAskOfferUpdate;
}

export function isBetBuilderOfferUpdateEnvelope(envelope: MessageEnvelope): envelope is BetBuilderOfferUpdateMessageEnvelope {
    return envelope.messageType === MessageType.BetBuilderOfferUpdate;
}

export function isBetBuilderExpiringSubscriptionEnvelope(envelope: MessageEnvelope): envelope is BetBuilderExpiringSubscriptionEnvelope {
    return envelope.messageType === MessageType.BetBuilderExpiringSubscription;
}

export interface RealParticipant extends Participant {
    id: number;
}

export interface ParticipantFixtureLike<TParticipant extends RealParticipant> {
    id: string;
    participants: TParticipant[];
}

function updateObjectInCollection<TPush extends { id: number }, O extends { id: number }>(
    updatedObj: TPush,
    collection: O[],
    creator: (push: TPush) => O,
    updater: (push: TPush, client: O) => O,
): O[] {
    const newCollection: O[] = [];
    let isUpdated = false;
    for (const item of collection) {
        if (item.id === updatedObj.id) {
            isUpdated = true;
            const updated = updater(updatedObj, item);
            newCollection.push(updated);
        } else {
            newCollection.push(item);
        }
    }
    if (!isUpdated) {
        // new object add at the end.
        newCollection.push(creator(updatedObj));
    }

    return newCollection;
}

function getUpdatedOptionMarkets<TPush extends { id: number; options: Option[] }, O extends { id: number; options: Option[] }>(
    updatedObj: TPush,
    collection: O[],
    creator: (push: TPush) => O,
    updater: (push: TPush, client: O) => O,
): O[] {
    const newCollection: O[] = [];
    let isUpdated = false;
    for (const item of collection) {
        if (item.id === updatedObj.id) {
            isUpdated = true;
            const updated = updater(updatedObj, item);
            updated.options = updatedObj.options;
            newCollection.push(updated);
        } else {
            newCollection.push(item);
        }
    }
    if (!isUpdated) {
        // new object add at the end.
        newCollection.push(creator(updatedObj));
    }

    return newCollection;
}

function defaultCreator<TInput, TOutput extends TInput>(input: TInput): TOutput {
    return input as TOutput;
}

function defaultUpdater<TInput, TOutput extends TInput>(input: TInput, current: TOutput): TOutput {
    return merge({}, current, input);
}

export interface IFixtureUpdateLike extends Pick<FixtureBase, 'id' | 'startDate' | 'cutOffDate' | 'stage'> {
    addons: Pick<FixtureAddons, 'isResulted' | 'isRaceOver' | 'pricingState'>;
}

export function updateFixture<T extends IFixtureUpdateLike>(fixture: T, message: FixtureUpdateMessageEnvelope): T {
    const payload = message.payload;
    if (payload.fixtureId !== fixture.id) {
        return fixture;
    }

    return {
        ...fixture,
        startDate: payload.startDate,
        cutOffDate: payload.cutOffDate,
        stage: payload.stage,
        addons: {
            ...fixture.addons,
            isResulted: payload.isResulted,
            isRaceOver: payload.isRaceOver,
            pricingState: payload.pricingState,
            raceSummary: payload.raceSummary,
        },
    };
}

export function updateFixtureParticipants<
    TParticipantFixture extends ParticipantFixtureLike<TClientParticipant>,
    TClientParticipant extends RealParticipant,
>(
    fixture: TParticipantFixture,
    message: ParticipantUpdateMessageEnvelope,
    options: {
        creator?: (push: RealParticipant) => TClientParticipant;
        updater?: (push: RealParticipant, client: TClientParticipant) => TClientParticipant;
    },
): TParticipantFixture {
    if (message.payload.fixtureId !== fixture.id) {
        return fixture;
    }
    const messageParticipant = message.payload.participant as RealParticipant;
    const creator = options.creator || defaultCreator;
    const updater = options.updater || defaultUpdater;

    return {
        ...fixture,
        participants: updateObjectInCollection(messageParticipant, fixture.participants, creator, updater),
    };
}

interface OptionMarketFixtureLike {
    id: string;
    optionMarkets: OptionMarket[];
}

export function updateFixtureOptionMarkets<TOptionMarketFixture extends OptionMarketFixtureLike>(
    fixture: TOptionMarketFixture,
    message: OptionMarketUpdateMessageEnvelope | OptionMarketDeleteMessageEnvelope,
): TOptionMarketFixture {
    if (message.payload.fixtureId !== fixture.id) {
        return fixture;
    }
    if (isOptionMarketDeleteEnvelope(message)) {
        return {
            ...fixture,
            optionMarkets: fixture.optionMarkets.filter((o) => o.id !== message.payload.marketId),
        };
    } else {
        const updatedMarket = message.payload.optionMarket;

        return {
            ...fixture,
            optionMarkets: getUpdatedOptionMarkets(updatedMarket, fixture.optionMarkets, defaultCreator, defaultUpdater),
        };
    }
}

interface GamesFixtureLike {
    id: string;
    games: Game[];
}

export function updateFixtureGames<TGamesFixture extends GamesFixtureLike>(
    fixture: TGamesFixture,
    message: GameUpdateMessageEnvelope | GameDeleteMessageEnvelope,
): TGamesFixture {
    if (message.payload.fixtureId !== fixture.id) {
        return fixture;
    }
    if (isGameDeleteEnvelope(message)) {
        return {
            ...fixture,
            games: fixture.games.filter((o) => o.id !== message.payload.gameId),
        };
    } else {
        const updatedGame = message.payload.game;

        return {
            ...fixture,
            games: updateObjectInCollection(updatedGame, fixture.games, defaultCreator, defaultUpdater),
        };
    }
}
