import { NgModule } from '@angular/core';

import { DsButton } from '@frontend/ui/button';

import { MyBetsOddsPipe } from './pipes/my-bets-odds.pipe';

@NgModule({
    imports: [DsButton],
    declarations: [MyBetsOddsPipe],
    exports: [MyBetsOddsPipe],
})
export class MyBetsBaseModule {}
