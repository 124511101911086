import { Injectable } from '@angular/core';

import { Odds, OddsFormat } from '@bpos';
import { NativePrice } from '@frontend/sports/common/odds-lib';
import { OddsDisplayFormat } from '@frontend/sports/types/models/user-settings';
import { Decimal } from 'decimal.js';

import { UserService } from '../user/services/user.service';

@Injectable({ providedIn: 'root' })
export class OddsFormatConverterService {
    constructor(private userService: UserService) {}

    getOddsByOddsFormat(price: NativePrice): Decimal {
        switch (this.userService.oddsFormat) {
            case OddsDisplayFormat.UK:
                return price.fraction;
            case OddsDisplayFormat.US:
                return NativePrice.americanOddsToDecimalOdds(price.americanOdds);
            default:
                return price.odds;
        }
    }

    getCalculatedOddsByFormat(price: NativePrice): Decimal {
        if (this.userService.oddsFormat === OddsDisplayFormat.US && price.americanOddsCalculation !== undefined) {
            return new Decimal(price.americanOddsCalculation);
        }

        return this.getOddsByOddsFormat(price);
    }

    getEmptyBposOdds(): Odds {
        return {
            european: 0,
            british: { numerator: 0, denominator: 0 },
            american: 0,
            oddsFormat: this.getBposOddsFormat(),
        };
    }

    private getBposOddsFormat(): OddsFormat {
        switch (this.userService.oddsFormat) {
            case OddsDisplayFormat.UK:
                return OddsFormat.British;
            case OddsDisplayFormat.US:
                return OddsFormat.American;
            default:
                return OddsFormat.European;
        }
    }
}
