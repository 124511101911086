import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NavigationCoreModule } from '../navigation-core/navigation-core.module';
import { ShareMyBetCopyComponent } from './share-my-bet-copy.component';
import { ShareMyBetNativeMessageService } from './share-my-bet-native-message.service';
import { ShareMyBetOnboardingComponent } from './share-my-bet-onboarding.component';
import { ShareMyBetStateLoader, ShareMyBetStateSaver } from './share-my-bet-state-storage';
import { ShareMyBetComponent } from './share-my-bet.component';
import { ShareMyBetEffect } from './share-my-bet.epic';
import { shareMyBetReducer } from './share-my-bet.reducer';
import { ShareMyBetService } from './share-my-bet.service';
import { shareMyBetFeatureKey } from './share-my-bet.state';

@NgModule({
    declarations: [ShareMyBetComponent, ShareMyBetCopyComponent, ShareMyBetOnboardingComponent],
    imports: [
        CommonModule,
        NavigationCoreModule,
        StoreModule.forFeature(shareMyBetFeatureKey, shareMyBetReducer),
        EffectsModule.forFeature([ShareMyBetEffect]),
    ],
    exports: [ShareMyBetComponent, ShareMyBetCopyComponent],
    providers: [ShareMyBetStateSaver, ShareMyBetStateLoader, ShareMyBetService, ShareMyBetNativeMessageService],
})
export class ShareMyBetModule {
    constructor(private shareMyBetNativeMessageService: ShareMyBetNativeMessageService) {
        this.shareMyBetNativeMessageService.listen();
    }
}
