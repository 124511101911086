import { Injectable } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { ToasterService } from '../modal/toaster/toaster.service';

@Injectable({ providedIn: 'root' })
export class AlertToastMessagesService {
    private timeout: number;
    constructor(
        private toaster: ToasterService,
        private sitecore: Sitecore,
    ) {
        this.timeout = 3000;
    }

    showUnsubscribedToast(): void {
        if (this.sitecore.nativeAlerts) {
            this.startToast(this.sitecore.nativeAlerts.messages.LiveNotificationsUnsubscribed);
        }
    }

    showSubscribedToast(): void {
        if (this.sitecore.nativeAlerts) {
            this.startToast(this.sitecore.nativeAlerts.messages.LiveNotificationsSubscribed);
        }
    }

    private startToast(toast: string): void {
        this.toaster.open(toast, { duration: this.timeout, icon: 'sports-alert' });
    }
}
