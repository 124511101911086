import { NgIf } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

import { HooksWireup } from '@frontend/sports/common/base-utils';
import { LinkListLayout, LinkListPayload } from '@frontend/sports/types/components/widget/types';
import { kebabCase } from 'lodash-es';

import { WidgetComponent } from '../widget/core/widget-registry.service';
import { QuickLinkItem, toQuickLinkItem } from './quick-links.models';
import { TopBottomSectionLinksComponent } from './top-bottom-section-links/top-bottom-section-links.component';

@HooksWireup()
@Component({
    standalone: true,
    imports: [NgIf, TopBottomSectionLinksComponent],
    selector: 'ms-link-list-widget',
    templateUrl: './link-list-widget.component.html',
})
export class LinkListWidgetComponent extends WidgetComponent<LinkListPayload> {
    @HostBinding('class') get className(): string {
        const classes = ['link-list-widget'];

        if (this.title || this.layout === LinkListLayout.Stacked || this.layout === LinkListLayout.MiniCarousel) {
            classes.push('list', 'list-card');
        }

        if (this.layout) {
            classes.push(`link-list-${kebabCase(this.layout)}`);
        }

        return classes.join(' ');
    }

    title: string | undefined;
    layout?: LinkListLayout;
    links: QuickLinkItem[] | undefined;
    smallIcon: boolean;

    protected override onData({ title, links, layout }: LinkListPayload): void {
        this.title = title;
        this.links = links?.map(toQuickLinkItem);
        this.hasData = !!links?.length;

        if (layout === LinkListLayout.TilesCarouselSmallIcon) {
            this.smallIcon = true;
            this.layout = LinkListLayout.TilesCarousel;
        } else {
            this.smallIcon = false;
            this.layout = layout;
        }
        this.setWidgetLoaded();
    }
}
