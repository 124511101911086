import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { interpolateDoubleBracesString, isDefined } from '@frontend/sports/common/base-utils';
import { Decimal } from 'decimal.js';

@Injectable({ providedIn: 'root' })
@Pipe({
    name: 'msAccaBoostRatio',
})
export class AccaBoostRatioPipe implements PipeTransform {
    transform(ratio: number | undefined, prefix: boolean = true, template?: string): string {
        return isDefined(ratio) ? this.format(ratio, prefix, template) : '';
    }

    private format(ratio: number, prefix: boolean, template?: string): string {
        const boostRatio = new Decimal(ratio).times(100).toString();
        const formatted = `${prefix ? '+' : ''}${boostRatio}%`;

        return template ? this.replaceBoostPlaceholder(template, formatted) : formatted;
    }

    private replaceBoostPlaceholder(template: string, boost: string): string {
        return interpolateDoubleBracesString(template, {
            boost,
        });
    }
}
