import { Injectable } from '@angular/core';

import { interpolateDoubleBracesString } from '@frontend/sports/common/base-utils';
import { CrmConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import Decimal from 'decimal.js';
import { round, trim } from 'lodash-es';
import { AccaBoostToken, BetAndGetToken } from 'packages/sports/common/betslip/modules/reward-tokens/reward-tokens.model';
import { getAccaBoostRatio } from 'packages/sports/common/betslip/modules/reward-tokens/services/reward-tokens.utils';

import { AccaBoostRatioPipe } from '../acca-boost-base/acca-boost-ratio.pipe';
import { MoneyService } from '../money/money.service';
import { PayoutType, RewardTokenType, RewardTriggerType } from './token-base.models';
import { TokensCalculatorService } from './tokens-calculator.service';

export enum PayoutInfoLocation {
    Summary = 'Summary',
    Drawer = 'Drawer',
}

type DisplayPayoutTypes = RewardTokenType.FreeBet | RewardTokenType.OddsBoost | RewardTokenType.RiskFreeBet | RewardTokenType.BetAndGet;
type DisplayPayoutTypesMap = { [payoutDisplay in DisplayPayoutTypes]: { [payoutType in PayoutType]: string } | undefined };

const PRECISION = 2;

@Injectable({ providedIn: 'root' })
export class TokensSummaryService {
    private readonly displayPayoutTypes: DisplayPayoutTypesMap;

    constructor(
        private moneyService: MoneyService,
        private crmConfig: CrmConfig,
        private tokensCalculator: TokensCalculatorService,
        private sitecore: Sitecore,
        private accaBoostRatioPipe: AccaBoostRatioPipe,
    ) {
        this.displayPayoutTypes = this.createDisplayPayoutTypes();
    }

    getBoostedWinningsSummary(payoutType: PayoutType, winningsBoost: Decimal | null): string {
        if (this.isPayoutInfoEnabled(PayoutInfoLocation.Summary, RewardTokenType.OddsBoost, payoutType)) {
            const winningsBoostFormatted = this.moneyService.transform(winningsBoost, true);
            const displayPayoutType = this.getDisplayPayoutType(payoutType, RewardTokenType.OddsBoost);

            return interpolateDoubleBracesString(this.sitecore.crmTokens.oddsBoost.betslipSummary.IncludedBoostedWinnings, {
                winningsBoost: winningsBoostFormatted,
                payoutType: displayPayoutType,
            });
        }

        return '';
    }

    // if no riskFreePercentage or stake is supplied, then no calculation will happen
    // and maxPayout is considered as returnAmount
    getRiskFreeSummary(
        payoutType: PayoutType,
        maxPayout: number,
        riskFreePercentage?: number,
        stake?: number,
        noOfSlabs?: number,
        slabAmount?: number,
    ): string {
        if (this.isPayoutInfoEnabled(PayoutInfoLocation.Summary, RewardTokenType.RiskFreeBet, payoutType)) {
            const riskFreeReturns = this.tokensCalculator.getTokenPercentageReturns(maxPayout, riskFreePercentage, stake);
            const incrementalPayout = this.getIncrementalDetails(payoutType, riskFreeReturns, noOfSlabs, slabAmount) ?? 0;
            const displayPayoutType =
                incrementalPayout > 0
                    ? this.sitecore.crmTokens.riskFree.betslipSummary.IncrementalPayoutFreebet
                    : this.getDisplayPayoutType(payoutType, RewardTokenType.RiskFreeBet);
            const payoutSummaryTemplate =
                incrementalPayout > 0
                    ? this.sitecore.crmTokens.riskFree.betslipSummary.RiskFreeIncrementalPayoutSummary
                    : this.sitecore.crmTokens.riskFree.betslipSummary.RiskFreePayoutSummary;

            return interpolateDoubleBracesString(payoutSummaryTemplate, {
                payoutType: displayPayoutType,
                amount: this.moneyService.transform(riskFreeReturns, true),
                noOfSlabs: noOfSlabs ?? 1,
                payout: this.moneyService.transform(incrementalPayout, true),
            });
        }

        return '';
    }

    getBetAndGetSummary(token: BetAndGetToken): string {
        if (this.isPayoutInfoEnabled(PayoutInfoLocation.Summary, RewardTokenType.BetAndGet, token.payoutType)) {
            let displayPayoutTemplate = this.sitecore.crmTokens.betAndGet.betslipSummary.BetAndGetPayoutSummary;
            let displayPayoutValue = this.moneyService.transform(token.payoutValue, true);
            let displayPayoutType = '';
            let displayMaxPayoutValue = '';

            if (token.percentagePayout) {
                displayPayoutTemplate = this.sitecore.crmTokens.betAndGet.betslipSummary.BetAndGetPercentagePayoutSummary;
                displayPayoutValue = token.percentagePayout.toString();
                displayMaxPayoutValue = this.moneyService.transform(token.maximumPayout, true);
            }

            const displayRewardTriggerType =
                token.rewardTriggerType === RewardTriggerType.BetPlacement
                    ? this.sitecore.crmTokens.betAndGet.betslipSummary.BetPlacementTemplate
                    : this.sitecore.crmTokens.betAndGet.betslipSummary.BetSettlementTemplate;

            switch (token.payoutType) {
                case PayoutType.Bonus:
                    displayPayoutType = this.sitecore.crmTokens.betAndGet.betslipSummary.BonusPayoutTemplate;
                    break;
                case PayoutType.Cash:
                    displayPayoutType = this.sitecore.crmTokens.betAndGet.betslipSummary.CashPayoutTemplate;
                    break;
                case PayoutType.FreeBet:
                    displayPayoutType = this.sitecore.crmTokens.betAndGet.betslipSummary.FreebetPayoutTemplate;
                    break;
                case PayoutType.OddsBoostToken:
                    displayPayoutType = this.sitecore.crmTokens.betAndGet.betslipSummary.OddsBoostPayoutTemplate;
                    displayPayoutValue = '';
                    displayMaxPayoutValue = '';
                    break;
                case PayoutType.RiskFreeToken:
                    displayPayoutType = this.sitecore.crmTokens.betAndGet.betslipSummary.RiskFreePayoutTemplate;
                    displayPayoutValue = '';
                    displayMaxPayoutValue = '';
                    break;
            }

            return trim(
                interpolateDoubleBracesString(displayPayoutTemplate, {
                    payoutType: displayPayoutType,
                    value: displayPayoutValue,
                    maxAmount: displayMaxPayoutValue,
                    triggerType: displayRewardTriggerType,
                }),
            );
        }

        return '';
    }

    isPayoutInfoEnabled(location: PayoutInfoLocation, tokenType: RewardTokenType, payoutType: PayoutType): boolean {
        const payoutInfo = location === PayoutInfoLocation.Summary ? this.crmConfig.tokens.summaryPayoutInfo : this.crmConfig.tokens.drawerPayoutInfo;
        let config;
        switch (tokenType) {
            case RewardTokenType.OddsBoost:
                config = payoutInfo.oddsBoost;
                break;
            case RewardTokenType.RiskFreeBet:
                config = payoutInfo.riskFree;
                break;
            case RewardTokenType.BetAndGet:
                config = payoutInfo.betAndGet;
                break;
        }

        return config
            ? {
                  [PayoutType.Bonus]: config.bonus,
                  [PayoutType.FreeBet]: config.freeBet,
                  [PayoutType.Cash]: config.cash,
                  [PayoutType.BonusNoWager]: false,
                  [PayoutType.OddsBoostToken]: config.oddsBoostToken,
                  [PayoutType.RiskFreeToken]: config.riskFreeToken,
              }[payoutType]
            : false;
    }

    getAccaBoostSummary(token: AccaBoostToken, pickCount: number): string | null {
        const ratio = getAccaBoostRatio(token, pickCount).toNumber();

        return this.accaBoostRatioPipe.transform(ratio, false, this.sitecore.accaBoost.BetslipBoostingInfo);
    }

    getDisplayPayoutType(payoutType: PayoutType, tokenType: RewardTokenType): string {
        const displayPayoutTypes = this.displayPayoutTypes[tokenType];

        return displayPayoutTypes ? displayPayoutTypes[payoutType] ?? '' : '';
    }

    private createDisplayPayoutTypes(): DisplayPayoutTypesMap {
        return {
            [RewardTokenType.OddsBoost]: {
                [PayoutType.FreeBet]: this.sitecore.crmTokens.oddsBoost.betslipSummary.PayoutTypeFreebet,
                [PayoutType.Bonus]: this.sitecore.crmTokens.oddsBoost.betslipSummary.PayoutTypeBonus,
                [PayoutType.Cash]: this.sitecore.crmTokens.oddsBoost.betslipSummary.PayoutTypeCash,
                [PayoutType.BonusNoWager]: '',
                [PayoutType.OddsBoostToken]: '',
                [PayoutType.RiskFreeToken]: '',
            },
            [RewardTokenType.RiskFreeBet]: {
                [PayoutType.FreeBet]: this.sitecore.crmTokens.riskFree.betslipSummary.PayoutTypeFreebet,
                [PayoutType.Bonus]: this.sitecore.crmTokens.riskFree.betslipSummary.PayoutTypeBonus,
                [PayoutType.Cash]: this.sitecore.crmTokens.riskFree.betslipSummary.PayoutTypeCash,
                [PayoutType.BonusNoWager]: '',
                [PayoutType.OddsBoostToken]: '',
                [PayoutType.RiskFreeToken]: '',
            },
            [RewardTokenType.FreeBet]: undefined,
            [RewardTokenType.BetAndGet]: {
                [PayoutType.FreeBet]: this.sitecore.crmTokens.betAndGet.betslipSummary.FreebetPayoutTemplate,
                [PayoutType.Bonus]: this.sitecore.crmTokens.betAndGet.betslipSummary.BonusPayoutTemplate,
                [PayoutType.Cash]: this.sitecore.crmTokens.betAndGet.betslipSummary.CashPayoutTemplate,
                [PayoutType.BonusNoWager]: '',
                [PayoutType.OddsBoostToken]: this.sitecore.crmTokens.betAndGet.betslipSummary.OddsBoostPayoutTemplate,
                [PayoutType.RiskFreeToken]: this.sitecore.crmTokens.betAndGet.betslipSummary.RiskFreePayoutTemplate,
            },
        };
    }

    private getIncrementalDetails(payoutType: PayoutType, actualPayout?: number, noOfSlabs?: number, slabAmount?: number): number | undefined {
        if (payoutType === PayoutType.FreeBet && actualPayout && noOfSlabs && noOfSlabs > 1) {
            return slabAmount ?? round(actualPayout / noOfSlabs, PRECISION);
        }

        return;
    }
}
