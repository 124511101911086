import { ChangeDetectorRef, Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { NativeAlertsConfig } from '@frontend/sports/common/client-config-data-access';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EventModel } from '../../event-model/model/event.model';
import { ModalRef } from '../../modal/common/modal-ref';
import { DialogSettings } from '../../modal/dialog/modal-dialog.model';
import { ModalDialogService } from '../../modal/dialog/modal-dialog.service';
import { RedirectHelperService } from '../../navigation-core/redirect-helper.service';
import { IScoreboardLiveAlert } from '../../scoreboards/models/scoreboard.viewmodel';
import { UserService } from '../../user/services/user.service';
import { AlertConfigService } from '../alert-config.service';
import { AlertSelectorComponent } from '../alert-selector/alert-selector.component';
import { AlertSelectorService } from '../alert-selector/alert-selector.service';
import { AlertSubscriptionService } from '../alert-subscription.service';
import { AlertToastMessagesService } from '../alert-toast-messages.service';
import { AlertEvent, NotificationEnabled, Source, Subscriptions } from '../model';
import { NativeAlertsService } from '../native-alerts.service';

@HooksWireup()
@Component({
    selector: 'ms-alert',
    template: '',
})
export class AlertButtonComponent extends OnDestroyCleanup implements OnInit {
    @Input() disable = false;
    @Input() model: any;
    @Input() tracking?: string;
    @Output() alertEnabled = new EventEmitter<void>();

    @Input()
    @HostBinding('class.alert-selected')
    selected: boolean;

    @HostBinding('class.alert-enabled') enabled = false;

    @HostBinding('class.alert-deselected')
    get deselected(): boolean {
        return !this.selected;
    }

    @HostBinding('class.sports-alert')
    get alert(): boolean {
        return this.enabled && !this.multiple && this.selected;
    }

    @HostBinding('class.sports-alert-o')
    get alertDisabled(): boolean {
        return this.enabled && !this.multiple && !this.selected;
    }

    @HostBinding('class.sports-alert-multiple')
    get alertMultiple(): boolean {
        return this.enabled && this.multiple && this.selected;
    }

    @HostBinding('class.sports-alert-multiple-o')
    get alertMultipleDisabled(): boolean {
        return this.enabled && this.multiple && !this.selected;
    }

    private events: AlertEvent[] = [];
    private multiple = false;
    private dialog?: ModalRef;
    private subscription: Subscription;

    constructor(
        private alertsConfig: AlertConfigService,
        private toastMessageService: AlertToastMessagesService,
        private selectorService: AlertSelectorService,
        private subscriptionService: AlertSubscriptionService,
        private userService: UserService,
        private modalDialogService: ModalDialogService,
        private changeDetector: ChangeDetectorRef,
        private redirectService: RedirectHelperService,
        private nativeAlertsConfig: NativeAlertsConfig,
        private nativeAlertsService: NativeAlertsService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.events = this.model ? this.getModel(this.model) : this.selectorService.model;
        this.enabled = this.alertsConfig.isEnabled() && this.events && this.alertsConfig.isSportEnabled(this.events[0].sportId);
        this.multiple = this.events && this.events.length > 1;

        if (this.enabled) {
            this.alertEnabled.emit();
            this.subscribe();
        }
    }

    @HostListener('click', ['$event'])
    clicked(event: Event): void {
        if (!this.userService.isAuthenticated) {
            this.redirectService.goToLogin({ appendReferrer: true });

            return;
        }

        if (this.disable) {
            return;
        }

        if (this.selected && !this.multiple) {
            this.unsubscribe();
        } else {
            this.openDialog();
        }

        event.stopPropagation();
    }

    private unsubscribe(): void {
        const subscriptions = this.subscriptionService.getSubscriptions(this.events);
        if (subscriptions) {
            this.subscriptionService.unsubscribeAll(subscriptions).then(() => {
                this.selected = false;
                this.toastMessageService.showUnsubscribedToast();
            });
        }
    }

    private async openDialog(): Promise<void> {
        if (!this.nativeAlertsConfig.appNotificationsEnabledCheck) {
            this.launchAlertSelector();
        } else {
            this.verifyAndLaunchAlertsSelector();
        }
    }

    private launchAlertSelector(): void {
        if (this.dialog) {
            return;
        }

        this.selectorService.model = this.events.filter((event: AlertEvent) => this.alertsConfig.isSportEnabled(event.sportId));
        this.selectorService.showSelector(this.multiple, this.tracking || '', this.selected);

        const settings: DialogSettings = {
            showPageHeader: false,
            cssClass: 'alerts-selector-popup',
            isModalDialog: false,
            fit: false,
        };

        this.dialog = this.modalDialogService.openDialog(AlertSelectorComponent, { settings });
        this.dialog?.result.finally(() => {
            this.dialog = undefined;
        });
    }

    private verifyAndLaunchAlertsSelector(): void {
        if (this.dialog) {
            return;
        }

        this.subscription?.unsubscribe();
        this.subscription = this.nativeAlertsService.subscribeToNotificationCheck().subscribe((data) => {
            if (data?.parameters?.notificationEnabled === NotificationEnabled.Yes) {
                this.launchAlertSelector();
            }
            this.subscription.unsubscribe();
        });
    }

    private subscribe(): void {
        this.subscriptionService.event.pipe(takeUntil(this.destroyed$)).subscribe((res) => this.updateProperty(res));
    }

    private updateProperty(subscription: Subscriptions): void {
        if (!subscription || this.disable) {
            return;
        }

        const wasSelected = this.selected;

        this.selected = false;
        this.events.forEach((item) => {
            item.selected = !!this.subscriptionService.getSubscription(item.eventId, item.eventGroupId);

            if (item.selected) {
                this.selected = true;
            }
        });

        if (wasSelected !== this.selected) {
            this.changeDetector.markForCheck();
        }
    }

    private getBetslipModel(item: any): AlertEvent[] {
        const result: AlertEvent[] = [];
        if (item.clientPick.liveAlert && this.alertsConfig.isSportEnabled(item.clientPick.sportId)) {
            result.push({
                sportId: item.clientPick.sportId,
                eventId: item.clientPick.fixture?.fixtureId ?? item.clientPick.event?.id,
                leagueId: item.clientPick.leagueId ?? item.clientPick.league?.id,
                title: item.clientPick.eventName.name,
                eventGroupId: item.clientPick.event?.groupId,
                leagueGroupId: item.clientPick.league?.parentleagueId,
                live: item.clientPick.isLive,
            });
        }

        return result;
    }

    private getEventModel(item: EventModel): AlertEvent[] {
        const result: AlertEvent[] = [];
        if (item.liveAlert) {
            result.push({
                sportId: item.sport.id,
                eventId: item.id,
                leagueId: item.league.id,
                title: item.name,
                eventGroupId: item.groupId,
                leagueGroupId: item.league.parentLeagueId,
                live: item.live,
            });
        }

        return result;
    }

    private getMyBetModel(item: any): AlertEvent[] {
        const result: AlertEvent[] = [];
        if (item.liveAlert) {
            result.push({
                sportId: item.sportsId,
                eventId: item.eventId,
                leagueId: item.leagueId,
                title: item.eventName,
                eventGroupId: item.eventGroupId,
                leagueGroupId: item.leagueGroupId,
                live: item.containsLiveBets,
            });
        }

        return result;
    }

    private getScoreboardModel(item: IScoreboardLiveAlert): AlertEvent[] {
        const result: AlertEvent[] = [];
        if (item.liveAlert) {
            result.push({
                sportId: item.sportId,
                eventId: item.eventId,
                leagueId: item.leagueId,
                title: item.title,
                eventGroupId: item.eventGroupId,
                leagueGroupId: item.leagueGroupId,
                live: item.live,
            });
        }

        return result;
    }

    private getMyBetsModel(items: any): AlertEvent[] {
        let result: AlertEvent[] = [];
        items.bets.forEach((item: any) => {
            result = result.concat(this.getMyBetModel(item));
        });

        return result;
    }

    private getModel(model: any): AlertEvent[] {
        let result: AlertEvent[] = [];

        if (!model || model.length === 0) {
            return result;
        }

        const models = Array.isArray(model) ? model : [model];

        models.forEach((current) => {
            switch (this.tracking) {
                case Source.Mybets: {
                    result = result.concat(this.getMyBetsModel(current));
                    break;
                }
                case Source.Mybet: {
                    result = result.concat(this.getMyBetModel(current));
                    break;
                }
                case Source.Betslip: {
                    result = result.concat(this.getBetslipModel(current));
                    break;
                }
                case Source.Scoreboard: {
                    result = result.concat(this.getScoreboardModel(current));
                    break;
                }
                default: {
                    result = result.concat(this.getEventModel(current));
                    break;
                }
            }
        });

        return result;
    }
}
