<ms-promo-item-list
    [arrow]="showArrows"
    [items]="items"
    [tracking]="tracking"
    [itemClass]="linkClass"
    class="{{ itemListClass }}"
    (itemClick)="setTrackingDetails($event)"
    [promotedCompetitionIdsMap]="promotedCompetitionIdsMap"
    [promotedSportIdsMap]="promotedSportIdsMap"
    [layout]="layout">
</ms-promo-item-list>
