<div class="alerts-selector-header">
    @if (showBackButton) {
        <div class="alerts-selector-header-back"><i class="theme-left" (click)="backToList()"></i></div>
    }
    <div class="alerts-selector-header-title">{{ title }}</div>
    <i class="close theme-ex" (click)="closeSelector()"></i>
</div>
@if (isListView) {
    <section class="alerts-selector">
        <section class="alerts-selector-list multi">
            <div class="alerts-selector-list-item">
                <ms-alert [selected]="allSelected" (click)="toggleAllEvents()" class="alert-selector-view" [disable]="true"></ms-alert>
                <div class="option" (click)="toggleAllEvents()">
                    <span class="option-name">
                        @if (allSelected) {
                            Deselect All
                        } @else {
                            Select All
                        }
                    </span>
                </div>
            </div>
            @for (event of events; track event) {
                <div class="alerts-selector-list-item">
                    <ms-alert
                        [selected]="event.subscription.scores.length > 0"
                        (click)="toggleEvent(event.subscription)"
                        class="alert-selector-view"
                        [disable]="true"></ms-alert>
                    <div class="option" (click)="edit(event)">
                        <span class="name">{{ event.title }}</span>
                    </div>
                    <div class="option-icon" (click)="edit(event)"><i class="theme-right"></i></div>
                </div>
            }
        </section>
    </section>
}
@if (isDetailView) {
    <section class="alerts-selector" [ngClass]="{ 'no-footer': !showFooter }">
        <section class="alerts-selector-list single">
            @for (alert of alerts; track alert) {
                <div class="alerts-selector-list-item" (click)="toggleAlert(alert)">
                    <ms-alert [selected]="alert.isSelected" class="alert-selector-view" [disable]="true"></ms-alert>
                    <div class="option">
                        <span class="name">{{ alert.info.name }}</span>
                    </div>
                </div>
            }
        </section>
    </section>
}
@if (showFooter) {
    <section class="alerts-selector-footer" *ngIf="showFooter">
        @if (showConfirmButton) {
            <button ds-button kind="primary" variant="filled" size="large" truncate="true" class="alerts-selector-footer-button" (click)="save()">
                {{ sitecore.nativeAlerts.messages.ConfirmButton }}
            </button>
        } @else {
            <button
                ds-button
                kind="secondary"
                variant="filled"
                size="large"
                truncate="true"
                class="alerts-selector-footer-button"
                (click)="closeSelector()">
                {{ sitecore.nativeAlerts.messages.CancelButton }}
            </button>
        }
    </section>
}
