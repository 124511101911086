<ms-live-timer
    *ngIf="live && scoreboard.started; else prematch"
    [scoreboard]="scoreboard"
    [timeValue]="timeValue"
    [eventId]="eventId"></ms-live-timer>
<ng-template #prematch>
    <ms-prematch-timer
        [calendarInfoDisplay]="calendarInfoDisplay"
        [start]="start"
        [intervalForRedPrematchTimer]="intervalForRedPrematchTimer"
        [isDynamicTimer]="isDynamicTimer"></ms-prematch-timer>
</ng-template>
