import { NgClass, NgIf } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

enum EpcotNotificationTypes {
    Info = 'info',
    Error = 'error',
    Success = 'success',
    Warning = 'warning',
}

@Component({
    selector: 'ms-epcot-notification',
    templateUrl: 'notification.html',
    standalone: true,
    imports: [NgClass, NgIf],
})
export class EpcotNotificationComponent implements AfterViewChecked {
    @Input() notificationType?: string;
    @Input() message?: string;
    @Input() notificationHeaderTitle?: string;
    @Input() showCloseButton = false;
    @Output() linkClick = new EventEmitter<Event>();
    @Output() closeButtonClick = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) {}

    load(): string {
        switch (this.notificationType) {
            case EpcotNotificationTypes.Info:
                return 'info theme-info-i';
            case EpcotNotificationTypes.Error:
                return 'error theme-error-i';
            case EpcotNotificationTypes.Success:
                return 'success theme-win';
            case EpcotNotificationTypes.Warning:
                return 'warning theme-warning';
            default:
                return 'info theme-info-i';
        }
    }

    onCloseClick(): void {
        this.closeButtonClick.next();
    }

    ngAfterViewChecked(): void {
        if (this.elementRef.nativeElement.querySelector('#maxstakeSettings')) {
            this.linkClick.emit();
        }
    }
}
