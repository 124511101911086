import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SimpleCallback } from '@frontend/sports/common/base-utils';
import { ApiService } from '@frontend/sports/common/core/feature/http';
import { BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TimerService } from '../common/timer.service';
import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { UserService } from '../user/services/user.service';
import { AlertConfigService } from './alert-config.service';
import { NativeAlertsResponse, Subscription } from './model';

@Injectable({ providedIn: 'root' })
export class SubscriptionStreamService {
    private _data = new BehaviorSubject<Subscription[]>([]);
    private interval?: SimpleCallback;
    private readonly logger: SportsRemoteLogger;

    readonly data$ = this._data.asObservable();

    constructor(
        private apiService: ApiService,
        private alertConfigService: AlertConfigService,
        private userService: UserService,
        private timerService: TimerService,
        loggerFactory: LoggerFactory,
    ) {
        if (this.userService.isAuthenticated) {
            this.startGettingSubscriptions();
        }

        this.userService.onUserLogin$.pipe(takeUntilDestroyed()).subscribe(() => this.startGettingSubscriptions());
        this.userService.onUserLogout$.pipe(takeUntilDestroyed()).subscribe(() => this.stopGettingSubscriptions());

        this.logger = loggerFactory.getLogger('SubscriptionStreamService');
    }

    stopGettingSubscriptions(): void {
        if (this.interval) {
            this.interval();
        }

        this._data.next([]);
    }

    private startGettingSubscriptions(): void {
        if (this.alertConfigService.isEnabled()) {
            this.refreshSubscriptions();

            this.interval = this.timerService.setIntervalOutsideAngular(() => {
                this.refreshSubscriptions();
            }, this.alertConfigService.fetchingInterval);
        }
    }

    private refreshSubscriptions(): void {
        this.apiService
            .get<NativeAlertsResponse>('nativealerts/getSubscriptions')
            .pipe(
                catchError((err) => {
                    this.logger.error(err, 'Refresh Native Alerts Subscriptions failed.');

                    return of({ subscription: [] });
                }),
            )
            .subscribe((response) => this._data.next(response.subscription));
    }
}
