import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CountDownComponent } from './count-down.component';
import { EventTimerComponent } from './event-timer.component';
import { LiveTimerComponent } from './live-timer.component';
import { PrematchTimerComponent } from './prematch-timer.component';

@NgModule({
    imports: [CommonModule],
    declarations: [PrematchTimerComponent, LiveTimerComponent, EventTimerComponent, CountDownComponent],
    exports: [EventTimerComponent, CountDownComponent, PrematchTimerComponent],
})
export class TimerModule {}
