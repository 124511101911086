<div class="content-message-container">
    <div>
        <div>
            <div class="content-message" [ngClass]="load()">
                <span class="ui-icon icon"></span>
                <span *ngIf="showCloseButton" (click)="onCloseClick()" class="ui-icon theme-ex"></span>
                <div class="page-matrix">
                    <div>
                        <div class="pc-text pc-component">
                            <span *ngIf="!!notificationHeaderTitle" class="betslip-notification-title"> {{ notificationHeaderTitle }} </span>
                            <section class="pc-richtext" *ngIf="message" [innerHTML]="message"></section>
                            <ng-content select="[message]" class="pc-richtext"></ng-content>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
