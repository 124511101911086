import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy } from '@angular/core';

import { ShareMyBetConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { DeviceService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ButtonType } from '../modal/dialog/modal-dialog.model';
import { TrackingLocation } from '../tracking/tracking.models';
import { ShareMyBetActions } from './share-my-bet.actions';
import { ShareMyBetTracking } from './share-my-bet.models';
import { ShareMyBetService } from './share-my-bet.service';
import { IPlacedBetInfo, IShareMyBetRootState, IShareMyBetState, defaultSharingState, shareMyBetStateSelector } from './share-my-bet.state';

@Component({
    selector: 'ms-share-my-bet',
    templateUrl: './share-my-bet.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareMyBetComponent implements OnDestroy {
    @Input() placedBetInfo: IPlacedBetInfo;
    @Input() expandable: boolean;

    @HostBinding('class') get className() {
        return {
            'share-my-bet__button': this.shareMyBetConfig.isEnabled,
            'device-mobile': this.deviceService.isMobile,
        };
    }

    shareMyBetState$: Observable<IShareMyBetState>;

    sharingState = defaultSharingState;

    constructor(
        public sitecore: Sitecore,
        public shareMyBetConfig: ShareMyBetConfig,
        private shareMyBetService: ShareMyBetService,
        private deviceService: DeviceService,
        private store: Store<IShareMyBetRootState>,
    ) {
        this.shareMyBetState$ = store.select(shareMyBetStateSelector);
    }

    ngOnDestroy(): void {
        if (this.sharingState.isCopyLinkDialogOpen) {
            this.destroy();
        }
    }

    showOnboarding(): boolean {
        return (
            this.shareMyBetConfig.isOnboardingEnabled &&
            !this.shareMyBetService.isOnboardingShown() &&
            this.placedBetInfo.location === TrackingLocation.BetReceipt
        );
    }

    hasArrow(shareMyBetState: IShareMyBetState): boolean {
        this.sharingState = shareMyBetState.sharingStates[this.placedBetInfo.betslipId] || defaultSharingState;

        return this.expandable && this.sharingState.isCopyLinkDialogOpen;
    }

    onShareButtonClick(shareMyBetState: IShareMyBetState): void {
        this.sharingState = shareMyBetState.sharingStates[this.placedBetInfo.betslipId] || defaultSharingState;

        if (this.sharingState.isCopyLinkDialogOpen) {
            return;
        }

        this.shareMyBetService.doSenderTracking(ShareMyBetTracking.ShareMyBetClick, this.placedBetInfo.location);

        const showCompliance = !this.shareMyBetService.isComplianceShown();

        if (showCompliance && !this.deviceService.isMobile) {
            this.shareMyBetService.getComplianceDialog().result.then((buttonType: ButtonType) => {
                if (buttonType === ButtonType.Ok) {
                    this.shareMyBetService.setComplianceShown();

                    this.initGenerateShortUri();
                }
            });
        } else {
            this.initGenerateShortUri();
        }
    }

    onToggleClick(event: Event): void {
        event.stopPropagation();

        this.store.dispatch(ShareMyBetActions.closeCopyLinkDialog({ betslipId: this.placedBetInfo.betslipId }));
    }

    private initGenerateShortUri(): void {
        this.store.dispatch(ShareMyBetActions.initGenerateShortUri({ payload: this.placedBetInfo }));
    }

    private destroy(): void {
        this.store.dispatch(ShareMyBetActions.destroyState({ betslipId: this.placedBetInfo.betslipId }));
    }
}
