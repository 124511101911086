export interface ToastMessage {
    message: string;
    errorType: string;
}

export enum ErrorType {
    Error = 'ERROR',
    Info = 'INFO',
    Success = 'SUCCESS',
}

export enum RetryType {
    NonRetry = 0,
    Retry = 1,
}

export interface GDSResponse {
    locationStatus: string;
    isLocationAvailable: boolean;
    errorCode: number;
    errorMsg: string;
    troubleShooterMessages?: { tsMsgs?: GDSTsMsg[] };
}

export interface GDSTsMsg {
    rule: string;
    retry: number;
    message: string;
}
