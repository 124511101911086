export enum ShareMyBetTracking {
    BetSharing = 'Bet Sharing',
    ShareMyBet = 'Share My Bet',
    ShareBetLink = 'Share Bet Link',
    UserClicksButton = 'User Clicks "Share My Bet" Button',
    NotApplicable = 'not applicable',
    ShareMyBetClick = 'Share My Bet - Click',
    BetShared = 'Bet - Shared',
    CopyShareLinkClick = 'Copy Share Link - Click',
    LinkExpiredError = 'Link Expired - Error',
    ErrorLoaded = 'Error - Loaded',
    ErrorOkButton = 'OK CTA - Clicked',
    RefBet = 'refbet',
}

export enum ShareMyBetCookie {
    Onboarding = 'shareMyBetOnboarding',
    Complience = 'shareMyBetComplience', // Complience, Needs to be removed after the next release
    Compliance = 'shareMyBetCompliance',
    TrackingRefBet = 'trackingRefbet',
}
