export enum MarketView {
    Win = 'Win',
    Forecast = 'Forecast',
    Tricast = 'Tricast',
    Specials = 'Specials',
    ForecastAndTricast = 'ForecastAndTricast',
    RacingForcastAndTricast = 'Forecast / Tricast',
}

export enum RacingOptionMarketTypes {
    WinEw = 'WinEW',
    Forecast = 'Forecast',
    Tricast = 'Tricast',
    BettingWithout = 'BettingWithout',
    ToFinishX = 'ToFinishX',
    InsuranceX = 'InsuranceX',
    TopX = 'TopX',
    WinnerByHowFar = 'WinnerByHowFar',
    WinByXlengths = 'WinByXLengths',
    WinEwExtraPlace = 'WinEWExtraPlace',
    FreeFormed = 'FreeFormed',
    EachWay = 'EachWay',
    InsideVsOutside = 'InsideVsOutside',
    OddsVsEvens = 'OddsVsEvens',
    TrapWinner = 'TrapWinner',
}

export const GreyHoundsRacingMarketParticipantTypes = {
    WinEW: 'Dog',
    Forecast: 'Trap',
    Tricast: 'Trap',
    BettingWithout: 'Trap',
    ToFinishX: 'Dog',
    InsuranceX: 'Dog',
    TopX: 'Dog',
    FreeFormed: 'Trap',
    InsideVsOutside: 'Trap',
    TrapWinner: 'Trap',
    OddsVsEvens: 'Trap',
};

export enum GreyHoundsOptionTypes {
    Inside = 'Inside',
    Outside = 'Outside',
    Odds = 'Odd',
    Evens = 'Even',
}
