import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

import { switchMap } from 'rxjs';

import { HelpBoxBadgeComponent } from '../help-box/help-box-badge.component';
import { BestOddsFixtureLikeInput } from './models/models';
import { BestOddsGuaranteedService } from './services/best-odds-guaranteed.service';

@Component({
    selector: 'ms-race-view-best-odds-badge',
    templateUrl: './race-view-best-odds-badge.html',
    standalone: true,
    imports: [HelpBoxBadgeComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RaceViewBestOddsBadgeComponent {
    fixture = input.required<BestOddsFixtureLikeInput>();
    fixture$ = toObservable(this.fixture);
    showBadge = toSignal(this.fixture$.pipe(switchMap((fixture) => this.bestOddsService.isFixtureEligible(fixture))), { requireSync: false });

    constructor(private bestOddsService: BestOddsGuaranteedService) {}
}
