export enum SlipTypeId {
    Single = 1,
    Combo = 2,
    System2Of3 = 3,
    SystemPatent = 4,
    SystemTrixie = 5,
    System2Of4 = 6,
    System3Of4 = 7,
    SystemYankee = 8,
    SystemLucky15 = 9,
    System2Of5 = 10,
    System3Of5 = 11,
    System4Of5 = 12,
    SystemCanadian = 13,
    SystemLucky31 = 14,
    System2Of6 = 15,
    System3Of6 = 16,
    System4Of6 = 17,
    System5Of6 = 18,
    SystemHeinz = 19,
    SystemLucky63 = 20,
    System2Of7 = 21,
    System3Of7 = 22,
    System4Of7 = 23,
    System5Of7 = 24,
    System6Of7 = 25,
    SystemSuperHeinz = 26,
    System2Of8 = 27,
    System3Of8 = 28,
    System4Of8 = 29,
    System5Of8 = 30,
    System6Of8 = 31,
    System7Of8 = 32,
    SystemGoliath = 33,
    SystemBankerSingles = 34,
}

export enum MyBetsConstants {
    OutcomeEarlyPayout = 'EARLY_PAYOUT',
    ScopeLive = 'LIVE',
    ScopeAll = 'ALL',
    TypeSingle = 'Single bet',
    TypeMulti = 'Multi bet',
}
