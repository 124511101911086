import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, computed, input } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { ShowInEpcotDirective } from '../common/epcot-config.directive';
import { TrustPipe } from '../common/trust.pipe';
import { HelpBoxBadgeComponent } from './help-box-badge.component';
import { HELP_BOX_TYPE, HelpBoxType } from './help-box-type';
import { HelpBoxModel, HelpBoxService } from './help-box.service';

@Component({
    selector: 'ms-help-box',
    templateUrl: './help-box.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ShowInEpcotDirective, NgTemplateOutlet, TrustPipe, HelpBoxBadgeComponent],
})
export class HelpBoxComponent implements OnInit {
    boxType = input<HelpBoxType>(HELP_BOX_TYPE.BestOddsGuarantee);
    disableInfoIcon = input<boolean>(false);
    helpBoxData = computed<HelpBoxModel>(() => this.hbService.getHelpBoxData(this.boxType()));
    @Output() closeButtonClick = new EventEmitter<void>();
    @Output() infoIconClick = new EventEmitter<void>();
    @Output() initHelpBox = new EventEmitter<void>();

    constructor(
        public sitecore: Sitecore,
        private hbService: HelpBoxService,
    ) {}

    ngOnInit(): void {
        if (this.boxType() === HELP_BOX_TYPE.LineSwitcher) {
            this.initHelpBox.next();
        }
    }

    onCloseButtonClick(): void {
        this.closeButtonClick.next();
    }

    onInfoIconClick(): void {
        this.infoIconClick.next();
    }
}
