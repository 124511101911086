import { Injectable } from '@angular/core';

import { ModalRef } from '../common/modal-ref';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { IConfirmationDialogInput, IConfirmationDialogModel } from './confirmation-dialog.model';
import { DialogSettings } from './modal-dialog.model';
import { ModalDialogService } from './modal-dialog.service';

@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {
    constructor(private modalDialogService: ModalDialogService) {}

    showDialog(model: IConfirmationDialogModel): ModalRef {
        const confirmationDialogCssClass = 'confirmation-dialog';

        const input: IConfirmationDialogInput = {
            contentModel: model,
        };

        const settings: DialogSettings = {
            showDialogHeader: true,
            hideCloseButton: true,
            title: model.title,
            cssClass: [confirmationDialogCssClass, model.cssClass || ''].join(' '),
            isModalDialog: false,
        };
        if (model.backdropClass) {
            settings.backdropClass = model.backdropClass;
        }

        return this.modalDialogService.openDialog(ConfirmationDialogComponent, { data: input, settings });
    }
}
