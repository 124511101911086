import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';

import { HooksWireup, OnDestroyCleanup, StringDictionary } from '@frontend/sports/common/base-utils';
import { LinkListLayout } from '@frontend/sports/types/components/widget/types';
import { takeUntil } from 'rxjs/operators';

import { ListItem } from '../../common/item/item.model';
import { CrmPromotionProviderService, PromotionMap } from '../../crm-promotion/crm-promotion-provider.service';
import { CrmPromotionModule } from '../../crm-promotion/crm-promotion.module';
import { NOT_APPLICABLE, trackingConstants } from '../../tracking/tracking.models';
import { TrackingService } from '../../tracking/tracking.service';

@HooksWireup()
@Component({
    standalone: true,
    imports: [CrmPromotionModule],
    selector: 'ms-top-bottom-sectionlinks',
    templateUrl: 'top-bottom-section-links.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBottomSectionLinksComponent extends OnDestroyCleanup implements OnInit, OnChanges {
    @Input() itemClass = '';
    @Input() itemListClass = '';
    @Input() tracking?: string;
    @Input() showArrows = true;
    @Input() items: ListItem[];
    @Input() moduleName?: string;
    @Input() modulePosition?: number;
    @Input() moduleSection?: string;
    @Input() layout = '';
    @Input() showSmallIcons = false;

    promotedCompetitionIdsMap: StringDictionary<number> = {};
    promotedSportIdsMap: StringDictionary<number> = {};
    linkClass = '';

    constructor(
        private trackingService: TrackingService,
        private crmPromotionProvider: CrmPromotionProviderService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnChanges(): void {
        this.setItemClass();
    }

    ngOnInit(): void {
        this.crmPromotionProvider.promotionsMap$.pipe(takeUntil(this.destroyed$)).subscribe((ids) => this.promotionMapChanged(ids));
    }

    setTrackingDetails(data: ListItem): void {
        const index = this.items.indexOf(data);

        this.trackingService.track(trackingConstants.EVENT_TRACKING, {
            [trackingConstants.COMPONENT_LABEL_EVENT]: 'Quick Links',
            [trackingConstants.COMPONENT_CATEGORY_EVENT]: 'Links',
            [trackingConstants.COMPONENT_ACTION_EVENT]: 'Click',
            [trackingConstants.COMPONENT_POSITION_EVENT]: NOT_APPLICABLE,
            [trackingConstants.COMPONENT_CONTENT_POSITION]: index,
            [trackingConstants.COMPONENT_LOCATION_EVENT]: NOT_APPLICABLE,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: data.title ?? NOT_APPLICABLE,
            [trackingConstants.COMPONENT_SITECORE_ID]: data.sitecoreId ?? NOT_APPLICABLE,
            [trackingConstants.COMPONENT_USER_SEGMENTS]: data.userSegmentGroups ?? NOT_APPLICABLE,
        });
    }

    private promotionMapChanged(promoMap: PromotionMap): void {
        this.promotedCompetitionIdsMap = promoMap.competitions;
        this.promotedSportIdsMap = promoMap.sports;
        this.changeDetectorRef.markForCheck();
    }

    private setItemClass(): void {
        let layoutClass = '';

        switch (this.layout) {
            case LinkListLayout.Stacked:
                layoutClass = 'stacked-view';
                break;
            case LinkListLayout.TilesCarousel:
                layoutClass = this.showSmallIcons ? 'tiles-carousel-view small-icons' : 'tiles-carousel-view';
                break;
            case LinkListLayout.MiniCarousel:
                layoutClass = 'mini-carousel-view';
                break;
            case LinkListLayout.Grid:
                layoutClass = 'grid-view';
                break;
            default:
                layoutClass = '';
                break;
        }

        this.linkClass = [this.itemClass, layoutClass].join(' ');
    }
}
