import { Directive, Input, OnInit, inject } from '@angular/core';

import { ThemeOptions, injectCurrentTheme } from '@frontend/sports/common/ui-lib/shared';
import { DsButton, DsButtonKind, DsButtonSize, DsButtonVariant } from '@frontend/ui/button';

@Directive({
    selector: '[msThemedButton]',
    standalone: true,
})
export class SportsThemedButtonDirective implements OnInit {
    dsButton = inject(DsButton, { self: true });
    currentTheme = injectCurrentTheme();

    @Input() themeKind: ThemeOptions<DsButtonKind> = {};
    @Input() themeVariant: ThemeOptions<DsButtonVariant> = {};
    @Input() themeSize: ThemeOptions<DsButtonSize> = {};
    @Input() themeInverse: ThemeOptions<boolean> = {};

    ngOnInit() {
        const kind = this.themeKind[this.currentTheme];
        const variant = this.themeVariant[this.currentTheme];
        const size = this.themeSize[this.currentTheme];
        const inverse = this.themeInverse[this.currentTheme];

        if (kind) {
            this.dsButton.kind = kind;
        }

        if (variant) {
            this.dsButton.variant = variant;
        }

        if (size) {
            this.dsButton.size = size;
        }

        if (inverse) {
            this.dsButton.inverse = inverse;
        }
    }
}
