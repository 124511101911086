import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output } from '@angular/core';

import { ISimpleChanges } from '@frontend/sports/common/base-utils';
import { padStart } from 'lodash-es';
import { Subscription } from 'rxjs';

import { DateProviderService } from '../common/date-provider.service';
import { TickerInterval, TickerService } from '../common/ticker.service';

@Component({
    selector: 'ms-count-down',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountDownComponent implements OnDestroy, OnChanges {
    @Input() until: Date;
    @Output() end = new EventEmitter<void>();
    @Output() tick = new EventEmitter<number>();
    @HostBinding('class') className = 'count-down';

    private subscription: Subscription;

    constructor(
        private tickerService: TickerService,
        private elementRef: ElementRef,
    ) {}

    ngOnDestroy(): void {
        this.stop();
    }

    ngOnChanges(changes: ISimpleChanges<CountDownComponent>): void {
        if (!changes.until || !changes.until.currentValue) {
            throw new Error('Until should be defined');
        }

        const diff = this.diff();

        this.stop();
        this.text(diff);

        if (diff > 0) {
            this.start();
        }
    }

    private start(): void {
        this.stop();

        this.subscription = this.tickerService.subscribe(() => this.ticked(), TickerInterval.Second);
    }

    private stop(): void {
        this.subscription?.unsubscribe();
    }

    private ticked(): void {
        const diff = this.diff();

        if (diff <= 0) {
            this.stop();
            this.end.emit();
        }

        this.text(diff);
        this.tick.emit(diff);
    }

    private text(diff: number): void {
        this.elementRef.nativeElement.textContent = this.format(diff);
    }

    private diff(): number {
        const diff = (this.until.getTime() - DateProviderService.now()) / 1000;

        return Math.max(Math.ceil(diff), 0);
    }

    private format(diff: number): string {
        const pad = (value: number) => padStart(value.toString(), 2, '0');

        const seconds = Math.floor(diff % 60);
        const minutes = Math.floor((diff / 60) % 60);
        const hours = Math.floor(diff / (60 * 60));

        const segments = [];

        if (hours) {
            segments.push(hours);
            segments.push(pad(minutes));
        } else {
            segments.push(minutes);
        }

        segments.push(pad(seconds));

        return segments.join(':');
    }
}
