import { PicksView } from '@cds/betting-offer/domain-specific';
import { MyBetsBetSlip } from 'packages/sports/web/app/src/my-bets-base/models/my-bets-viewmodels';
import { MarketUrlParam } from 'packages/sports/web/app/src/navigation-core/url-helper.service';

export interface IEditMyBetInitPayload {
    sourceBetslip: MyBetsBetSlip;
}
export interface EditMyBetPicksPayload extends IEditMyBetInitPayload {
    picksView: PicksView;
    sourcePageState: IBetslipSourcePageState;
}

export enum SourcePageType {
    MyBets,
    Event,
    None,
}

export interface SourcePageEventOptions {
    eventId: string;
    eventName: string;
    market: MarketUrlParam;
}

export interface IBetslipSourcePageState {
    pageType: SourcePageType;
    options: SourcePageEventOptions | null;
}
