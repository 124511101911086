import {
    AdditionalInformation as AdditionalInformationBpos,
    BetGroupType,
    BetSlipType,
    EdsPromoTokenType,
    KeyValueInformation,
    Money,
    Odds,
    OddsFormat,
    SystemType,
} from '@bpos';
import { BetType } from '@bpos/common';
import { AccaBoostDeviationReason } from '@bpos/extended';
import {
    BestOddsGuaranteedInformation,
    BestOddsGuaranteedState,
    Bet,
    BetBuilderBetState,
    BetGroup,
    BetProtectorInformation,
    BetSlip,
    BetState,
    EditBetHistoryInformation as EditBetHistoryInformationBpos,
    EdsPromotionToken,
    FixtureType,
    ParticipantPick,
    PlaceTerm,
    PromoTokenType,
    PromotionToken,
    ProtectorState,
    SignPost,
    SignPostType,
    SlipState,
    SystemBetDetails,
    Tax,
    TaxInformation as TaxInformationResponse,
} from '@bpos/v1/my-bets';
import { OfferSource } from '@cds';
import { PicksView } from '@cds/betting-offer/domain-specific';
import { Nullable, SportConstant, exhaustiveCheckGuard } from '@frontend/sports/common/base-utils';
import { CrmConfig } from '@frontend/sports/common/client-config-data-access';
import { BetslipListType } from '@frontend/sports/types/models/my-bets';
import { isEmpty, partition, sortBy } from 'lodash-es';
import { BetAndGetEdsToken, PromoTokenPlaceBetSignPostingInfo } from 'packages/sports/common/betslip/modules/reward-tokens/reward-tokens.model';
import { isEdsPromoTokenBetAndGet } from 'packages/sports/common/betslip/modules/reward-tokens/services/reward-tokens.utils';

import { MyBetsEventModel } from '../../my-bets/models/my-bets-event.model';
import { IEarlyPayoutPicksResult } from './early-payout';
import { EarlyPayoutStates, IEarlyPayoutData, PayoutError } from './early-payout-types';
import { MyBetsRaceBetType } from './my-bets-response';

export interface IEarlyPayoutResultedPicks {
    resultedPicks: IEarlyPayoutPicksResult[];
}

export class EarlyPayout implements IEarlyPayoutData, IEarlyPayoutResultedPicks {
    state: EarlyPayoutStates;
    value: number;
    /**
		Has Value only for FreeBets. 
		Should not be send to EPS during Cashout!
		Used only to show to the customer and in Web tracking.
		currency.pipe ensures the new value is rounded and formatted accordingly
     */
    valueWithoutStake?: number;
    userExpectedValue: number;
    acceptMode: any;
    earlyPayoutPossible: boolean;
    editBetPossible: boolean;
    error?: PayoutError;
    autoCashOutValue?: number;
    /**
		Has Value only for FreeBets.
		Used only to show to the customer.
     */
    autoCashOutValueWithoutStake?: number;
    autoCashoutNotificationValue?: number;
    /**
		Has Value only for FreeBets.
		Used only to show to the customer.
     */
    autoCashoutNotificationValueWithoutStake?: number;
    resultedPicks: IEarlyPayoutPicksResult[];
    pushUpdate?: boolean;
}

export enum MigrationTabType {
    After = 'After',
    Before = 'Before',
}

export enum TaxationModel {
    OnWinnings = 'OnWinnings',
    OnStake = 'OnStake',
}

export enum SgpType {
    Sgp = 'Sgp',
    SgpPlus = 'Sgp+',
}

export class EditBetHistoryInformation {
    betSlipNumber: string;
    editDate: Date | null;
    editOrder: number;

    constructor(editBetsHistory: EditBetHistoryInformationBpos | undefined) {
        if (editBetsHistory) {
            this.betSlipNumber = editBetsHistory.betNumber;
            this.editDate = new Date(editBetsHistory.editDate);
            this.editOrder = editBetsHistory.editOrder;
        } else {
            this.betSlipNumber = '';
            this.editDate = null;
            this.editOrder = 0;
        }
    }
}

export class MyBetsBetV2ViewModel {
    fixtureId: number;
    compoundId: string;

    get fixtureCompoundId(): string {
        return this.compoundId ? this.compoundId : `2:${this.fixtureId}`;
    }
}

export class MyBetsParticipantData extends MyBetsBetV2ViewModel {
    isStartingPrice: boolean;
    placeDeadHeatDivisor: string | undefined;
    ruleFourDeduction: string | undefined;
    placeRule4Deduction: string | undefined;
    xCastSettlementDeviated: boolean;
    deadheatDivisor: string | undefined;
    betType: MyBetsRaceBetType;
    betTypeName: string;
    marketId: string | undefined;
    participants: string[];
    participantInfo: { [participantFixtureId: number]: number };
    bestOddsGuaranteedState: BestOddsGuaranteedState;
}

export class MyBetsResultData {
    constructor() {
        this.event = null;
    }

    event: MyBetsEventModel | null;
    eventId: number;
    eventName: string;
    eventDate: Date;
    marketId: number;
    marketName: string;
    optionId: number;
    optionName: string;
    leagueId: number;
    odds: number;
}

enum HbsFixtureType {
    Antepost = 0,
    DayOfRace = 1,
    Standard = 2,
    PairGame = 3,
    Tournament = 4,
    Unknown = 5,
}

export class MyBetsOptionMarketData extends MyBetsBetV2ViewModel {
    fixtureName: string;
    fixtureType: HbsFixtureType;
    optionMarketId: number;
    optionId: number;
    event: MyBetsEventModel | null;
    virtualSport: boolean;
    priceId: number;
    betType?: BetType;
    isStartingPrice: boolean;
    bestOddsGuaranteedState: BestOddsGuaranteedState;
}

export class MyBetsBetBuilderData extends MyBetsOptionMarketData {
    constructor() {
        super();
        this.event = null;
    }

    eventId: number;
    legs: MyBetsBetBuilderLeg[];
    offerSource: OfferSource;
    betBuilderType: 'Ogp' | 'BetBuilder';
}

export interface MyBetsBetBuilderLeg {
    name: string;
    betState: BetBuilderLegState | BetState;
    cancellationReason?: string;
}

export enum BetBuilderLegState {
    Open = 'OPEN',
    Lost = 'LOST',
    Won = 'WON',
    Cancelled = 'CANCELED',
}

export class MyBetsListContext {
    isEventView: boolean;
    isPrintView: boolean;
    eventId?: string;
    eventName?: string;
    listType?: BetslipListType;
    migrationTabType: MigrationTabType | null;
    associatedAccount: Nullable<string>;
    showMigrationTabs?: boolean;
    showPrintButtons?: boolean;
    showFilter?: boolean;
}

export class EarlyPayoutInformation {
    autoCashoutTriggered: boolean;
    autoCashoutThresholdValue: number;
}

export enum ResettlementEffectOnCustomer {
    Positive = 'Positive',
    Negative = 'Negative',
}

export enum BetStateCssClass {
    BetInProgress = 'bet-in-progress',
    BetSettled = 'bet-settled',
    BetWon = 'bet-won',
    BetLost = 'bet-lost',
    BetCancelled = 'bet-cancelled',
}

export class AdditionalInformation {
    boostedOdds?: number;
    boostedNativeOdds?: Odds;
    boostedWinnings?: number;
    boostedWinningsCurrencyCode?: string;
    winningsBoost?: number;
    winningsBoostCurrencyCode?: string;
    riskFreeMaxCompensation?: number;
    riskFreePercentage?: number;
    tokenNotRewarded?: boolean;
    positiveResettlementEffectOnCustomer?: boolean;
    negativeResettlementEffectOnCustomer?: boolean;
    accaBoostDeviationReason?: AccaBoostDeviationReason;
    boostRatio?: number;
    bestOddsBonus?: number;
    fixedPriceWinnings?: number;
    liability?: number;
    possibleLiability?: number;
    totalTax?: number;
    possibleTotalTax?: number;
    currencyCode?: string;

    private static getNativeBoostedOdds(additionalInformations: AdditionalInformationBpos): Odds | undefined {
        const mapIterable = additionalInformations.informationItems.map(({ key, value }) => [key, value] as [string, string]);
        const additionalInfoMap = new Map<string, any>(mapIterable);

        const oddsFormat = additionalInfoMap.get('BoostedOdds.OddsFormat');
        if (oddsFormat == null) {
            return;
        }

        const odds = {
            oddsFormat,
        } as Odds;

        switch (oddsFormat) {
            case OddsFormat.European:
                const euValue = additionalInfoMap.get('BoostedOdds.European');
                odds.european = Number(euValue);
                break;
            case OddsFormat.British:
                const numerator = additionalInfoMap.get('BoostedOdds.British.Numerator');
                const denominator = additionalInfoMap.get('BoostedOdds.British.Denominator');
                odds.british = {
                    numerator: Number(numerator),
                    denominator: Number(denominator),
                };
                break;
            case OddsFormat.American:
                const usValue = additionalInfoMap.get('BoostedOdds.American');
                odds.american = Number(usValue);
                break;
        }

        return odds;
    }

    static createFromAdditionalInfoResponse(additionalInformation: AdditionalInformationBpos, state?: SlipState): AdditionalInformation {
        const model = new AdditionalInformation();

        if (!additionalInformation?.informationItems) {
            return model;
        }

        model.boostedNativeOdds = this.getNativeBoostedOdds(additionalInformation);
        for (const info of additionalInformation.informationItems) {
            switch (info.key) {
                case 'BoostedOdds':
                    model.boostedOdds = Number(info.value);
                    break;
                case 'BoostedWinnings':
                    model.boostedWinnings = Number(info.value);
                    break;
                case 'BoostedWinningsCurrencyCode':
                    model.boostedWinningsCurrencyCode = info.value;
                    break;
                case 'WinningsBoost':
                    model.winningsBoost = Number(info.value);
                    break;
                case 'WinningsBoostCurrencyCode':
                    model.winningsBoostCurrencyCode = info.value;
                    break;
                case 'RiskFreeMaxCompensation':
                    model.riskFreeMaxCompensation = Number(info.value);
                    break;
                case 'RiskFreePercentage':
                    model.riskFreePercentage = Number(info.value);
                    break;
                case 'TokenNotRewarded':
                    model.tokenNotRewarded = info.value === 'true';
                    break;
                case 'ResettlementEffectOnCustomer':
                    model.positiveResettlementEffectOnCustomer = info.value === ResettlementEffectOnCustomer.Positive;
                    model.negativeResettlementEffectOnCustomer = info.value === ResettlementEffectOnCustomer.Negative;
                    break;
                case 'AccaBoostDeviationReason':
                    model.accaBoostDeviationReason = info.value as AccaBoostDeviationReason;
                    break;
                case 'AccaBoostRatio':
                    model.boostRatio = Number(info.value);
                    break;
                case 'FixedPriceWinnings':
                    model.fixedPriceWinnings = Number(info.value);
                    break;
                case 'BestOddsBonus':
                    model.bestOddsBonus = Number(info.value);
                    break;
                case 'liability':
                    if (state === SlipState.Open) {
                        model.possibleLiability = Number(info.value);
                        break;
                    }

                    model.liability = Number(info.value);
                    break;
                case 'possibleLiability':
                    model.possibleLiability = Number(info.value);
                    break;
                case 'totalTax':
                    if (state === SlipState.Open) {
                        model.possibleTotalTax = Number(info.value);
                        break;
                    }

                    model.totalTax = Number(info.value);
                    break;
                case 'possibleTotalTax':
                    model.possibleTotalTax = Number(info.value);
                    break;
                case 'currencyCode':
                    model.currencyCode = info.value;
                    break;
            }
        }

        if (model.bestOddsBonus && model.fixedPriceWinnings) {
            model.boostedWinnings = model.fixedPriceWinnings + model.bestOddsBonus;
        }

        return model;
    }
}

export enum TokenType {
    FreeBetToken = 'FreeBetToken',
    OddsBoostToken = 'OddsBoostToken',
    RiskFreeToken = 'RiskFreeToken',
    AccaBoostToken = 'AccaBoostToken',
    BestOddsToken = 'BestOddsToken',
    BetAndGetToken = 'BetAndGetToken',
}

export enum PayoutType {
    Cash = 'Cash',
    FreeBet = 'FreeBet',
    Bonus = 'Bonus',
}

export class UsedRewardToken {
    payoutType?: PayoutType;
    tokenType: TokenType;
    additionalInformation?: AdditionalInformation;

    static createFromResponse(promoToken: PromotionToken, state?: SlipState): UsedRewardToken {
        const parsedValue = new UsedRewardToken();

        if (promoToken.payoutType) {
            parsedValue.payoutType = PayoutType[promoToken.payoutType];
        }

        parsedValue.tokenType = TokenType[`${promoToken.tokenType.toString()}Token`];
        parsedValue.additionalInformation = AdditionalInformation.createFromAdditionalInfoResponse(promoToken.additionalInformation, state);

        return parsedValue;
    }
}

export class TaxInformation {
    taxes: Tax[];
    additionalInformation: AdditionalInformationBpos;
}

interface IMyBetsBet {
    eventDate: Date;
    eventName: string; // V1 Event Name, V2 Racing Fixture Name (Antepost), V2 Meeting Name ( Day of Race ), V2 Golf - Fixture Group
    marketName: string; // V1 Market Name, V2 Market Name (Racing - None, Golf - Fixture Name)
    betTypeName: string; // V1 (None), V2 (To Win, Each Way, Forecast, Tricast ... )
    optionName: string; // V1 Option Name, V2 Participant(s) or Option Market name.

    marketId: number;

    outcome: string; // Option that won.  ( Can be option name which won)
    state: BetState; // If our pick won or lost. When Cached out this can be LOST but betslip will be WON.

    isBanker: boolean;
    cancellationReason: string;
    betIndex: number;

    odds: Odds;

    sportName: string;
    leagueName: string;
    sportId: number;
    leagueId: number;
}

export interface IMyBetsBetWithResultInfo extends IMyBetsBet {
    resultInfo: MyBetsResultData;
}

export interface IMyBetsBetWithOptionMarketInfo extends IMyBetsBet {
    optionMarketInfo: MyBetsOptionMarketData;
}

export interface IMyBetsBetWithParticipantInfo extends IMyBetsBet {
    participantInfo: MyBetsParticipantData;
}

export interface IMyBetsBetWithBetBuilderInfo extends IMyBetsBet {
    betBuilderInfo: MyBetsBetBuilderData;
}

export interface OgpRepricingInfo {
    isPending: boolean;
    odds?: Odds;
}

export class MyBetsBet implements IMyBetsBet {
    eventDate: Date;
    eventName: string; // V1 Event Name, V2 Racing Fixture Name (Antepost), V2 Meeting Name ( Day of Race ), V2 Golf - Fixture Group
    marketName: string; // V1 Market Name, V2 Market Name (Racing - None, Golf - Fixture Name)
    betTypeName: string; // V1 (None), V2 (To Win, Each Way, Forecast, Tricast ... )
    optionName: string; // V1 Option Name, V2 Participant(s) or Option Market name.
    marketId: number;
    optionNames: string[]; // V1 (None), V2 (Forecast, Tricast ... )

    outcome: string; // Option that won.  ( Can be option name which won)
    state: BetState; // If our pick won or lost. When Cached out this can be LOST but betslip will be WON.

    isBanker: boolean;
    cancellationReason: string;
    betIndex: number;
    odds: Odds;
    participantInfo: MyBetsParticipantData | null;
    resultInfo: MyBetsResultData | null;
    optionMarketInfo: MyBetsOptionMarketData | null;
    betBuilderInfo: MyBetsBetBuilderData | null;
    leagueName: string;
    sportId: number;
    sportName: string;
    leagueId: number;
    priceBoostOriginalOdds: Odds;
    isPriceBoosted: boolean;
    ogpRepricingInfo: OgpRepricingInfo | undefined;
    betType?: string;

    /**
     * Generates place terms formatted text e.g. 1/3 1-2-3
     *
     * @param terms Place terms data from the response
     */
    private static getPlaceTermsText(terms: PlaceTerm): string {
        const places = [...Array(terms.numberOfFirstPlaces)].map((_, i) => i + 1).join('-');

        return `${terms.fraction.numerator}/${terms.fraction.denominator} ${places}`;
    }

    static createFromBetResponse(bet: Bet): MyBetsBet {
        const viewModel = new MyBetsBet();
        if (bet.fixture.date) {
            viewModel.eventDate = new Date(bet.fixture.date);
        }
        viewModel.outcome = bet.outcome;
        viewModel.isBanker = bet.isBanker;
        viewModel.cancellationReason = bet.cancellationReason || '';
        viewModel.betIndex = bet.index;
        viewModel.eventName = bet.fixture.name || '';
        viewModel.marketName = bet.market.name || '';
        viewModel.marketId = bet.market.id!;
        viewModel.optionName = bet.option?.name || '';
        if (bet.participantBetDetails) {
            viewModel.participantInfo = MyBetsBet.createFromParticipantBetDetails(bet);
            viewModel.optionName = viewModel.participantInfo.participants.join(', ');
            // TODO: BPOSv4 investigate if both fields `betTypeName` are actually needed
            viewModel.betTypeName = viewModel.participantInfo.betTypeName;
        } else if (bet.optionBetDetails) {
            const isBetBuilder = Boolean(bet.optionBetDetails.isBetBuilder && bet.optionBetDetails.betBuilderData);
            if (isBetBuilder) {
                viewModel.betBuilderInfo = this.createFromBetBuilderDetails(bet);
                viewModel.betBuilderInfo.betBuilderType = 'BetBuilder';
            } else {
                viewModel.optionMarketInfo = this.createFromOptionBetDetails(bet);
            }
            if (bet.sport.id === SportConstant.Horses || bet.sport.id === SportConstant.Greyhounds) {
                viewModel.optionNames = bet.optionBetDetails.picks.map((o) => o.optionName);
                if (viewModel.optionMarketInfo) viewModel.optionMarketInfo.isStartingPrice = bet.optionBetDetails.isStartingPrice;
            }
            viewModel.isPriceBoosted = bet.optionBetDetails.isPriceBoost;
            viewModel.priceBoostOriginalOdds = bet.optionBetDetails.priceBoostData?.originalOdds;
            viewModel.betType = bet.optionBetDetails.betType?.toString();
            viewModel.betTypeName = bet.optionBetDetails.betTypeName ?? '';
            if (bet.optionBetDetails.betType === BetType.EachWay && bet.optionBetDetails.placeTerm != null) {
                viewModel.betTypeName = `${bet.optionBetDetails.betTypeName} ${MyBetsBet.getPlaceTermsText(bet.optionBetDetails.placeTerm)}`;
            }
        } else {
            viewModel.resultInfo = MyBetsBet.createFromRegularOption(bet);
        }
        viewModel.odds = bet.odds;
        viewModel.state = bet.state;
        viewModel.leagueName = bet.competition?.name ?? '';
        viewModel.sportName = bet.sport.name ?? '';
        viewModel.sportId = bet.sport.id;
        // buildabet doesn't have competition
        viewModel.leagueId = bet.competition?.id ?? 0;

        return viewModel;
    }

    static createFromOgpBetGroupResponse(betGroup: BetGroup, bets: Bet[]): MyBetsBet {
        const bet = bets[0];
        const viewModel = new MyBetsBet();
        if (bet.fixture.date) {
            viewModel.eventDate = new Date(bet.fixture.date);
        }
        viewModel.outcome = betGroup.outcome;
        viewModel.betIndex = betGroup.index ?? 0;
        viewModel.eventName = bet.fixture.name ?? '';
        viewModel.betBuilderInfo = new MyBetsBetBuilderData();
        viewModel.betBuilderInfo.betBuilderType = 'Ogp';
        viewModel.betBuilderInfo.fixtureId = bet.fixture.id;
        viewModel.betBuilderInfo.legs = bets.map((b) => ({
            name: this.resolveLegName(b),
            betState: b.state,
            cancellationReason: b.cancellationReason ?? '',
        }));
        viewModel.odds = betGroup.odds;
        viewModel.state = betGroup.betGroupState;
        viewModel.leagueName = bet.competition?.name ?? '';
        viewModel.sportName = bet.sport.name ?? '';
        viewModel.sportId = bet.sport.id;
        // buildabet doesn't have competition
        viewModel.leagueId = bet.competition?.id ?? 0;
        viewModel.betBuilderInfo.compoundId = bet.fixture.compoundId;
        viewModel.ogpRepricingInfo = betGroup.ogpBetGroupDetails?.repricingInfo;

        return viewModel;
    }

    private static resolveLegName(bet: Bet): string {
        if (bet.participantBetDetails) {
            const participants = this.mapParticipants(bet.participantBetDetails.picks);

            return participants.join(', ');
        } else {
            return `${bet.market.name} - ${bet.option?.name}`;
        }
    }

    static createFromRegularOption(bet: Bet): MyBetsResultData {
        const viewModel = new MyBetsResultData();

        viewModel.eventId = bet.fixture.id;
        viewModel.eventName = bet.fixture.name || '';
        if (bet.fixture.date) {
            viewModel.eventDate = new Date(bet.fixture.date);
        }
        viewModel.marketId = bet.market.id!;
        viewModel.marketName = bet.market.name || '';
        viewModel.optionId = bet.option.id;
        viewModel.optionName = bet.option.name || '';
        viewModel.leagueId = bet.competition.id!;

        return viewModel;
    }

    static createFromParticipantBetDetails(bet: Bet): MyBetsParticipantData {
        const viewModel = new MyBetsParticipantData();
        viewModel.betTypeName = bet.participantBetDetails.betTypeName;
        viewModel.isStartingPrice = bet.participantBetDetails.isStartingPrice;
        viewModel.placeDeadHeatDivisor = bet.participantBetDetails.placeDeadHeatDivisor?.toString();
        viewModel.deadheatDivisor = bet.deadHeatDivisor?.toString();
        viewModel.placeRule4Deduction = bet.participantBetDetails.placeRule4Deduction?.toString();
        viewModel.ruleFourDeduction = bet.participantBetDetails.rule4Deduction?.toString();
        viewModel.xCastSettlementDeviated = bet.participantBetDetails.xCastSettlementDeviated;
        viewModel.betType = bet.participantBetDetails.betTypeId;
        viewModel.marketId = bet.market.id?.toString();

        if (viewModel.betType === MyBetsRaceBetType.EachWay && bet.participantBetDetails.placeTerm != null) {
            viewModel.betTypeName = `${viewModel.betTypeName} ${MyBetsBet.getPlaceTermsText(bet.participantBetDetails.placeTerm)}`;
        }
        viewModel.participants = this.mapParticipants(bet.participantBetDetails.picks);

        viewModel.bestOddsGuaranteedState = bet.participantBetDetails.bestOddsGuaranteedState;
        viewModel.fixtureId = bet.fixture.id;
        viewModel.compoundId = bet.fixture.compoundId;
        viewModel.participantInfo = bet.participantBetDetails.picks.reduce((result, curr) => {
            result[curr.fixtureParticipantId] = curr.positionInBet;

            return result;
        }, {});

        return viewModel;
    }

    private static mapParticipants(participantPick: ParticipantPick[]): string[] {
        return participantPick
            .sort((a, b) => {
                return a.positionInBet > b.positionInBet ? 1 : a.positionInBet < b.positionInBet ? -1 : 0;
            })
            .map((p) => p.participantName);
    }

    static createFromOptionBetDetails(bet: Bet): MyBetsOptionMarketData {
        const getFixtureType = (fixtureType?: FixtureType): HbsFixtureType => {
            switch (fixtureType) {
                case FixtureType.Antepost:
                    return HbsFixtureType.Antepost;
                case FixtureType.DayOfRace:
                    return HbsFixtureType.DayOfRace;
                case FixtureType.PairGame:
                    return HbsFixtureType.PairGame;
                case FixtureType.Standard:
                    return HbsFixtureType.Standard;
                case FixtureType.Tournament:
                    return HbsFixtureType.Tournament;
            }

            return HbsFixtureType.Unknown;
        };

        const viewModel = new MyBetsOptionMarketData();
        viewModel.fixtureName = bet.fixture.name;
        viewModel.fixtureId = bet.fixture.id;
        viewModel.fixtureType = getFixtureType(bet.fixture.type);
        viewModel.optionMarketId = bet.market.id!;
        viewModel.optionId = bet.option.id;
        viewModel.virtualSport = bet.optionBetDetails.isVirtual;
        viewModel.priceId = bet.optionBetDetails.pick?.id;
        viewModel.betType = bet.optionBetDetails.betType;
        viewModel.compoundId = bet.fixture.compoundId;

        return viewModel;
    }

    static createFromBetBuilderDetails(bet: Bet): MyBetsBetBuilderData {
        const { betBuilderData } = bet.optionBetDetails;
        const viewModel = new MyBetsBetBuilderData();

        viewModel.eventId = betBuilderData.tv1BetBuilderInfo?.event.id;
        viewModel.offerSource = betBuilderData.tv1BetBuilderInfo ? OfferSource.V1 : OfferSource.V2;
        viewModel.fixtureId = bet.fixture.id;
        viewModel.compoundId = bet.fixture.compoundId;
        viewModel.optionMarketId = bet.market.id!;
        viewModel.optionId = bet.option.id;
        viewModel.legs = (betBuilderData.legs || []).map(({ name, betState }) => ({ name, betState: convertBetBuilderBetState(betState) }));

        return viewModel;
    }

    constructor() {
        this.eventName = '';
        this.marketName = '';
        this.betTypeName = '';
        this.optionName = '';
        this.outcome = '';
        this.cancellationReason = '';
        this.state = BetState.Open;
        this.participantInfo = null;
        this.resultInfo = null;
        this.optionMarketInfo = null;
        this.betBuilderInfo = null;
        this.isBanker = false;
    }

    get isLive(): boolean {
        return !!(this.event && this.event.scoreboard.started);
    }

    get isVirtual(): boolean {
        return !!(this.optionMarketInfo && this.optionMarketInfo.virtualSport);
    }

    get needToShowEventView(): boolean {
        return !this.isVirtual && !!this.event && this.state === BetState.Open;
    }

    get event(): MyBetsEventModel | null {
        if (this.resultInfo) {
            return this.resultInfo.event;
        }

        if (this.betBuilderInfo) {
            return this.betBuilderInfo.event;
        }

        if (this.optionMarketInfo) {
            return this.optionMarketInfo.event;
        }

        return null;
    }
}

export class MyBetsBetslipBase {
    betType: BetSlipType;
    stake: Money;
    isPotentialEP: boolean;
    betPlacedDate: Date;
    betslipRealId: string;
    state: SlipState;
    isCachedOut: boolean;
    earlyPayout?: EarlyPayout;
    hideEP?: boolean;
    typeAsName: string;
    containsLiveBets: boolean;
    isFreeBet: boolean;
    get isSingleBet(): boolean {
        return this.betType === BetSlipType.Single;
    }

    sgpType?: SgpType;
}

export interface SignPostingData {
    offerId: string;
    offerType: string;
    rewardTime: number;
    rewards: RewardData[];
}

export interface RewardData {
    currency: string;
    maxCap?: number;
    type: string;
    value: number;
    noOfSlabs?: number;
    slabAmount?: number;
}

// TODO: Use the SignPostingReward below instead of bpos/v4 SignPostingReward, as it has been deprecated.
export interface SignPostingReward {
    currency: string;
    maxCap?: number;
    offerId: string;
    offerType: string;
    rewardTime: number;
    type: string;
    value: number;
    noOfSlabs?: number;
    slabAmount?: number;
}

export class MyBetsBetSlip extends MyBetsBetslipBase {
    systemType: SystemType | null;
    betslipId: string;
    bets: MyBetsBet[];
    totalOdds: Odds | undefined;
    isOGPRepricePending: boolean;
    totalOGPRepricedOdds: Odds | undefined;
    possibleWinnings: Money;
    payoutAmount: Money;
    systemBetDetails?: SystemBetDetails;
    /**
     * This includes taxation amount
     */
    grossPossibleWinnings: Money;
    /**
     * This includes taxation amount
     */
    grossPayoutAmount: Money;
    /**
     * Returns true when slip is lost, but bet protection is won
     */
    isRefunded: boolean;
    betProtectorInformation: BetProtectorInformation;
    taxInformation: TaxInformation;
    /**
     * Regulation specific e.g. italian regulator ticket
     */
    externalTicketId: string | null;
    /**
     * Regulation specific e.g. italian regulator license
     */
    licenseId: string | null;
    columnId: number;
    showDate: boolean;
    dateKey: number;
    isBasicModel: boolean;
    typeTranslated: string;
    bestOddsGuaranteeInformation: BestOddsGuaranteedInformation;
    earlyPayoutInformation?: EarlyPayoutInformation;
    isEditBet: boolean;
    editBetHistory: EditBetHistoryInformation[];
    isRecoveringAfterEditBet: boolean;
    signPostingRewards: SignPostingReward[];
    placeBetSignPosting: PromoTokenPlaceBetSignPostingInfo | BetAndGetEdsToken | undefined;
    usedRewardTokens: UsedRewardToken[];
    edsPromoTokens: EdsPromotionToken[];
    reBetPicks?: PicksView;
    listType?: BetslipListType;
    isPendingPayout: boolean;
    isTeaserBet: boolean;
    teasedAmount?: number;

    override get isSingleBet(): boolean {
        return this.betType === BetSlipType.Single || (this.betType === BetSlipType.Combo && this.bets.length === 1);
    }

    static createFromBetslipResponse(betslip: BetSlip, events: { [key: number]: MyBetsEventModel }, tokensConfig: CrmConfig): MyBetsBetSlip {
        const viewModel = new MyBetsBetSlip();
        viewModel.betType = betslip.slipType;
        viewModel.systemType = betslip.systemType || null;
        viewModel.betslipId = betslip.betSlipNumber;
        viewModel.betslipRealId = betslip.editBetInformation?.betSlipRealNumber || betslip.betSlipNumber;
        viewModel.betPlacedDate = new Date(betslip.conclusionDateUtc);
        viewModel.dateKey = calculateDateKey(betslip.conclusionDateUtc);
        viewModel.totalOdds = betslip.totalOdds;
        viewModel.isFreeBet = betslip.isFreeBet;
        viewModel.isCachedOut = betslip.isEarlyPayout;
        viewModel.isBasicModel = betslip.isBasicModel;
        viewModel.stake = betslip.stake || emptyMoney();
        viewModel.possibleWinnings = getCalculatedPossibleWinnings(tokensConfig, betslip);
        viewModel.payoutAmount = getCalculatedPayoutAmount(tokensConfig, betslip);
        viewModel.grossPossibleWinnings = betslip.grossPossibleWinnings || emptyMoney();
        viewModel.grossPayoutAmount = betslip.grossPayout || emptyMoney();
        viewModel.betProtectorInformation = betslip.betProtectorInformation || emptyBetProtector();
        viewModel.isRefunded = viewModel.betProtectorInformation.state === ProtectorState.Won;
        viewModel.taxInformation = getTaxInformation(betslip.taxInformation);
        viewModel.externalTicketId = betslip.externalTicketId || null;
        viewModel.licenseId = betslip.licenseId || null;
        viewModel.typeAsName = betslip.typeAsName;
        viewModel.typeTranslated = betslip.type;
        viewModel.containsLiveBets = betslip.containsLiveBets || false;
        viewModel.bets = MyBetsBetSlip.createFromApiResponse(betslip);
        viewModel.enhanceBetsWithEventData(events);
        viewModel.bestOddsGuaranteeInformation = betslip.bestOddsGuaranteedInformation || emptyBestOddsGuaranteed();
        viewModel.isEditBet = this.checkIsEditBet(betslip);
        viewModel.editBetHistory = (betslip.editBetInformation?.editBetHistory || []).map((e) => new EditBetHistoryInformation(e));
        viewModel.earlyPayoutInformation = betslip.earlyPayoutInformation as EarlyPayoutInformation;
        viewModel.isRecoveringAfterEditBet = betslip.editBetInformation?.editBetProgressInformation?.isRecovering || false;
        viewModel.usedRewardTokens = [MyBetsBetSlip.getUsedReward(betslip)!].filter(Boolean);

        const [placeBetSignPosting, postBetSignPosting] = partition(betslip.signPostings, (signPost) => signPost.type === SignPostType.BetPlacement);
        viewModel.signPostingRewards = getSignPostingRewards(postBetSignPosting, betslip);
        viewModel.placeBetSignPosting = betslip.isFreeBet ? undefined : getPlaceBetSignPosting(placeBetSignPosting, betslip);

        viewModel.state = betslip.state;
        viewModel.systemBetDetails = betslip.systemBetDetails;
        viewModel.edsPromoTokens = betslip.edsPromoTokens;
        viewModel.isPendingPayout = betslip.isEarlyPayout && betslip.isDelayedForEarlyPayout;
        const { isTeaserBet, teaserPoints } = MyBetsBetSlip.resolveTeaserBetInfo(betslip);

        viewModel.isTeaserBet = isTeaserBet;
        viewModel.teasedAmount = teaserPoints;
        viewModel.sgpType = MyBetsBetSlip.getSgpType(betslip);
        viewModel.isOGPRepricePending = betslip.betGroups.some((betGroup) => betGroup.ogpBetGroupDetails?.repricingInfo?.isPending);
        viewModel.totalOGPRepricedOdds = betslip.ogpRepricingInfo?.totalOdds;
        return viewModel;
    }

    static getUsedReward(betslip: BetSlip): Nullable<UsedRewardToken> {
        if (betslip.isFreeBet) {
            return { tokenType: TokenType.FreeBetToken };
        } else if (isEdsPromoTokenBetAndGet(betslip.edsPromoTokens)) {
            return { tokenType: TokenType.BetAndGetToken };
        } else if (!isEmpty(betslip.promoTokens)) {
            return UsedRewardToken.createFromResponse(betslip.promoTokens[0], SlipState.Open);
        } else {
            return null;
        }
    }

    static hasAccaBoost(betslip: BetSlip): boolean {
        return betslip.promoTokens && betslip.promoTokens[0]?.tokenType === PromoTokenType.AccaBoost;
    }

    static hasEdsToken(betslip: BetSlip): boolean {
        return betslip.edsPromoTokens && betslip.edsPromoTokens[0]?.tokenType === EdsPromoTokenType.Campaign;
    }

    private static checkIsEditBet(betslip: BetSlip): boolean {
        if (betslip.isEditBet === undefined) {
            return !!betslip.editBetInformation;
        }

        return betslip.isEditBet;
    }

    get usedRewardToken(): Nullable<UsedRewardToken> {
        if (this.usedRewardTokens && this.usedRewardTokens.length) {
            return this.usedRewardTokens[0];
        }

        return null;
    }

    private enhanceBetsWithEventData(events: { [key: string]: MyBetsEventModel }): void {
        for (const bet of this.bets) {
            if (bet.resultInfo) {
                bet.resultInfo.event = events[bet.resultInfo.eventId] || null;
            }
            if (bet.betBuilderInfo) {
                if (bet.betBuilderInfo.legs.length > 1 && !!bet.betBuilderInfo.compoundId) {
                    bet.betBuilderInfo.event = events[bet.betBuilderInfo.compoundId] ?? events[bet.betBuilderInfo.fixtureId] ?? null;
                }

                switch (bet.betBuilderInfo.offerSource) {
                    case OfferSource.V1:
                        bet.betBuilderInfo.event = events[bet.betBuilderInfo.eventId] ?? null;
                        break;
                    case OfferSource.V2:
                        bet.betBuilderInfo.event = events[bet.betBuilderInfo.compoundId] ?? null;
                        break;
                }
            }

            if (bet.optionMarketInfo) {
                bet.optionMarketInfo.event = events[bet.optionMarketInfo.compoundId] ?? null;
            }
        }
    }

    private static createFromApiResponse(betslip: BetSlip): MyBetsBet[] {
        const betslipBets = betslip.bets ?? [];
        const betslipGroups = betslip.betGroups ?? [];
        const betslipBetOGPGroups = betslipGroups.filter((betGroup) => betGroup.betGroupType === BetGroupType.Ogp);

        // The betslip.Bets collection contains bets that are part of groups and stand-alone bets. For bets that are part of OGP groups we will consider them as legs,
        // hence we skip them from regular bet mapping and use a custom <group, group bets> mapper that will yield a single mapped bet
        const betsByGroupMap = new Map<BetGroup, Bet[]>();
        const standAloneBets: Bet[] = [];
        for (const bet of betslipBets) {
            let isStandAloneBet = true;
            for (const betGroup of betslipBetOGPGroups) {
                const groupBets = betsByGroupMap.get(betGroup) || [];
                if (betGroup.bets.some((betIndex) => bet.index === betIndex)) {
                    isStandAloneBet = false;
                    groupBets.push(bet);
                }
                betsByGroupMap.set(betGroup, groupBets);
            }
            if (isStandAloneBet) {
                standAloneBets.push(bet);
            }
        }

        const ogpBetGroups = Array.from(betsByGroupMap.keys());

        const mappedStandAloneBets = standAloneBets.map((bet) => MyBetsBet.createFromBetResponse(bet));
        const groupBets = ogpBetGroups.map((betGroup) => MyBetsBet.createFromOgpBetGroupResponse(betGroup, betsByGroupMap.get(betGroup)!));

        return sortBy(groupBets.concat(mappedStandAloneBets), (mappedBet) => mappedBet.betIndex);
    }

    private static resolveTeaserBetInfo(betslip: BetSlip): { isTeaserBet: boolean; teaserPoints: number | undefined } {
        // we are expecting that there is only one TeaserBet per slip
        const teaserBetGroup = betslip.betGroups?.find((betGroup) => betGroup.betGroupType === BetGroupType.Teaser);

        return { isTeaserBet: !!teaserBetGroup, teaserPoints: teaserBetGroup?.teaserBetGroupDetails?.teaserPoints };
    }

    static getSgpType(betslip: BetSlip): SgpType | undefined {
        const sgpGroups = betslip.betGroups.filter((g) => g.index !== undefined && g.betGroupType === BetGroupType.Ogp);

        // if no bet groups - undefined
        if (!Array.isArray(sgpGroups) || !sgpGroups.length) {
            return undefined;
        }

        if (sgpGroups.length === 1) {
            const allBetsInSameGroup = betslip.bets.every((bet) => {
                return sgpGroups[0].bets.includes(bet.index);
            });

            return allBetsInSameGroup ? SgpType.Sgp : SgpType.SgpPlus;
        }

        // if more than one sgp group - SgpPlus
        return SgpType.SgpPlus;
    }
}

// These function are scoped inside module and do NOT leak into window-object
function calculateDateKey(isoDate: string): number {
    const [datePart]: string[] = isoDate.split('T');

    return parseInt(datePart.replace(/-/g, ''));
}

function getTaxInformation(taxInformation: TaxInformationResponse): TaxInformation {
    if (!taxInformation) {
        return emptyTaxInformation();
    }

    return {
        taxes: taxInformation.taxes,
        additionalInformation: taxInformation.additionalInformation,
    };
}

function emptyBestOddsGuaranteed(): BestOddsGuaranteedInformation {
    return {
        state: BestOddsGuaranteedState.NotEligible,
        fixedPriceWinnings: {
            value: 0,
            currency: '',
        },
    };
}

function emptyBetProtector(): BetProtectorInformation {
    return {
        fee: 0,
    } as BetProtectorInformation;
}

function emptyMoney(): Money {
    return {
        value: 0,
        currency: '',
    };
}

function emptyAdditionalInformation(): AdditionalInformationBpos {
    return {
        informationItems: [],
    };
}

function emptyTaxInformation(): TaxInformation {
    return {
        taxes: [],
        additionalInformation: emptyAdditionalInformation(),
    };
}

function getCalculatedPossibleWinnings(tokensConfig: CrmConfig, betslip: BetSlip): Money {
    if (tokensConfig.tokens?.useFreebetStakeInCalculations && betslip.isFreeBet) {
        return betslip.grossPossibleWinnings || emptyMoney();
    }

    return betslip.maxPayout || emptyMoney();
}

function getCalculatedPayoutAmount(tokensConfig: CrmConfig, betslip: BetSlip): Money {
    if (tokensConfig.tokens?.useFreebetStakeInCalculations && betslip.isFreeBet) {
        return betslip.grossPayout || emptyMoney();
    }

    return betslip.payout || emptyMoney();
}

export class MyBetsSlipEditBetHistoryViewModel extends MyBetsBetSlip {
    editBetTitle: string;

    static createEditBetFromBetslipResponse(betslip: BetSlip, title: string, tokensConfig: CrmConfig): MyBetsSlipEditBetHistoryViewModel {
        const viewModel = MyBetsBetSlip.createFromBetslipResponse(betslip, [], tokensConfig) as MyBetsSlipEditBetHistoryViewModel;
        viewModel.editBetTitle = title;

        return viewModel;
    }
}

export interface IBetSlipRepositoryResult {
    betslips: MyBetsBetSlip[];
    hasMore: boolean;
    pageIndex: number;
    listType: BetslipListType | null;
    errorLoadingBets: boolean | undefined;
}

function convertBetBuilderBetState(betBuilderBetState: BetBuilderBetState): BetBuilderLegState {
    switch (betBuilderBetState) {
        case BetBuilderBetState.Canceled:
            return BetBuilderLegState.Cancelled;
        case BetBuilderBetState.Lost:
            return BetBuilderLegState.Lost;
        case BetBuilderBetState.Open:
            return BetBuilderLegState.Open;
        case BetBuilderBetState.Won:
            return BetBuilderLegState.Won;
        default:
            return exhaustiveCheckGuard(betBuilderBetState);
    }
}

// When the new betslip.signPostings object have no postBetPlacement data and exists betslip.signPostingRewards then return betslip.signPostingRewards
function getSignPostingRewards(signPostings: SignPost[], betslip: BetSlip): SignPostingReward[] {
    try {
        if (signPostings.length === 0) {
            return betslip.signPostingRewards ?? [];
        }

        const parsedSignPostDetails = signPostings.map((signPost) => JSON.parse(signPost.data) as SignPostingData);
        parsedSignPostDetails.sort((a, b) => a.rewardTime - b.rewardTime);

        return parsedSignPostDetails.flatMap((obj) =>
            obj.rewards.map((reward) => ({
                currency: reward.currency,
                offerId: obj.offerId,
                offerType: obj.offerType,
                rewardTime: obj.rewardTime,
                type: reward.type,
                value: reward.value,
                maxCap: reward.maxCap,
                noOfSlabs: reward.noOfSlabs,
                slabAmount: reward.slabAmount,
            })),
        );
    } catch (error) {
        throw new Error('Error while parsing post bet placement signposting JSON');
    }
}

// When the new betslip.signPostings object have no betPlacement data and the betslip.edsPromoTokens tokenType is BetAndGet
// then return betslip.edsPromoTokens[0].additionalInformation PlaceBetSignPostingData
function getPlaceBetSignPosting(signPostings: SignPost[], betslip: BetSlip): PromoTokenPlaceBetSignPostingInfo | BetAndGetEdsToken | undefined {
    try {
        if (signPostings.length) {
            const parsedData = JSON.parse(signPostings[0].data);
            if (!isEmpty(betslip.promoTokens)) {
                return parsedData as PromoTokenPlaceBetSignPostingInfo;
            }
            if (isEdsPromoTokenBetAndGet(betslip.edsPromoTokens)) {
                return parsedData as BetAndGetEdsToken;
            }
        } else {
            if (isEdsPromoTokenBetAndGet(betslip.edsPromoTokens)) {
                const placeBetSignPost = betslip.edsPromoTokens[0].additionalInformation.informationItems.find(
                    (item: KeyValueInformation) => item.key === 'PlaceBetSignPostingData',
                )?.value;

                return placeBetSignPost ? (JSON.parse(placeBetSignPost) as BetAndGetEdsToken) : undefined;
            }
        }

        return;
    } catch (error) {
        throw new Error('Error while parsing bet placement signposting JSON');
    }
}
