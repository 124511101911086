import { Inject, Injectable } from '@angular/core';

import { EarlyPayoutConfig } from '@frontend/sports/common/client-config-data-access';

import { EPSDetailedLogger } from '../../logging/eps-detailed-logger.service';
import { LoggerFactory } from '../../logging/logger-factory.service';
import { UserService } from '../../user/services/user.service';
import {
    earlyPayoutServiceEditBetToken,
    earlyPayoutServiceMyBetsToken,
    earlyPayoutSubmitServiceEditBetToken,
    earlyPayoutSubmitServiceMyBetsToken,
    earlyPayoutSubscriptionServiceEditBetToken,
    earlyPayoutSubscriptionServiceMyBetsToken,
} from './early-payout-injection-tokens';
import { EarlyPayoutSubmitService } from './early-payout-submit.service';
import { EarlyPayoutSubscriptionMapperService } from './early-payout-subscription-mapper.service';
import { EarlyPayoutSubscriptionService } from './early-payout-subscription.service';
import { EarlyPayoutUtils } from './early-payout-utils';
import { EarlyPayoutService } from './early-payout.service';
import { EarlyPayoutHelpers } from './my-bets-earlypayout-helpers';
import { MyBetsEarlyPayoutService } from './my-bets-earlypayout.service';

class MyBetsEarlyPayoutServiceFactory {
    constructor(
        private epHelpers: EarlyPayoutHelpers,
        private loggerFactory: LoggerFactory,
        private msUser: UserService,
        private earlyPayoutService: EarlyPayoutService,
        private earlyPayoutSubscriptionService: EarlyPayoutSubscriptionService,
        private earlyPayoutSubmitService: EarlyPayoutSubmitService,
        private epsLogger: EPSDetailedLogger,
        private earlyPayoutSubscriptionMapperService: EarlyPayoutSubscriptionMapperService,
        private earlyPayoutUtils: EarlyPayoutUtils,
    ) {}

    create(): MyBetsEarlyPayoutService {
        return new MyBetsEarlyPayoutService(
            this.earlyPayoutService,
            this.earlyPayoutSubscriptionService,
            this.epHelpers,
            this.loggerFactory,
            this.msUser,
            this.earlyPayoutSubmitService,
            this.epsLogger,
            this.earlyPayoutSubscriptionMapperService,
            this.earlyPayoutUtils,
        );
    }
}

@Injectable({ providedIn: 'root' })
export class MyBetsEarlyPayoutServiceMyBetsFactory extends MyBetsEarlyPayoutServiceFactory {
    constructor(
        epHelpers: EarlyPayoutHelpers,
        loggerFactory: LoggerFactory,
        msUser: UserService,
        @Inject(earlyPayoutServiceMyBetsToken) earlyPayoutService: EarlyPayoutService,
        @Inject(earlyPayoutSubscriptionServiceMyBetsToken) earlyPayoutSubscriptionService: EarlyPayoutSubscriptionService,
        @Inject(earlyPayoutSubmitServiceMyBetsToken) earlyPayoutSubmitService: EarlyPayoutSubmitService,
        epsLogger: EPSDetailedLogger,
        earlyPayoutSubscriptionMapperService: EarlyPayoutSubscriptionMapperService,
        earlyPayoutUtils: EarlyPayoutUtils,
    ) {
        super(
            epHelpers,
            loggerFactory,
            msUser,
            earlyPayoutService,
            earlyPayoutSubscriptionService,
            earlyPayoutSubmitService,
            epsLogger,
            earlyPayoutSubscriptionMapperService,
            earlyPayoutUtils,
        );
    }
}

@Injectable({ providedIn: 'root' })
export class MyBetsEarlyPayoutServiceEditBetFactory extends MyBetsEarlyPayoutServiceFactory {
    constructor(
        epConfig: EarlyPayoutConfig,
        epHelpers: EarlyPayoutHelpers,
        loggerFactory: LoggerFactory,
        msUser: UserService,
        @Inject(earlyPayoutServiceEditBetToken) earlyPayoutService: EarlyPayoutService,
        @Inject(earlyPayoutSubscriptionServiceEditBetToken) earlyPayoutSubscriptionService: EarlyPayoutSubscriptionService,
        @Inject(earlyPayoutSubmitServiceEditBetToken) earlyPayoutSubmitService: EarlyPayoutSubmitService,
        epsLogger: EPSDetailedLogger,
        earlyPayoutSubscriptionMapperService: EarlyPayoutSubscriptionMapperService,
        earlyPayoutUtils: EarlyPayoutUtils,
    ) {
        super(
            epHelpers,
            loggerFactory,
            msUser,
            earlyPayoutService,
            earlyPayoutSubscriptionService,
            earlyPayoutSubmitService,
            epsLogger,
            earlyPayoutSubscriptionMapperService,
            earlyPayoutUtils,
        );
    }
}
