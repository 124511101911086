import { pprops } from '@frontend/sports/common/base-utils';
import { createAction, props } from '@ngrx/store';

import { IPlacedBetInfo, IShareMyBetState } from './share-my-bet.state';

export class ShareMyBetActions {
    private static readonly INIT_GENERATE_SHORT_URI = '@share-my-bet/INIT_GENERATE_SHORT_URI';
    private static readonly SHARE = '@share-my-bet/SHARE';
    private static readonly GENERATE_SHORT_URI = '@share-my-bet/GENERATE_SHORT_URI';
    private static readonly OPEN_COPY_LINK_DIALOG = '@share-my-bet/OPEN_COPY_LINK_DIALOG';
    private static readonly CLOSE_COPY_LINK_DIALOG = '@share-my-bet/CLOSE_COPY_LINK_DIALOG';
    private static readonly DESTROY_STATE = '@share-my-bet/DESTROY_STATE';
    private static readonly COPY_SHORT_URI = '@share-my-bet/COPY_SHORT_URI';
    private static readonly REMOVE_COPIED = '@share-my-bet/REMOVE_COPIED';
    private static readonly RECEIVE_BET = '@share-my-bet/RECEIVE_BET';
    private static readonly REMOVE_RECEIVED_BET_MESSAGE = '@share-my-bet/REMOVE_RECEIVED_BET_MESSAGE';
    private static readonly POPULATE_FROM_STORAGE = '@share-my-bet/POPULATE_FROM_STORAGE';

    static initGenerateShortUri = createAction(ShareMyBetActions.INIT_GENERATE_SHORT_URI, pprops<IPlacedBetInfo>());
    static generateShortUri = createAction(ShareMyBetActions.GENERATE_SHORT_URI, pprops<IPlacedBetInfo>());
    static share = createAction(ShareMyBetActions.SHARE, pprops<{ uri: string; betInfo: IPlacedBetInfo }>());
    static openCopyLinkDialog = createAction(ShareMyBetActions.OPEN_COPY_LINK_DIALOG, props<{ betslipId: string }>());
    static closeCopyLinkDialog = createAction(ShareMyBetActions.CLOSE_COPY_LINK_DIALOG, props<{ betslipId: string }>());
    static copyShortUri = createAction(ShareMyBetActions.COPY_SHORT_URI, pprops<{ betslipId: string; uri: string }>());
    static removeCopied = createAction(ShareMyBetActions.REMOVE_COPIED, props<{ betslipId: string }>());
    static destroyState = createAction(ShareMyBetActions.DESTROY_STATE, props<{ betslipId: string }>());
    static receiveBet = createAction(ShareMyBetActions.RECEIVE_BET, props<{ pickCount: number }>());
    static removeReceiveBetMessage = createAction(ShareMyBetActions.REMOVE_RECEIVED_BET_MESSAGE);
    static populateFromStorage = createAction(ShareMyBetActions.POPULATE_FROM_STORAGE, pprops<IShareMyBetState>());
}
