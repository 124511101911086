import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges } from '@angular/core';

import { HooksWireup, OnDestroyCleanup, PropType } from '@frontend/sports/common/base-utils';
import { ARCConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { LocalStoreService } from '@frontend/vanilla/core';
import { isEmpty } from 'lodash-es';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NOT_APPLICABLE, trackingConstants } from '../tracking/tracking.models';
import { ArcMessageFrequencyService } from './arc-message-frequency.service';
import { ArcRestrictionTrackingService } from './arc-restriction-tracking.service';
import { ArcRestrictionService } from './arc-restriction.service';
import {
    ArcRestrictionTrackingConstants,
    FeatureLocation,
    IARCProfileConfiguration,
    ProfileActionName,
    ProfileMessageStorageKeys,
    TrackingEvent,
} from './arc.model';

@HooksWireup()
@Component({
    selector: 'ms-arc-message',
    templateUrl: './arc-message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArcMessageComponent extends OnDestroyCleanup implements OnChanges {
    @Input() profileData: IARCProfileConfiguration;
    @Input() featureLocation: FeatureLocation;
    @HostBinding('class') className = 'playbreak';
    FeatureLocation = FeatureLocation;

    expanded = true;
    isMessageFrequency: boolean;
    isUserOnPlayBreak$: Observable<boolean>;
    frequency: number;
    arcBetslipMessages: PropType<PropType<Sitecore, 'betslip'>, 'arcBetslipMessages'>;
    betslipMessage: string;

    constructor(
        public sitecore: Sitecore,
        private arcRestrictionService: ArcRestrictionService,
        private localStorage: LocalStoreService,
        private arcRestrictionTrackingService: ArcRestrictionTrackingService,
        private arcConfig: ARCConfig,
        private arcMessageFrequencyService: ArcMessageFrequencyService,
    ) {
        super();
        this.arcBetslipMessages = sitecore.betslip.arcBetslipMessages;
        this.isUserOnPlayBreak$ = this.arcRestrictionService.isUserOnPlayBreak$;
        this.arcRestrictionService.isUserOnPlayBreak$.pipe(takeUntil(this.destroyed$)).subscribe((isBreak) => {
            if (isBreak) {
                this.trackMessage(NOT_APPLICABLE, undefined, isBreak);
            }
        });
    }

    ngOnChanges(): void {
        if (!isEmpty(this.profileData)) {
            this.handleBetSlipMessageAndFrequency();
        }
    }

    private handleBetSlipMessageAndFrequency(): void {
        const position = this.expanded ? TrackingEvent.Expand : TrackingEvent.Collapse;
        if (
            (!(this.featureLocation === FeatureLocation.PreBetSlipMessage) && !(this.featureLocation === FeatureLocation.PostBetSlipMessage)) ||
            !this.arcConfig.profileActionsSwitch.isBetslipMessagingEnabled
        ) {
            return;
        }
        this.getBetSlipMessage(this.profileData.profileId);
        const profileFrequency =
            this.profileData.profileActions.find((action) =>
                this.featureLocation === FeatureLocation.PreBetSlipMessage
                    ? action.name === ProfileActionName.PreBetSlipMessage
                    : action.name === ProfileActionName.PostBetSlipMessage,
            )?.frequency ?? 0;
        if (this.featureLocation === FeatureLocation.PreBetSlipMessage) {
            this.isMessageFrequency = this.arcMessageFrequencyService.isMessageFrequencyAvailable(
                ProfileMessageStorageKeys.preBetMessageStorageKey,
                profileFrequency,
                ProfileMessageStorageKeys.actionForMessagedisplayPre,
                ProfileMessageStorageKeys.firstLoginActionPrebet,
            );

            if (this.isMessageFrequency) {
                this.setLocalStorage(
                    ProfileMessageStorageKeys.preBetMessageStorageKey,
                    ProfileMessageStorageKeys.actionForMessagedisplayPre,
                    ProfileMessageStorageKeys.firstLoginActionPrebet,
                );
                this.trackMessage(position, TrackingEvent.Load);
            }

            return;
        }
        this.isMessageFrequency = this.arcMessageFrequencyService.isMessageFrequencyAvailable(
            ProfileMessageStorageKeys.postBetMessageStorageKey,
            profileFrequency,
            ProfileMessageStorageKeys.actionForMessagedisplayPost,
            ProfileMessageStorageKeys.firstLoginActionPostbet,
        );
        if (this.isMessageFrequency) {
            this.setLocalStorage(
                ProfileMessageStorageKeys.postBetMessageStorageKey,
                ProfileMessageStorageKeys.actionForMessagedisplayPost,
                ProfileMessageStorageKeys.firstLoginActionPostbet,
            );
            this.trackMessage(position, TrackingEvent.Load);
        }
    }

    private getBetSlipMessage(profileId: string): void {
        const msgKey = Object.keys(this.arcBetslipMessages).find((key) =>
            this.featureLocation === FeatureLocation.PreBetSlipMessage
                ? key === `${ProfileActionName.PreBetSlipMessage}_${profileId}`
                : key === `${ProfileActionName.PostBetSlipMessage}_${profileId}`,
        );
        this.betslipMessage = msgKey ? this.arcBetslipMessages[msgKey] : this.betslipMessage;
    }

    private setLocalStorage(key: string, loginActionKey: string, loginActionValue: string): void {
        this.localStorage.set(key, new Date());
        this.localStorage.set(loginActionKey, loginActionValue);
    }

    toggle(): void {
        this.expanded = !this.expanded;
        const action = this.expanded ? TrackingEvent.Expand : TrackingEvent.Collapse;
        const position = action === TrackingEvent.Expand ? TrackingEvent.Collapse : TrackingEvent.Expand;
        this.trackMessage(position, action);
    }

    private trackMessage(position: string, action?: string, isPlayBreak?: boolean): void {
        const trackingData = {
            action,
            position,
            source:
                this.featureLocation === FeatureLocation.PreBetSlipMessage || isPlayBreak
                    ? ArcRestrictionTrackingConstants.SourceBetslip
                    : ArcRestrictionTrackingConstants.SourceBetslipReceipt,
        };
        if (isPlayBreak) {
            trackingData.source =
                this.featureLocation === FeatureLocation.QuickBet ? ArcRestrictionTrackingConstants.SourceBetslipQuickBet : trackingData.source;
            this.arcRestrictionTrackingService.trackPlayBreakMessage(trackingData);
        } else {
            const event = action === TrackingEvent.Load ? trackingConstants.EVENT_CONTENT_VIEW : trackingConstants.EVENT_TRACKING;
            this.arcRestrictionTrackingService.trackSaferGambalingMessage(event, trackingData);
        }
    }

    trackHelpLink(): void {
        const trackingData = {
            action: TrackingEvent.Click,
            position: ArcRestrictionTrackingConstants.FindOutMoreLinkPositionEvent,
            source: ArcRestrictionTrackingConstants.SourceBetslipReceipt,
        };
        this.arcRestrictionTrackingService.trackFindOutMoreLink(trackingData);
    }
}
