import { NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';

import { DsButton } from '@frontend/ui/button';

import { EpcotConfigService } from '../../common/epcot-config.service';
import { TrackingType, trackingConstants } from '../../tracking/tracking.models';
import { TrackingService } from '../../tracking/tracking.service';
import { ActiveModal } from '../common/modal-ref';
import { IConfirmationDialogButton, IConfirmationDialogComponentModel } from './confirmation-dialog.model';

@Component({
    selector: 'ms-confirmation-dialog',
    templateUrl: 'confirmation-dialog.html',
    standalone: true,
    imports: [NgIf, NgClass, NgFor, DsButton],
})
export class ConfirmationDialogComponent implements AfterViewInit {
    contentModel: IConfirmationDialogComponentModel;
    isEpcotEnabled: boolean;

    //TODO Buttons Design System: remove once all buttons have been migrated
    get isDesignSystem(): boolean {
        return this.contentModel.buttons.every((b) => b.buttonConfig);
    }

    constructor(
        private activeModal: ActiveModal,
        private trackingService: TrackingService,
        epcotConfigService: EpcotConfigService,
    ) {
        this.isEpcotEnabled = epcotConfigService.isEnabled();
    }

    ngAfterViewInit(): void {
        if (this.contentModel.tracking) {
            switch (this.contentModel.tracking.type) {
                case TrackingType.PageName:
                    this.trackingService.track(this.contentModel.tracking.name, this.contentModel.tracking.data || {});
                    break;
                case TrackingType.Referring:
                    this.trackingService.update({ [trackingConstants.PAGE_REFERRING_ACTION]: this.contentModel.tracking.name });
                    break;
            }
        }
    }

    handleClick(button: IConfirmationDialogButton): void {
        this.activeModal.close(button.buttonType);
    }
}
