import { QuickLinkViewModel } from '@frontend/sports/types/components/quick-links';

import { ListItem } from '../common/item/item.model';

export interface QuickLinkItem extends ListItem {
    linkType?: string;
    sportId?: number;
    leagueId?: number;
    popup?: string;
    leagueTitle?: string;
    leagueIcon?: string;
}

export interface Positionable<T> {
    top: T;
    bottom: T;
}

export interface QuickLinkList {
    title: string | null;
    links: QuickLinkItem[];
    maxItems: number;
    mobileLayout: string;
    desktopLayout: string;
}

export interface SectionLinks {
    pickedLinks: QuickLinkViewModel[];
    visibleOnLobby: boolean;
    sectionTitle: string;
    location: string;
    maxItems: number;
    showTitleOnMobileResolutionsXSandS?: boolean;
    desktopLayout: string;
    mobileLayout: string;
    showTitleOnDesktopResolutions: boolean;
    setTilesCarouselIconsAsSmall: boolean;
}

export enum QuickLinksSections {
    BetConfirmation = 'BetConfirmation',
    EmptyBetslip = 'EmptyBetslip',
}

export enum LinkType {
    HomeLobby = '1',
    SportLobby = '2',
    Both = '3',
}

export interface QuickLinksView {
    showQuicklinksTitle: boolean;
    showQuicklinksinDesktop: boolean;
}

export function toQuickLinkItem(quickLink: QuickLinkViewModel): QuickLinkItem {
    return {
        icon: quickLink.colouredIcon ? '' : quickLink.icon,
        href: quickLink.linkModel?.href,
        popup: quickLink.linkModel?.popup,
        title: quickLink.linkModel?.link ?? '',
        linkType: quickLink.linkType,
        sportId: quickLink.sportId,
        leagueId: quickLink.leagueId,
        attributes: quickLink.attributes || {},
        bgColor: quickLink.backgroundColor,
        colouredIcon: quickLink.colouredIcon,
        sitecoreId: quickLink.sitecoreId,
        userSegmentGroups: quickLink.userSegmentGroups,
        leagueTitle: quickLink.leagueTitle,
        leagueIcon: quickLink.leagueIcon,
    };
}
