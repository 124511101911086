import { Injectable } from '@angular/core';

import { interpolateSingleBracesString } from '@frontend/sports/common/base-utils';
import {
    BetslipConfig,
    EarlyPayoutConfig,
    MyBetsConfig,
    RacingConfig,
    ReBetConfig,
    ShareMyBetConfig,
    Sitecore,
    TeaserBetConfig,
} from '@frontend/sports/common/client-config-data-access';

import { ReBetMyBetsTabs } from '../common/re-bet-my-bets-tabs.models';
import { UserService } from '../user/services/user.service';
import { HELP_BOX_TYPE, HelpBoxType } from './help-box-type';

export interface HelpBoxModel {
    headerTitle: string;
    infoIconLink: string;
    explanationText: string;
}

@Injectable({ providedIn: 'root' })
export class HelpBoxService {
    constructor(
        private sitecore: Sitecore,
        private racingConfig: RacingConfig,
        private epConfig: EarlyPayoutConfig,
        private userService: UserService,
        protected mybetsConfig: MyBetsConfig,
        protected betslipConfig: BetslipConfig,
        protected shareMyBetConfig: ShareMyBetConfig,
        protected teaserBetConfig: TeaserBetConfig,
        protected rebetConfig: ReBetConfig,
        private earlyPayoutConfig: EarlyPayoutConfig,
    ) {}

    getHelpBoxData(type: HelpBoxType): HelpBoxModel {
        switch (type) {
            case HELP_BOX_TYPE.BestOddsGuarantee: {
                return this.getBOGHelpBoxData();
            }
            case HELP_BOX_TYPE.CashOutInfo: {
                return this.getCashOutInfoData();
            }
            case HELP_BOX_TYPE.AutoCashout: {
                return this.getAutoCashoutHelpBoxData();
            }
            case HELP_BOX_TYPE.LineSwitcher: {
                return this.getLineSwitcherHelpData();
            }
            case HELP_BOX_TYPE.ArchivedBets: {
                return this.getArchivedBetsHelpData();
            }
            case HELP_BOX_TYPE.ShareMyBet: {
                return this.getShareMyBetHelpData();
            }
            case HELP_BOX_TYPE.TransactionBets: {
                return this.getTransactionBetsHelpData();
            }
            case HELP_BOX_TYPE.TeaserBet: {
                return this.getTeaserBetHelpData();
            }

            case HELP_BOX_TYPE.ReBet: {
                return this.getReBetHelpBoxData();
            }

            case HELP_BOX_TYPE.ReBetForOpenTab: {
                return this.getReBetOpenTabHelpBoxData();
            }
        }

        return <HelpBoxModel>{};
    }

    getHelpBoxBadgeData(type?: HelpBoxType): string {
        switch (type) {
            case HELP_BOX_TYPE.BestOddsGuarantee: {
                return this.sitecore.horseRacing.badges.BOG;
            }
            case HELP_BOX_TYPE.AutoCashout: {
                return this.sitecore.earlyPayoutV2.SetAutocashoutHelpBoxBadgeText;
            }
            case HELP_BOX_TYPE.PaidOutAutoCashout: {
                return this.sitecore.earlyPayoutV2.PaidOutAutoCashoutBadgeText;
            }
            case HELP_BOX_TYPE.LineSwitcher: {
                return this.sitecore.betslip.lineSwitcherHelpBox.Badge;
            }
            case HELP_BOX_TYPE.ArchivedBets: {
                return this.sitecore.betslip.archivedBetsHelpBox.Badge;
            }
            case HELP_BOX_TYPE.ShareMyBet: {
                return this.sitecore.globalMessages.NewBadgeText;
            }
            case HELP_BOX_TYPE.TransactionBets: {
                return this.sitecore.betslip.transactionBetsHelpBox.Badge;
            }
            case HELP_BOX_TYPE.TeaserBet: {
                return this.sitecore.betslip.teaserBet.helpBox.Badge;
            }
            case HELP_BOX_TYPE.ReBet: {
                return this.sitecore.reBet.helpBox.BadgeForSettledTab;
            }
            case HELP_BOX_TYPE.ReBetForOpenTab: {
                return this.sitecore.reBet.helpBox.BadgeForOpenTab;
            }
        }

        return '';
    }

    isHelpBoxEnabled(type?: HelpBoxType): boolean {
        switch (type) {
            case HELP_BOX_TYPE.BestOddsGuarantee: {
                return this.racingConfig.isBOGEnabled;
            }
            case HELP_BOX_TYPE.CashOutInfo: {
                return this.earlyPayoutConfig.showCashOutInfo;
            }
            case HELP_BOX_TYPE.AutoCashout: {
                return (
                    this.epConfig.isAutoCashOutEnabled &&
                    this.epConfig.isAutoCashOutHelpEnabled &&
                    this.userService.isEarlyPayoutAllowed &&
                    !this.userService.bettingSettings.earlyPayoutDeactivated
                );
            }
            case HELP_BOX_TYPE.PaidOutAutoCashout: {
                return true;
            }
            case HELP_BOX_TYPE.LineSwitcher: {
                return this.betslipConfig.isLineSwitcherEnabled;
            }
            case HELP_BOX_TYPE.ArchivedBets: {
                return this.mybetsConfig.isArchivedBetsEnabled;
            }
            case HELP_BOX_TYPE.ShareMyBet: {
                return this.shareMyBetConfig.isEnabled && this.shareMyBetConfig.isHelpBoxEnabled;
            }
            case HELP_BOX_TYPE.TransactionBets: {
                return this.mybetsConfig.isTransactionBetsEnabled;
            }
            case HELP_BOX_TYPE.TeaserBet: {
                return (
                    this.teaserBetConfig.isEnabled &&
                    this.sitecore.betslip.teaserBet.helpBox.HeaderLine.length > 0 &&
                    this.sitecore.betslip.teaserBet.helpBox.Text.length > 0 &&
                    this.sitecore.betslip.teaserBet.helpBox.Badge.length > 0
                );
            }
            case HELP_BOX_TYPE.ReBet: {
                return (
                    this.rebetConfig.helpBoxEnabledTabs.includes(ReBetMyBetsTabs.Settled.toUpperCase()) &&
                    this.rebetConfig.enabledTabs.includes(ReBetMyBetsTabs.Settled.toUpperCase())
                );
            }
            case HELP_BOX_TYPE.ReBetForOpenTab: {
                return (
                    this.rebetConfig.helpBoxEnabledTabs.includes(ReBetMyBetsTabs.Open.toUpperCase()) &&
                    this.rebetConfig.enabledTabs.includes(ReBetMyBetsTabs.Open.toUpperCase())
                );
            }
        }

        return false;
    }

    private getReBetHelpBoxData(): HelpBoxModel {
        return <HelpBoxModel>{
            headerTitle: this.sitecore.reBet.helpBox.HeaderLineForSettledTab,
            infoIconLink: this.sitecore.reBet.helpBox.InfoIconLinkForSettledTab,
            explanationText: this.sitecore.reBet.helpBox.TextForSettledTab,
        };
    }

    private getReBetOpenTabHelpBoxData(): HelpBoxModel {
        return <HelpBoxModel>{
            headerTitle: this.sitecore.reBet.helpBox.HeaderLineForOpenTab,
            infoIconLink: this.sitecore.reBet.helpBox.InfoIconLinkForOpenTab,
            explanationText: this.sitecore.reBet.helpBox.TextForOpenTab,
        };
    }

    private getBOGHelpBoxData(): HelpBoxModel {
        return <HelpBoxModel>{
            headerTitle: this.sitecore.horseRacing.helpBox.HeaderLine,
            infoIconLink: this.sitecore.horseRacing.helpBox.InfoIconLink,
            explanationText: this.sitecore.horseRacing.helpBox.Text,
        };
    }

    private getCashOutInfoData(): HelpBoxModel {
        return <HelpBoxModel>{
            headerTitle: this.sitecore.myBets.cashoutInfoHelpBox.HeaderLine,
            infoIconLink: this.sitecore.myBets.cashoutInfoHelpBox.InfoIconLink,
            explanationText: this.sitecore.myBets.cashoutInfoHelpBox.Text,
        };
    }

    private getAutoCashoutHelpBoxData(): HelpBoxModel {
        return <HelpBoxModel>{
            headerTitle: this.sitecore.earlyPayoutV2.SetAutocashoutHelpBoxHeaderLine,
            infoIconLink: this.sitecore.earlyPayoutV2.SetAutocashoutHelpBoxInfoIconLink,
            explanationText: this.sitecore.earlyPayoutV2.SetAutocashoutHelpBoxText,
        };
    }

    private getLineSwitcherHelpData(): HelpBoxModel {
        return <HelpBoxModel>{
            headerTitle: this.sitecore.betslip.lineSwitcherHelpBox.HeaderLine,
            explanationText: this.sitecore.betslip.lineSwitcherHelpBox.Text,
        };
    }

    private getArchivedBetsHelpData(): HelpBoxModel {
        return <HelpBoxModel>{
            headerTitle: this.sitecore.betslip.archivedBetsHelpBox.HeaderLine,
            explanationText: interpolateSingleBracesString(this.sitecore.betslip.archivedBetsHelpBox.Text, {
                archivedMyBetsUrl: this.mybetsConfig.archivedMyBetsUrl ?? '',
                sessionKey: this.userService.ssoToken as string,
            }),
        };
    }

    private getShareMyBetHelpData(): HelpBoxModel {
        return <HelpBoxModel>{
            headerTitle: this.sitecore.shareMyBet.helpBox.Title,
            explanationText: this.sitecore.shareMyBet.helpBox.Text,
        };
    }

    private getTransactionBetsHelpData(): HelpBoxModel {
        return <HelpBoxModel>{
            headerTitle: this.sitecore.betslip.transactionBetsHelpBox.HeaderLine,
            explanationText: interpolateSingleBracesString(this.sitecore.betslip.transactionBetsHelpBox.Text, {
                transactionMyBetsUrl: this.mybetsConfig.stadiumMyBetsTransactionsUrl ?? '',
                sessionKey: this.userService.ssoToken as string,
            }),
        };
    }

    private getTeaserBetHelpData(): HelpBoxModel {
        return <HelpBoxModel>{
            headerTitle: this.sitecore.betslip.teaserBet.helpBox.HeaderLine,
            explanationText: this.sitecore.betslip.teaserBet.helpBox.Text,
        };
    }
}
