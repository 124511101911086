import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TrustPipe } from '../common/trust.pipe';
import { ArcMessageFrequencyService } from './arc-message-frequency.service';
import { ArcMessageComponent } from './arc-message.component';
import { ArcRestrictionTrackingService } from './arc-restriction-tracking.service';
import { ArcRestrictionService } from './arc-restriction.service';

@NgModule({
    imports: [CommonModule, TrustPipe],
    declarations: [ArcMessageComponent],
    exports: [ArcMessageComponent],
    providers: [ArcRestrictionService, ArcRestrictionTrackingService, ArcMessageFrequencyService],
})
export class ArcModule {}
