import { Fixture, OptionMarket, Participant } from '@cds/betting-offer';
import { RaceEntity } from '@cds/betting-offer/domain-specific/horse-racing';
import {
    RacingPostFixtureData,
    GreyhoundParticipantData as RacingPostGreyhoundParticipantData,
    HorseParticipantData as RacingPostHorseParticipantData,
} from '@cds/betting-offer/domain-specific/horse-racing/racing-post';
import { HorseParticipantData, TimeformFixtureData } from '@cds/betting-offer/domain-specific/horse-racing/timeform';
import { Omit } from '@frontend/sports/common/base-utils';
import { RacingPostData, WinGameParticipant } from '@frontend/sports/common/racing-lib';

import { EventOptionGroup, EventOptionSet } from '../../event-model/model/event.model';

export enum NextRaceFilters {
    All = 'all filter',
    Ukireland = 'UK & Ireland filter',
}

type ExcludeKeys = 'tournament' | 'scoreboard' | 'games' | 'groupId' | 'liveType' | 'liveAlert' | 'source';

export type OptionMarketsFixture = Omit<Fixture, 'participants' | 'games'>;

export type ParticipantsFixture = Omit<Fixture, 'optionMarkets' | 'games'>;

export type GamesFixture = Omit<Fixture, 'optionMarkets' | 'participants'>;

export interface WinGameFixture extends Omit<Fixture, ExcludeKeys> {
    fixtureId: string;
    participants: WinGameParticipant[];
    optionGroups: EventOptionGroup[];
    optionSets: EventOptionSet[];
    nextRaceFilter?: NextRaceFilters;
    isOptionBet: boolean;
    timeform?: TimeformFixtureData;
    racingpost?: RacingPostFixtureData;
}

export interface ParticipantFixture<TParticipant extends WinGameParticipant> extends WinGameFixture {
    participants: TParticipant[];
}

export type WinGameFixturePreview = Omit<WinGameFixture, 'participants' | 'optionMarkets'>;

function getFixtureId(compoundFixtureId: string): string {
    return compoundFixtureId.split(':').pop() as string;
}

function mapParticipant<T extends WinGameParticipant>(
    input: Participant,
    participantExtraProps: (participant: Participant) => Partial<T> = () => ({}) as Partial<T>,
): T {
    return {
        ...input,
        id: input.id!,
        ...participantExtraProps(input),
    } as T;
}

export function createWinGameParticipant(input: Participant): WinGameParticipant {
    return mapParticipant<WinGameParticipant>(input);
}

export function createParticipantWithTimeFormData(input: Participant, timeform?: TimeformFixtureData): WinGameParticipant {
    return mapParticipant<WinGameParticipant>(input, (p) => ({
        timeformInfo: (timeform && timeform.horses[p.id!]) || ({} as HorseParticipantData),
    }));
}

export function createParticipantWithRacingPostData(input: Participant, racingPost?: RacingPostFixtureData): WinGameParticipant {
    return mapParticipant<WinGameParticipant>(input, (p) => ({
        racingPostInfo: {
            horseData: (racingPost?.horses && racingPost.horses[p.id!]) || ({} as RacingPostHorseParticipantData),
            greyhoundsData: (racingPost?.greyhounds && racingPost.greyhounds[p.id!]) || ({} as RacingPostGreyhoundParticipantData),
        } as RacingPostData,
    }));
}

export function toWinGameFixture(fixture: Fixture): WinGameFixture {
    return {
        ...fixture,
        fixtureId: getFixtureId(fixture.id),
        participants: (fixture.participants || []).map((p) => createWinGameParticipant(p)),
        optionGroups: [],
        optionSets: [],
        isOptionBet: hasOptionMarkets(fixture.optionMarkets),
    };
}

export function toHorseRacingTimeFormFixture(horseRacingEntity: RaceEntity): WinGameFixture {
    const timeform: TimeformFixtureData = horseRacingEntity.timeform || ({ horses: {} } as TimeformFixtureData);
    const fixture = horseRacingEntity.fixture;

    return {
        ...fixture,
        fixtureId: getFixtureId(fixture.id),
        participants: (fixture.participants || []).map((_: any) => createParticipantWithTimeFormData(_, timeform)),
        timeform,
        optionGroups: [],
        optionSets: [],
        isOptionBet: hasOptionMarkets(fixture.optionMarkets),
    };
}

export function toRacingPostFixture(horseRacingEntity: RaceEntity): WinGameFixture {
    const racingPost: RacingPostFixtureData = horseRacingEntity.racingPost || ({ horses: {}, greyhounds: {} } as RacingPostFixtureData);
    const fixture = horseRacingEntity.fixture;

    return {
        ...fixture,
        fixtureId: getFixtureId(fixture.id),
        participants: (fixture.participants || []).map((_) => createParticipantWithRacingPostData(_, racingPost)),
        racingpost: racingPost,
        optionGroups: [],
        optionSets: [],
        isOptionBet: hasOptionMarkets(fixture.optionMarkets),
    };
}

export function toRacingFixturePreview(fixture: Fixture): WinGameFixturePreview {
    return toWinGameFixture(fixture);
}

function hasOptionMarkets(optionMarkets: OptionMarket[]): boolean {
    return !!optionMarkets?.length;
}
