import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { WindowRef } from '@frontend/vanilla/core';

import { LoggerFactory } from '../../logging/logger-factory.service';
import { SportsRemoteLogger } from '../../logging/sports-remote-logger.service';

@Injectable({ providedIn: 'root' })
export class ClipboardService {
    private tempTextArea: HTMLTextAreaElement | undefined;
    private nativeWindow: Window;
    private logger: SportsRemoteLogger;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        window: WindowRef,
        loggerFactory: LoggerFactory,
    ) {
        this.nativeWindow = window.nativeWindow;
        this.logger = loggerFactory.getLogger('ClipboardService');
    }

    get isSupported(): boolean {
        return !!(this.document.queryCommandSupported && this.document.queryCommandSupported('copy'));
    }

    /**
     * Creates a fake textarea element, sets its value from `text` property,
     * and makes a selection on it.
     */
    copyFromContent(content: string): boolean {
        if (!this.tempTextArea) {
            this.tempTextArea = this.createTempTextArea(this.document, this.nativeWindow);
            this.document.body.appendChild(this.tempTextArea);
        }
        this.tempTextArea.value = content;

        return this.copyFromInputElement(this.tempTextArea);
    }

    /**
     * copyFromInputElement
     */
    copyFromInputElement(targetElm: HTMLTextAreaElement): boolean {
        try {
            this.selectTarget(targetElm);
            const re = this.copyText();
            this.clearSelection(targetElm, this.nativeWindow);

            return re;
        } catch (error) {
            this.logger.error(error);

            return false;
        }
    }

    private copyText(): boolean {
        return this.document.execCommand('copy');
    }

    // Removes current selection and focus from `target` element.
    private clearSelection(inputElement: HTMLTextAreaElement, window: Window): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        inputElement && inputElement.blur();
        window.getSelection() && window.getSelection()!.removeAllRanges();
    }

    // select the target html input element
    private selectTarget(inputElement: HTMLTextAreaElement): number | undefined {
        if (this.nativeWindow.navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = inputElement.contentEditable;
            const readOnly = inputElement.readOnly;
            inputElement.contentEditable = 'true';
            inputElement.readOnly = true;
            const range = this.document.createRange();
            range.selectNodeContents(inputElement);
            const sel = this.nativeWindow.getSelection();
            if (sel) {
                sel.removeAllRanges();
                sel.addRange(range);
            }
            inputElement.setSelectionRange(0, 999999);
            inputElement.contentEditable = editable;
            inputElement.readOnly = readOnly;
        } else {
            inputElement.select();
        }

        return inputElement.value.length;
    }

    // create a fake textarea for copy command
    private createTempTextArea(doc: Document, window: Window): HTMLTextAreaElement {
        const isRTL = doc.documentElement.getAttribute('dir') === 'rtl';
        const ta = doc.createElement('textarea');
        // Prevent zooming on iOS
        ta.style.fontSize = '12pt';
        // Reset box model
        ta.style.border = '0';
        ta.style.padding = '0';
        ta.style.margin = '0';
        // Move element out of screen horizontally
        ta.style.position = 'absolute';
        ta.style[isRTL ? 'right' : 'left'] = '-9999px';
        // Move element to the same position vertically
        const yPosition = window.pageYOffset || doc.documentElement.scrollTop;
        ta.style.top = yPosition + 'px';
        ta.setAttribute('readonly', '');

        return ta;
    }

    // remove temporary textarea if any
    destroy(): void {
        if (this.tempTextArea) {
            this.document.body.removeChild(this.tempTextArea);
            this.tempTextArea = undefined;
        }
    }
}
