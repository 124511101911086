import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DsButton } from '@frontend/ui/button';

import { AlertButtonComponent } from './alert-button/alert-button.component';
import { AlertSelectorComponent } from './alert-selector/alert-selector.component';

@NgModule({
    imports: [CommonModule, DsButton],
    declarations: [AlertButtonComponent, AlertSelectorComponent],
    exports: [AlertButtonComponent, AlertSelectorComponent],
})
export class NativeAlertsModule {}
