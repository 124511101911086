import { Injectable, isDevMode } from '@angular/core';

import { Optional } from '@frontend/sports/common/base-utils';
import { GeolocationConfig } from '@frontend/sports/common/client-config-data-access';
import {
    CoordinatorStates,
    ICoordinatorProbeResult,
    IErrorData,
    IInitializationInput,
    IIntegrationApp,
    OnError,
    OnInitialized,
    OnProbed,
    OnUninitialized,
    PLCIdentifier,
} from '@geolocation/coordinator';
import { ReplaySubject } from 'rxjs';

import { UserService } from '../user/services/user.service';

@Injectable({ providedIn: 'root' })
export class GeolocationCoordinatorService implements IIntegrationApp {
    private geolocationCoordinator: IIntegrationApp;
    private _initializePending = false;
    private _uninitializePending = false;
    private _initializationInput: Optional<IInitializationInput>;
    private _unInitializedHooks: Partial<{
        onInitialized: OnInitialized<CoordinatorStates>;
        onUninitialized: OnUninitialized;
        onError: OnError;
        onProbed: OnProbed;
    }> = {};

    private readonly _ready$ = new ReplaySubject<void>(1);
    readonly ready$ = this._ready$.asObservable();

    constructor(
        private geolocationConfig: GeolocationConfig,
        private user: UserService,
    ) {
        this.hookupCoordinator();
        addEventListener(
            'geolocationcoordinator:bootstrapped',
            () => {
                this.hookupCoordinator();
            },
            {
                once: true,
            },
        );
    }

    get appDownloadUrl(): string {
        if (isDevMode() && !this.geolocationCoordinator) {
            throw new Error('geolocationCoordinator is not ready yet, use the ready$ observable to determine when this is the case');
        }

        return this.geolocationCoordinator?.appDownloadUrl ?? '';
    }

    set onInitialized(value: OnInitialized<CoordinatorStates>) {
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.onInitialized = value;
        } else {
            this._unInitializedHooks.onInitialized = value;
        }
    }

    set onUninitialized(value: OnUninitialized) {
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.onUninitialized = value;
        } else {
            this._unInitializedHooks.onUninitialized = value;
        }
    }

    set onError(value: OnError) {
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.onError = value;
        } else {
            this._unInitializedHooks.onError = value;
        }
    }

    set onProbed(value: OnProbed) {
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.onProbed = value;
        } else {
            this._unInitializedHooks.onProbed = value;
        }
    }

    initialize(input?: IInitializationInput): void {
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.initialize({
                session: (this.user && this.user.ssoToken) || 'NotAvailable',
                channelId: this.geolocationConfig.coordinatorSettings.channelId,
                plcIdentifier: this.geolocationConfig.coordinatorSettings.plcIdentifier as PLCIdentifier,
                showPLCPopup: input && input.showPLCPopup,
                productId: this.geolocationConfig.coordinatorSettings.productId,
            });
        } else {
            this._initializePending = true;
            this._initializationInput = input;
        }
    }

    uninitialize(): void {
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.uninitialize();
        } else {
            this._uninitializePending = true;
        }
    }

    probe(): void {
        if (isDevMode() && !this.geolocationCoordinator) {
            throw new Error('geolocationCoordinator is not ready yet, use the ready$ observable to determine when this is the case');
        }
        this.geolocationCoordinator?.probe?.();
    }

    triggerFetchLocation(): void {
        if (isDevMode() && !this.geolocationCoordinator) {
            throw new Error('geolocationCoordinator is not ready yet, use the ready$ observable to determine when this is the case');
        }
        this.geolocationCoordinator?.triggerFetchLocation?.();
    }

    private onGeolocationInitialized(initializationData: CoordinatorStates): void {
        if (this.onInitialized) {
            this.onInitialized(initializationData);
        }
    }

    private onGeolocationUninitialized(): void {
        if (this.onUninitialized) {
            this.onUninitialized();
        }
    }

    private onGeolocationError(errorData: IErrorData): void {
        if (this.onError) {
            this.onError(errorData);
        }
    }

    private onGeolocationProbed(probeResult: ICoordinatorProbeResult): void {
        if (this.onProbed) {
            this.onProbed(probeResult);
        }
    }

    private hookupCoordinator(): void {
        const geolocationCoordinator = (window as any).geolocationCoordinator as IIntegrationApp;
        if (geolocationCoordinator) {
            this.geolocationCoordinator = geolocationCoordinator;
            this.geolocationCoordinator.onInitialized = this._unInitializedHooks.onInitialized ?? this.onGeolocationInitialized.bind(this);
            this.geolocationCoordinator.onUninitialized = this._unInitializedHooks.onUninitialized ?? this.onGeolocationUninitialized.bind(this);
            this.geolocationCoordinator.onError = this._unInitializedHooks.onError ?? this.onGeolocationError.bind(this);
            this.geolocationCoordinator.onProbed = this._unInitializedHooks.onProbed ?? this.onGeolocationProbed.bind(this);

            if (this._initializePending) {
                this._initializePending = false;
                this.initialize(this._initializationInput);
                this._initializationInput = undefined;

                return;
            }
            if (this._uninitializePending) {
                this._uninitializePending = false;
                this.uninitialize();
            }
            this._ready$.next();
        }
    }
}
