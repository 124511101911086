import { Injectable } from '@angular/core';

import { LocalStoreService } from '@frontend/vanilla/core';

import { DateProviderService } from '../common/date-provider.service';

@Injectable()
export class ArcMessageFrequencyService {
    private lastMsgDisplayDate: Date = new Date();

    constructor(private localStorage: LocalStoreService) {}

    isMessageFrequencyAvailable(key: string, frequency: number, loginActionKey: string, loginActionValue: string): boolean {
        const messageKey = this.localStorage.get(key);
        if (!messageKey || this.localStorage.get(loginActionKey) === loginActionValue) {
            return true;
        }

        this.lastMsgDisplayDate = new Date(messageKey as Date);
        this.lastMsgDisplayDate.setDate(this.lastMsgDisplayDate.getDate() + frequency);

        if (DateProviderService.getStartOfDay(this.lastMsgDisplayDate) <= DateProviderService.getStartOfDay(new Date())) {
            return true;
        }

        return false;
    }
}
