<div>
    <div class="confirmation-dialog_message">
        @if (contentModel.icon) {
            <i [ngClass]="contentModel.icon"></i>
        }
        {{ contentModel.message }}
    </div>
    @if (contentModel.buttons.length && isDesignSystem) {
        <!-- TODO Buttons Design System: remove "ds" from the class once all buttons have been migrated -->
        <div class="confirmation-dialog_ds-buttons">
            @for (button of contentModel.buttons; track $index) {
                @if (button.buttonConfig) {
                    <button
                        ds-button
                        [kind]="button.buttonConfig.kind"
                        [variant]="button.buttonConfig.variant"
                        [size]="button.buttonConfig.size"
                        truncate="true"
                        class="confirmation-dialog_button"
                        [class]="{ 'single-button': contentModel.buttons?.length === 1, 'capitalize': isEpcotEnabled }"
                        (click)="handleClick(button)">
                        {{ button.buttonTitle }}
                    </button>
                }
            }
        </div>
    } @else {
        <div class="confirmation-dialog_buttons">
            @for (button of contentModel.buttons; track $index) {
                <button (click)="handleClick(button)" class="{{ button.cssClass }}">
                    {{ button.buttonTitle }}
                </button>
            }
        </div>
    }
</div>
