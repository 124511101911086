<ng-container *ngIf="!hidden">
    <div *ngIf="title" class="item-header col-12 quick-links">{{ title }}</div>
    <ms-top-bottom-sectionlinks
        [showArrows]="false"
        [items]="links"
        [tracking]="'quickLinks'"
        [itemClass]="'col-12'"
        [itemListClass]="'list row mb-0 shadow-none'"
        [moduleName]="title"
        [modulePosition]="0"
        [moduleSection]="'Left_'"
        [layout]="layout"
        [showSmallIcons]="smallIcon">
    </ms-top-bottom-sectionlinks>
</ng-container>
