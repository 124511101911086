export const HELP_BOX_TYPE = {
    BestOddsGuarantee: 'bestoddsguarantee',
    AutoCashout: 'autocashout',
    PaidOutAutoCashout: 'paidoutautocashout',
    LineSwitcher: 'lineswitcher',
    ArchivedBets: 'archivedBets',
    ShareMyBet: 'shareMyBet',
    TransactionBets: 'transactionBets',
    TeaserBet: 'teaserBet',
    ReBet: 'reBet',
    ReBetForOpenTab: 'reBetForOpenTab',
    CashOutInfo: 'cashoutinfo',
};

type HelpBoxTypeKey = keyof typeof HELP_BOX_TYPE;
export type HelpBoxType = (typeof HELP_BOX_TYPE)[HelpBoxTypeKey];
