import { Component } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { ShareMyBetService } from './share-my-bet.service';

@Component({
    selector: 'ms-share-my-bet-onboarding',
    templateUrl: './share-my-bet-onboarding.html',
})
export class ShareMyBetOnboardingComponent {
    constructor(
        public sitecore: Sitecore,
        private shareMyBetService: ShareMyBetService,
    ) {}

    hideOnboarding(event: Event): void {
        event.stopPropagation();

        this.shareMyBetService.setOnboardingShown();
    }
}
