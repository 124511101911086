import { Injectable } from '@angular/core';

import { AlertToastMessagesService } from '../alert-toast-messages.service';
import { Action, AlertEvent, Mode, Subscription } from '../model';

@Injectable({ providedIn: 'root' })
export class AlertSelectorService {
    mode: Mode;
    model: AlertEvent[];
    source: string;
    private subscribed: boolean;
    private toast?: Function;

    constructor(private toastMessageService: AlertToastMessagesService) {}

    showSelector(multi: boolean, source: string, selected: boolean): void {
        delete this.toast;
        this.mode = Mode[multi ? 'Multi' : 'Single'];
        this.source = source;
        this.subscribed = selected;
    }

    confirm(subscriptions?: Subscription[]): void {
        if (subscriptions) {
            const newsubscribed = subscriptions.filter((_) => _.action === Action.Subscribe).length;
            if (!this.subscribed && newsubscribed) {
                this.toast = this.toastMessageService.showSubscribedToast.bind(this.toastMessageService);
            }
            if (this.subscribed && !this.model.filter((_) => _.selected).length) {
                this.toast = this.toastMessageService.showUnsubscribedToast.bind(this.toastMessageService);
            }
        }

        this.toast?.();
    }
}
