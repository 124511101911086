<ng-container *ngIf="shareMyBetConfig.isEnabled">
    <ng-container *ngIf="shareMyBetState$ | async as shareMyBetState">
        <div
            id="share-my-bet"
            (click)="onShareButtonClick(shareMyBetState)"
            class="theme-share toolbar-button"
            [class.active]="!sharingState.isCopyLinkDialogOpen">
            <ms-share-my-bet-onboarding class="share-my-bet__onboarding" *ngIf="showOnboarding()"></ms-share-my-bet-onboarding>

            <div class="share-my-bet__button-title button-label">
                <span>{{ sitecore.shareMyBet.messages.Title }}</span>
                <span *ngIf="hasArrow(shareMyBetState)" class="theme-up" (click)="onToggleClick($event)"></span>
            </div>

            <span *ngIf="shareMyBetConfig.isNewBadgeEnabled" class="share-my-bet__new-badge">{{ sitecore.globalMessages.NewBadgeText }}</span>
        </div>
    </ng-container>
</ng-container>
