import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { AmericanFootballPeriodInfo, ISimpleChanges, SportConstant } from '@frontend/sports/common/base-utils';
import { SitecoreEventDetailsConfig } from '@frontend/sports/common/client-config-data-access';
import { ceil, padStart } from 'lodash-es';
import { Subscription, firstValueFrom } from 'rxjs';

import { DateProviderService } from '../common/date-provider.service';
import { TickerInterval, TickerService } from '../common/ticker.service';
import { EventScoreboard, ScoreboardType, TournamentScore } from '../event-model/model/scoreboard.model';

@Component({
    selector: 'ms-live-timer',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveTimerComponent implements OnDestroy, OnChanges, OnInit {
    @Input() scoreboard: EventScoreboard;
    @Input() timeValue?: string;
    @Input() eventId?: number;

    private gameTime: number; // seconds
    private serverTime: string;
    private tickerFormattedTime = '';
    private startTime = 0;

    private tickerSubscription: Subscription;
    private formatterFunc: () => string;

    constructor(
        private sitecoreEventDetails: SitecoreEventDetailsConfig,
        private ticker: TickerService,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit(): Promise<void> {
        await firstValueFrom(this.sitecoreEventDetails.whenReady);
    }

    ngOnChanges(changes: ISimpleChanges<LiveTimerComponent>): void {
        if (!changes.scoreboard) {
            return;
        }

        const scoreboardTypeChanged =
            changes.scoreboard.previousValue && changes.scoreboard.previousValue.type !== changes.scoreboard.currentValue.type;

        if (changes.scoreboard.isFirstChange() || scoreboardTypeChanged) {
            this.formatterFunc = this.getTimeFormatter();
        }

        this.restartTimer();
    }

    ngOnDestroy(): void {
        this.stopTicker();
    }

    private getTimeFormatter(): () => string {
        if (this.isType(ScoreboardType.TournamentGame)) {
            return this.getTournamentTime;
        }

        if (this.isType(ScoreboardType.PairGame, ScoreboardType.PeriodGame, ScoreboardType.SpecialGame, ScoreboardType.LeaderboardGame)) {
            return this.getPairGameTime;
        }

        if (this.isType(ScoreboardType.Darts, ScoreboardType.SetGame, ScoreboardType.BaseballGame, ScoreboardType.Cricket)) {
            return this.getOtherScoreboardTypesTime;
        }

        return () => '';
    }

    private restartTimer(): void {
        if (!this.scoreboard.started) {
            return;
        }

        this.gameTime = Number(this.scoreboard.gameTimeInSeconds);
        this.serverTime = this.scoreboard.timeIndicator || '';

        this.startTicker();
    }

    private startTicker(): void {
        this.stopTicker();

        if (this.scoreboard.timer && this.scoreboard.timer.base && this.scoreboard.timer.running) {
            this.gameTime = this.getTime();
            this.tickerFormattedTime = this.formatTime(this.gameTime);
            this.startCounter();
        }

        this.ticked();
    }

    private startCounter(): void {
        this.stopTicker();

        this.startTime = this.now();
        this.tickerSubscription = this.ticker.subscribe(() => this.ticked(), TickerInterval.Second);
    }

    private ticked(): void {
        if (this.scoreboard.timer) {
            this.updateTickerFormattedTime();
        }

        this.elementRef.nativeElement.textContent = this.formatterFunc();
    }

    private now(): number {
        return ceil(DateProviderService.now(), -3);
    }

    private updateTickerFormattedTime(): void {
        const currentTime = this.now();

        const elapsedTime = Math.floor((currentTime - this.startTime) / 1000);

        this.tickerFormattedTime = this.formatTime(this.gameTime + elapsedTime);
    }

    private getOtherScoreboardTypesTime = (): string => {
        return (this.scoreboard.started && this.scoreboard.period && this.scoreboard.period.name) || '';
    };

    private getTournamentTime = (): string => {
        if (!this.scoreboard.visible) {
            return '';
        }

        const score = this.scoreboard.getScore<TournamentScore>();
        if (!score || Object.keys(score).length === 0) {
            return '';
        }

        const type = this.sitecoreEventDetails.eventDetails[score.type] || '';

        let stateLabel = score.current ? ` ${score.current}` : '';
        stateLabel += score.final ? `/${score.final}` : '';

        if (type) {
            stateLabel = type + stateLabel;
        } else {
            stateLabel += score.type || '';
        }
        const isTimerVisible = this.scoreboard.timerVisible && !this.scoreboard.isSuspended;

        if (isTimerVisible && this.scoreboard.timerRunning && this.eventId !== SportConstant.Golf) {
            stateLabel += ` • ${this.tickerFormattedTime}`;
        }

        return stateLabel;
    };

    private getPairGameTime = (): string => {
        let period = this.scoreboard.period!.name;

        const isTimerVisible = this.scoreboard.timerVisible && !this.scoreboard.isSuspended;

        this.timeValue =
            this.timeValue !== undefined &&
            (this.scoreboard.period?.id === AmericanFootballPeriodInfo.IntermissionQ1 ||
                this.scoreboard.period?.id === AmericanFootballPeriodInfo.IntermissionQ2 ||
                this.scoreboard.period?.id === AmericanFootballPeriodInfo.IntermissionQ3 ||
                this.scoreboard.period?.id === AmericanFootballPeriodInfo.RegularTimeOver ||
                this.scoreboard.period?.id === AmericanFootballPeriodInfo.Finished)
                ? ''
                : this.timeValue;
        if (this.timeValue) {
            period += ' ' + this.timeValue;
        } else {
            if (isTimerVisible) {
                period += this.scoreboard.timerRunning ? ` • ${this.tickerFormattedTime}` : ` ${this.serverTime.replace(period, '')}`;
            }
        }

        return period;
    };

    private getTime(): number {
        if (!this.scoreboard.timer) {
            return Number(this.scoreboard.gameTimeInSeconds);
        }
        let timerSeconds = this.scoreboard.timer.seconds;
        if (timerSeconds <= 0) {
            timerSeconds = this.getTotalSeconds(this.scoreboard.timer.value);
        }

        const base = new Date(this.scoreboard.timer.base).getTime();
        const now = this.now();

        const time = timerSeconds + Math.round((now - base) / 1000);

        return time > 0 ? time : 0;
    }

    private getTotalSeconds(timer: string): number {
        const values = timer?.split(':');
        switch (values?.length) {
            case 1:
                return parseInt(values[0], 10);
            case 2:
                return parseInt(values[0], 10) * 60 + parseInt(values[1], 10);
            case 3:
                return parseInt(values[0], 10) * 3600 + parseInt(values[1], 10) * 60 + parseInt(values[2], 10);
        }

        return 0;
    }

    private formatTime(timeSpan: number): string {
        const minutes = Math.floor(timeSpan / 60);
        const seconds = timeSpan % 60;

        return `${this.pad(minutes)}:${this.pad(seconds)}`;
    }

    private pad(val: number): string {
        return padStart(val.toString(), 2, '0');
    }

    private isType(...types: ScoreboardType[]): boolean {
        return types.some((type) => this.scoreboard.type === type);
    }

    private stopTicker(): void {
        this.tickerSubscription?.unsubscribe();
    }
}
