import { InjectionToken, inject } from '@angular/core';

import { Page } from '@frontend/vanilla/core';

import { SportsTheme } from './themes';

export const CURRENT_THEME_TOKEN = new InjectionToken<SportsTheme>('CURRENT_THEME', {
    factory: () => inject(Page).theme as SportsTheme,
    providedIn: 'root',
});

export const injectCurrentTheme = () => inject(CURRENT_THEME_TOKEN);
