import { Injectable } from '@angular/core';

import { NativeAppService, NativeEvent } from '@frontend/vanilla/core';
import { Observable, filter } from 'rxjs';

import { NativeEventType } from './model';

@Injectable({ providedIn: 'root' })
export class NativeAlertsService {
    constructor(private nativeAppService: NativeAppService) {}

    subscribeToNotificationCheck(): Observable<NativeEvent> {
        this.nativeAppService.sendToNative({
            eventName: NativeEventType.IsNotificationEnabled,
        });

        return this.nativeAppService.eventsFromNative.pipe(filter((e) => e.eventName === NativeEventType.IsNotificationEnabled));
    }
}
