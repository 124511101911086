import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OptionQuery } from '../navigation-core/pick-uri.model';
import { IRootState } from '../store/root.state';

export const shareMyBetFeatureKey = 'shareMyBet';

export interface IShareMyBetRootState extends IRootState {
    shareMyBet: IShareMyBetState;
}

export const shareMyBetStateSelector = createFeatureSelector<IShareMyBetState>(shareMyBetFeatureKey);
export const receivingStateSelector = createSelector(shareMyBetStateSelector, (state: IShareMyBetState) => state.receivingState);
export const sharingStateSelector = (betslipId: string) =>
    createSelector(shareMyBetStateSelector, (state: IShareMyBetState) => state.sharingStates[betslipId]);

export interface IPlacedBetInfo {
    picksParams: OptionQuery[];
    betslipId: string;
    location: string;
    betType: string;
    pickCount: number;
    eventName?: string;
    systemType?: string;
}

export const defaultPlacedBetInfo: IPlacedBetInfo = {
    picksParams: [],
    betslipId: '',
    location: '',
    betType: '',
    pickCount: 0,
};

export interface ISharingState {
    uri: string;
    isCopyLinkDialogOpen: boolean;
    isCopied: boolean;
    betInfo: IPlacedBetInfo;
}

export const defaultSharingState: ISharingState = {
    uri: '',
    isCopyLinkDialogOpen: false,
    isCopied: false,
    betInfo: defaultPlacedBetInfo,
};

export interface IReceivingState {
    pickCount: number;
    showImportMessage: boolean;
}

export const defaultReceivingState: IReceivingState = {
    pickCount: 0,
    showImportMessage: false,
};

export interface IShareMyBetState {
    betslipId: string; // Initialized after bet placement
    sharingStates: { [key: string]: ISharingState };
    receivingState: IReceivingState;
}

export const defaultShareMyBetState: IShareMyBetState = {
    betslipId: '',
    sharingStates: {},
    receivingState: defaultReceivingState,
};
