import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { IntlService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { DateProviderService } from '../common/date-provider.service';
import { EpcotConfigService } from '../common/epcot-config.service';
import { TickerInterval, TickerService } from '../common/ticker.service';

@Component({
    selector: 'ms-prematch-timer',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrematchTimerComponent implements OnInit, OnDestroy, OnChanges {
    @Input() start: Date;
    @Input() isDynamicTimer = false;
    @Input() intervalForRedPrematchTimer = 10;
    @Input() calendarInfoDisplay = false;
    @HostBinding('class') get className(): string {
        const className = 'timer-badge starting-time';

        return this.epcotConfigService.isEnabled() ? `${className} pre-match-time` : className;
    }

    private readonly MINUTE = 60 * 1000;
    private readonly HOUR = 60 * this.MINUTE;
    private readonly DAY = 24 * this.HOUR;

    private tickerSubscription: Subscription;
    private formattedTime: string;
    private isEpcotEnabled: boolean;

    constructor(
        private intlService: IntlService,
        private sitecore: Sitecore,
        private ticker: TickerService,
        private elementRef: ElementRef,
        private epcotConfigService: EpcotConfigService,
    ) {}

    ngOnInit(): void {
        if (!this.start) {
            console.warn('No starting date defined');

            return;
        }

        this.tickerSubscription = this.ticker.subscribe(() => this.ticked(), TickerInterval.Second);
        this.ticked();
        this.isEpcotEnabled = this.epcotConfigService.isEnabled();
    }

    ngOnDestroy(): void {
        this.tickerSubscription.unsubscribe();
    }

    ngOnChanges(): void {
        this.ticked();
    }

    private ticked(): void {
        const now = DateProviderService.getDate();
        const diff = this.start.getTime() - now.getTime();

        const formatter = this.getFormat(now);

        let dateString = '';

        if (formatter.format) {
            dateString = this.isEpcotEnabled
                ? formatter.format.map((f) => this.intlService.formatDate(this.start, f)).join(' • ')
                : formatter.format.map((f) => this.intlService.formatDate(this.start, f)).join(' ');
        } else {
            dateString = Math.floor(Math.abs(diff / this.MINUTE)).toString();
        }

        const formattedTime = formatter.title ? formatter.title.replace('{date}', dateString) : '';

        if (this.formattedTime === formattedTime) {
            return;
        }

        this.formattedTime = formattedTime;

        if (formattedTime.indexOf('<') === -1) {
            this.elementRef.nativeElement.textContent = formattedTime;
        } else {
            this.elementRef.nativeElement.innerHTML = formattedTime;
        }
    }

    private getDisplayFormat(calendarFormat: string, timerFormat: string): string {
        return this.calendarInfoDisplay ? calendarFormat : timerFormat;
    }

    private getFormat(now: Date): { title: string; format?: string[] } {
        const diff = this.start.getTime() - now.getTime();
        const today = DateProviderService.buildDate(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        const tomorrow = DateProviderService.getDate(today.getTime() + this.DAY);
        let title = '';
        if (diff < this.MINUTE) {
            title = this.isDynamicTimer ? this.sitecore.globalMessages.StartsNowFullyFormatted : this.sitecore.globalMessages.StartsNowFormatted;

            return { title };
        }
        if (diff < (this.intervalForRedPrematchTimer + 1) * this.MINUTE) {
            title = this.isDynamicTimer
                ? this.getDisplayFormat(this.sitecore.globalMessages.CalendarTimerFormatted, this.sitecore.globalMessages.TimerFormatted)
                : this.getDisplayFormat(
                      this.sitecore.globalMessages.CalendarTimerStartingInFormatted,
                      this.sitecore.globalMessages.TimerStartingInFormatted,
                  );

            return { title };
        }

        if (diff < this.HOUR) {
            title = this.isDynamicTimer
                ? this.getDisplayFormat(this.sitecore.globalMessages.CalendarTimerStartingIn, this.sitecore.globalMessages.TimerStartingIn)
                : this.getDisplayFormat(
                      this.sitecore.globalMessages.CalendarTimerStartingInFormatted,
                      this.sitecore.globalMessages.TimerStartingInFormatted,
                  );

            return { title };
        }

        if (this.start < today) {
            return {
                title: this.getDisplayFormat(this.sitecore.globalMessages.CalendarTimerToday, this.sitecore.globalMessages.TimerToday),
                format: ['shortTime'],
            };
        }

        if (this.start < tomorrow) {
            return {
                title: this.getDisplayFormat(this.sitecore.globalMessages.TimerTomorrow.replace('/', ''), this.sitecore.globalMessages.TimerTomorrow),
                format: ['shortTime'],
            };
        }

        return { title: this.sitecore.globalMessages.TimerOther, format: ['shortDate', 'shortTime'] };
    }
}
