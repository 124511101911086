import { Injectable } from '@angular/core';

import { WidgetType } from '@frontend/sports/types/components/widget';
import { DynamicLayoutService, OnFeatureInit, SingleSlot, SlotType } from '@frontend/vanilla/core';

import { WidgetRegistryService } from '../widget/core/widget-registry.service';
import { LinkListWidgetComponent } from './link-list-widget.component';

export const COUNT_QUICKLINKS = 'count-quick-links';
export const QUICK_LINKS = 'quick-links';

@Injectable()
export class BootstrapQuickLink implements OnFeatureInit {
    constructor(
        private widgetRegistry: WidgetRegistryService,
        private slot: DynamicLayoutService,
    ) {}

    onFeatureInit() {
        this.widgetRegistry.registerLazy(WidgetType.LinkList, LinkListWidgetComponent);
    }

    registerCountQuickLinks(): void {
        try {
            const currentAppSlot = this.slot.getSlot<SingleSlot>(COUNT_QUICKLINKS, SlotType.Single);
            if (!currentAppSlot) {
                this.slot.registerSlot(COUNT_QUICKLINKS, SlotType.Single);
            }
        } catch {
            this.slot.registerSlot(COUNT_QUICKLINKS, SlotType.Single);
        }
    }

    registerQuickLinks(): void {
        try {
            const currentAppSlot = this.slot.getSlot<SingleSlot>(QUICK_LINKS, SlotType.Single);
            if (!currentAppSlot) {
                this.slot.registerSlot(QUICK_LINKS, SlotType.Single);
            }
        } catch {
            this.slot.registerSlot(QUICK_LINKS, SlotType.Single);
        }
    }
}
