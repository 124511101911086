import { Component, OnInit } from '@angular/core';

import { NativeAlertsConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { cloneDeep } from 'lodash-es';
import { Subscription as RxjsSubscription } from 'rxjs';

import { ActiveModal } from '../../modal/common/modal-ref';
import { AlertConfigService } from '../alert-config.service';
import { AlertSubscriptionService } from '../alert-subscription.service';
import { AlertEvent, AlertMessage, AlertPreferences, AlertViewModel, EventViewModel, Mode, NotificationEnabled, Page, Subscription } from '../model';
import { NativeAlertsService } from '../native-alerts.service';
import { AlertSelectorService } from './alert-selector.service';

@Component({
    selector: 'ms-alert-selector',
    templateUrl: 'alert-selector.html',
})
export class AlertSelectorComponent implements OnInit {
    private page: Page;
    events: EventViewModel[];
    alerts: AlertViewModel[];
    selectedEvent: EventViewModel;
    allSelected: boolean;
    formChanged: boolean;
    private alertPreferences: AlertPreferences = {};
    private alertMessagesCache: { [sportId: number]: AlertMessage[] } = {};
    private detailViewInitValues: { name: string; value: boolean }[] = [];
    private subscription: RxjsSubscription;

    // TODO MD
    constructor(
        private selectorService: AlertSelectorService,
        private subscriptionService: AlertSubscriptionService,
        private alertConfigService: AlertConfigService,
        public sitecore: Sitecore,
        private activeModal: ActiveModal,
        private nativeAlertsConfig: NativeAlertsConfig,
        private nativeAlertsService: NativeAlertsService,
    ) {}

    ngOnInit(): void {
        this.page = Page.List;
        this.events = this.createEventsModel();

        if (this.isSingleMode) {
            this.edit(this.events[0]);
        }

        if (this.isMultiMode) {
            this.refreshSelectAllButton();
        }
        this.setInitailValues();
    }

    closeSelector(): void {
        this.activeModal.close();
    }

    save(): void {
        if (!this.nativeAlertsConfig.appNotificationsEnabledCheck) {
            this.saveAlerts();
        } else {
            this.verifyAndSaveAlerts();
        }
    }

    private saveAlerts(): void {
        if (this.isDetailView) {
            this.updateSelectedSubscription();
        }

        this.updateEvents();
    }

    private verifyAndSaveAlerts(): void {
        this.subscription?.unsubscribe();
        this.subscription = this.nativeAlertsService.subscribeToNotificationCheck().subscribe((data) => {
            if (data?.parameters?.notificationEnabled === NotificationEnabled.Yes) {
                this.saveAlerts();
            }
            this.subscription.unsubscribe();
        });
    }

    backToList(): void {
        this.updateSelectedSubscription();
        this.refreshSelectAllButton();

        this.page = Page.List;
    }

    edit(event: EventViewModel): void {
        this.page = Page.Detail;
        this.selectedEvent = event;
        this.alerts = this.createAlertsModel(event.subscription);

        if (!this.formChanged) {
            this.formChanged = !this.hasAnyAlerts(event.subscription);
        }
    }

    toggleAlert(alert: AlertViewModel): void {
        alert.isSelected = !alert.isSelected;
        this.formChanged = true;
    }

    toggleEvent(subscription: Subscription): void {
        this.toggleScores(subscription, !this.hasAnyAlerts(subscription));

        this.refreshSelectAllButton();
        this.formChanged = this.isValueChanged();
    }

    toggleAllEvents(): void {
        this.allSelected = !this.allSelected;

        this.events.forEach((event) => {
            if (this.allSelected) {
                if (this.hasAnyAlerts(event.subscription)) {
                    return;
                }

                this.toggleScores(event.subscription, true);
            } else {
                this.toggleScores(event.subscription, false);
            }
        });

        this.formChanged = this.isValueChanged();
    }

    private toggleScores(subscription: Subscription, subscribe: boolean): void {
        if (subscribe) {
            subscription.scores = this.getAlertsIdsForSport(subscription.sportId);
            this.addTrackingInfo(subscription);
        } else {
            subscription.scores = [];
            delete subscription.tracking;
        }
    }

    private updateEvents(): void {
        const subscriptionsToUpdate = this.events.filter((event) => this.shouldUpdate(event.subscription)).map((s) => s.subscription);

        if (subscriptionsToUpdate.length > 0) {
            this.subscriptionService
                .updateSubscriptions(subscriptionsToUpdate)
                .then(() => {
                    this.selectorService.confirm(subscriptionsToUpdate);
                })
                .finally(() => this.activeModal.close());
        } else {
            this.activeModal.close();
        }
    }

    private refreshSelectAllButton(): void {
        const noAlertEvent = this.events.find((e) => !this.hasAnyAlerts(e.subscription));
        this.allSelected = noAlertEvent === undefined ? true : false;
    }

    private createEventsModel(): EventViewModel[] {
        return this.selectorService.model.map<EventViewModel>((alertEvent: AlertEvent) => {
            const subscription = this.subscriptionService.getSubscription(alertEvent.eventId, alertEvent.eventGroupId);
            if (subscription) {
                return {
                    subscription: cloneDeep(subscription),
                    title: alertEvent.title,
                };
            }

            return {
                subscription: this.subscriptionService.createNewSubscription(alertEvent),
                title: alertEvent.title,
            };
        });
    }

    private createAlertsModel(subscription: Subscription): AlertViewModel[] {
        const alertsMessages: AlertMessage[] = this.getAlertMessages(subscription.sportId);
        const hasAnyAlerts = this.hasAnyAlerts(subscription);
        const preferences = this.alertPreferences[subscription.sportId];

        const model = alertsMessages.map<AlertViewModel>((alertMessage) => {
            const alertViewModel: AlertViewModel = {
                info: alertMessage,
            };

            if (hasAnyAlerts) {
                alertViewModel.isSelected = alertMessage.ids.every((id) => subscription.scores.indexOf(id) > -1);
            } else if (preferences) {
                alertViewModel.isSelected = alertMessage.ids.every((id) => preferences.indexOf(id) > -1);
            } else {
                alertViewModel.isSelected = alertMessage.isTop;
            }

            return alertViewModel;
        });

        return model;
    }

    private updateSelectedSubscription(): void {
        const selectedIds = this.getSelectedAlertIds();
        const updatePreferences = !selectedIds.length || selectedIds.sort().join(',') !== this.selectedEvent.subscription.scores.sort().join(',');
        this.selectedEvent.subscription.scores = selectedIds;
        this.addTrackingInfo(this.selectedEvent.subscription);

        if (updatePreferences) {
            this.alertPreferences[this.selectedEvent.subscription.sportId] = this.selectedEvent.subscription.scores;
        }
    }

    private getSelectedAlertIds(): number[] {
        let ids: number[] = [];
        this.alerts.filter((alert) => alert.isSelected).forEach((_) => (ids = ids.concat(_.info.ids)));

        return ids;
    }

    private addTrackingInfo(subscription: Subscription): void {
        this.alerts = this.alerts || this.createAlertsModel(subscription);
        const data = this.alerts
            .filter((_) => _.isSelected)
            .map((_) => _.info.key)
            .join('_');
        if (data.length) {
            subscription.tracking = `${this.selectorService.source}_${data}`;
        }
    }

    private getAlertsIdsForSport(sportId: number): number[] {
        const preferences = this.alertPreferences[sportId];
        if (preferences) {
            return preferences;
        }

        return this.alertConfigService.getTopIdsBySport(sportId);
    }

    private hasAnyAlerts(subscription: Subscription): boolean {
        return subscription.scores && subscription.scores.length > 0;
    }

    private shouldUpdate(subscription: Subscription): boolean {
        const currentSubscription = this.subscriptionService.getSubscription(subscription.event.id, subscription.event.groupId);
        if (currentSubscription) {
            return currentSubscription.scores.sort().join(',') !== subscription.scores.sort().join(',');
        }

        return this.hasAnyAlerts(subscription);
    }

    private getAlertMessages(sportId: number): AlertMessage[] {
        if (!this.alertMessagesCache[sportId]) {
            this.alertMessagesCache[sportId] = this.alertConfigService.getBySport(sportId);
        }

        return this.alertMessagesCache[sportId];
    }

    private setInitailValues() {
        this.detailViewInitValues = this.events.map((e) => ({
            name: e.title,
            value: this.hasAnyAlerts(e.subscription),
        }));
    }

    private isValueChanged(): boolean {
        let changed = false;
        this.events.forEach((event) => {
            if (!changed) {
                changed = !!this.detailViewInitValues.find((e) => e.name === event.title && this.hasAnyAlerts(event.subscription) !== e.value);
            }
        });

        return changed;
    }

    get showConfirmButton(): boolean {
        return this.isSingleMode || (!!this.formChanged && this.isListView);
    }

    get showFooter(): boolean {
        return this.isListView || this.isSingleMode;
    }

    get showBackButton(): boolean {
        return this.isDetailView && this.isMultiMode;
    }

    get isDetailView(): boolean {
        return this.page === Page.Detail;
    }

    get isListView(): boolean {
        return this.page === Page.List;
    }

    get isMultiMode(): boolean {
        return this.selectorService.mode === Mode.Multi;
    }

    get isSingleMode(): boolean {
        return this.selectorService.mode === Mode.Single;
    }

    get title(): string {
        return this.isDetailView
            ? this.sitecore.nativeAlerts?.messages.SingleEventSelectorTitle || ''
            : this.sitecore.nativeAlerts?.messages.MultiEventSelectorTitle || '';
    }
}
