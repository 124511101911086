import { Injectable } from '@angular/core';

import { Nullable } from '@frontend/sports/common/base-utils';
import { GeolocationConfig } from '@frontend/sports/common/client-config-data-access';
import { ApiService } from '@frontend/sports/common/core/feature/http';
import { DeviceService, LoadingIndicatorHandler, LoadingIndicatorService, NativeAppService, TimerService } from '@frontend/vanilla/core';
import { CoordinatorStates, Optional } from '@geolocation/coordinator';
import { once } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';

import { EpcotConfigService, EpcotModule } from '../common/epcot-config.service';
import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { ModalDialogService } from '../modal/dialog/modal-dialog.service';
import { trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';
import { UserService } from '../user/services/user.service';
import { GeolocationCoordinatorService } from './geo-coordinator.service';
import { GDSResponse, RetryType } from './geo-location.model';

const loadDownloadAppComponent = () =>
    import(/* webpackChunkName: "ms-download-app" */ './download-native-app/download-app.component').then((m) => m.DownloadAppComponent);
const loadGeoGuardValidationComponent = () =>
    import(/* webpackChunkName: "ms-geo-guard-validation" */ './geo-guard-validation/geo-guard-validation.component').then(
        (m) => m.GeoGuardValidationComponent,
    );
const loadGeoLocationErrorComponent = () =>
    import(/* webpackChunkName: "ms-geo-location-error" */ './geo-location-error/geo-location-error.component').then(
        (m) => m.GeoLocationErrorComponent,
    );
const loadGeoLocationInstallerComponent = () =>
    import(/* webpackChunkName: "ms-geo-location-installer" */ './geo-location-installer/geo-location-installer.component').then(
        (m) => m.GeoLocationInstallerComponent,
    );

@Injectable({ providedIn: 'root' })
export class GeolocationCheckService {
    constructor(
        private geolocationCoordinatorService: GeolocationCoordinatorService,
        private modalDialogService: ModalDialogService,
        private deviceService: DeviceService,
        private nativeAppService: NativeAppService,
        private geolocationConfig: GeolocationConfig,
        private user: UserService,
        private loadingIndicator: LoadingIndicatorService,
        private apiService: ApiService,
        private timerService: TimerService,
        private trackingService: TrackingService,
        loggerFactory: LoggerFactory,
        private epcotConfigService: EpcotConfigService,
    ) {
        this.logger = loggerFactory.getLogger('GeolocationCheckService');
    }

    private readonly logger: SportsRemoteLogger;
    private isLocationFetched = false;
    private loading: LoadingIndicatorHandler | null;
    isLocationFetchTimeOut = false;
    gdsSubscription: Subscription;
    resetTimer: Nullable<NodeJS.Timeout> = null;
    gdsApiInterval = this.geolocationConfig.initialRetryInterval;
    geoComplyErrorCodes = this.geolocationConfig.retriableErrorCodes;
    resetTimerAndRefetchLocation = false;
    errorResponse: GDSResponse;

    /**
     * Check geolocation restriction and calls the `callback` function at most once.
     * This function uses a callback instead of something like Observables because it's used in many places (like the pick component) and we don't want to introduce an overhead.
     *
     * @param [callback]
     * @param [options]
     * @returns
     * @memberof GeolocationCheckService
     */
    checkGeolocation(callback?: (isLocationFailed: boolean) => void, options?: { waitForLocationAvailable: boolean }): void {
        const cb = once(callback || (() => {}));

        if (
            this.geolocationConfig.isGeolocationEnabled &&
            this.nativeAppService.isNative &&
            this.user.isAuthenticated &&
            options?.waitForLocationAvailable
        ) {
            this.validateLocation(cb);
        } else {
            if (this.checkBetRestriction()) {
                return;
            }

            if (!this.isGeoLocationRestrictionEnabled()) {
                cb(false);

                return;
            }

            if (!this.loading && !this.isLocationFetched) {
                this.loading = this.loadingIndicator.start();
            }

            this.geolocationCoordinatorService.onInitialized = (initializationStatus: Optional<CoordinatorStates>) => {
                if (initializationStatus === CoordinatorStates.INITIALIZED) {
                    this.stopLoader();

                    if (options?.waitForLocationAvailable) {
                        this.validateLocation(cb);
                    } else {
                        cb(false);
                    }
                }
            };

            this.geolocationCoordinatorService.onUninitialized = () => {
                cb(true);

                if (this.deviceService.isMobile) {
                    this.showLocationConfirm();
                } else {
                    this.showGeoLocationInstallerDialog();
                }
                this.stopLoader();
            };

            this.geolocationCoordinatorService.onError = () => {
                this.stopLoader();
            };

            this.geolocationCoordinatorService.initialize();
        }
    }

    checkGeoLocation$(options: { waitForLocationAvailable: boolean }): Observable<boolean> {
        return new Observable((subscriber) => {
            this.checkGeolocation((isLocationFailed) => {
                subscriber.next(isLocationFailed);
                subscriber.complete();
            }, options);
        });
    }

    private stopLoader(): void {
        this.loading?.done();
        this.loading = null;
    }

    private isGeoLocationRestrictionEnabled(): boolean {
        return (
            this.geolocationConfig.isGeolocationEnabled &&
            this.user.isAuthenticated &&
            !this.nativeAppService.isNative &&
            !this.nativeAppService.isNative
        );
    }

    private validateLocation(callback: (isLocationFailed: boolean) => void): void {
        this.isLocationFetchTimeOut = false;
        this.isLocationFetched = false;
        this.resetTimerAndRefetchLocation = false;
        this.errorResponse = {} as GDSResponse;
        this.gdsApiInterval = this.geolocationConfig.initialRetryInterval;
        this.getLocationDetailsfromGDS(callback);
        this.startTimer(callback);
    }

    private startTimer(callback: (isLocationFailed: boolean) => void): void {
        this.clearTimer();

        this.resetTimer = this.timerService.setTimeout(() => {
            this.isLocationFetchTimeOut = true;
            if (!this.isLocationFetched) {
                if (this.geolocationConfig.logGenericFailureResults) {
                    this.logGeoLocationError(new Date(), this.errorResponse);
                }
                this.showGeoLocationError();
                callback(true);

                return;
            }
        }, this.gdsApiInterval);
    }

    private getLocationDetailsfromGDS(callback: (isLocationFailed: boolean) => void): void {
        if (!this.isLocationFetchTimeOut && this.geolocationConfig.gdsUrl) {
            const startGDSDate = new Date();
            this.apiService.post<GDSResponse>(this.geolocationConfig.gdsUrl, 'ssoKey=' + this.user.ssoToken).subscribe(
                (result) => {
                    if (result?.isLocationAvailable) {
                        if (result?.locationStatus === 'VALID_LOCATION' && result?.errorCode === 0) {
                            this.isLocationFetched = true;
                            callback(false);

                            return;
                        }
                        const isRetryableErrCode =
                            this.geolocationConfig.isRetryButtonEnabled &&
                            result?.troubleShooterMessages?.tsMsgs?.find((errMsg) => errMsg.retry === RetryType.Retry);

                        this.errorResponse = result;
                        if (isRetryableErrCode) {
                            this.isLocationFetched = false;
                            this.refetchLocationOnRetryableErrorCodes(callback);
                            this.refetchGdsApi(callback);
                        } else {
                            this.clearTimer();
                            this.showGeoLocationError();
                            callback(true);
                            return;
                        }
                    } else {
                        this.errorResponse = result;
                        this.refetchGdsApi(callback);
                    }
                },
                (error) => {
                    this.logGeoLocationError(startGDSDate, error);
                },
            );
        }
    }

    private refetchLocationOnRetryableErrorCodes(callback: (isLocationFailed: boolean) => void): void {
        if (!this.resetTimerAndRefetchLocation) {
            this.isLocationFetchTimeOut = false;
            this.gdsApiInterval = this.geolocationConfig.retryInterval;
            this.resetTimerAndRefetchLocation = true;
            this.startTimer(callback);
        }
    }

    private clearTimer(): void {
        if (this.resetTimer) {
            this.timerService.clearTimeout(this.resetTimer);
        }
    }

    private refetchGdsApi(callback: (isLocationFailed: boolean) => void): void {
        this.timerService.setTimeout(() => {
            this.getLocationDetailsfromGDS(callback);
        }, 1000);
    }

    private async showGeoLocationError(): Promise<void> {
        const modalHandler = this.modalDialogService.openPopup(await loadGeoLocationErrorComponent(), {
            settings: {
                cssClass: this.getDialogClassNames(),
                showPageHeader: true,
                fit: false,
                isModalDialog: true,
            },
            data: { errorMessages: this.errorResponse?.troubleShooterMessages?.tsMsgs || [] },
        });
        if (this.isEpcotEnabled() && this.isQuickbetShowing()) {
            modalHandler.addBackdropClass('fadeout-quickbet-geo-location');
        }
        this.geolocationCoordinatorService.onError = undefined;
        this.geolocationCoordinatorService.onInitialized = undefined;
        this.doTracking();
    }

    private async showGeoLocationInstallerDialog(): Promise<void> {
        this.modalDialogService.openPopup(await loadGeoLocationInstallerComponent(), {
            settings: {
                cssClass: 'geo-location-popup',
                showPageHeader: true,
                fit: false,
                isModalDialog: false,
            },
        });
        this.geolocationCoordinatorService.onError = undefined;
        this.geolocationCoordinatorService.onInitialized = undefined;
    }

    private async showLocationConfirm(): Promise<void> {
        this.modalDialogService.openPopup(await loadGeoGuardValidationComponent(), {
            settings: {
                cssClass: 'geo-guard-validation',
                showPageHeader: true,
                fit: false,
                isModalDialog: false,
            },
        });
        this.geolocationCoordinatorService.onError = undefined;
        this.geolocationCoordinatorService.onInitialized = undefined;
    }

    private async showDownloadAppDialog(): Promise<void> {
        this.modalDialogService.openPopup(await loadDownloadAppComponent(), {
            settings: {
                cssClass: 'native-app-installer',
                showPageHeader: true,
                fit: false,
                isModalDialog: false,
            },
        });
    }

    private checkBetRestriction(): boolean {
        if (
            this.geolocationConfig.isBetRestrictionEnabled &&
            !this.nativeAppService.isNative &&
            !this.nativeAppService.isNative &&
            this.user.isAuthenticated
        ) {
            this.showDownloadAppDialog();

            return true;
        }

        return false;
    }

    private doTracking(): void {
        this.trackingService.track(trackingConstants.EVENT_GEOLOCATION_ERROR, {
            [trackingConstants.GEOLOCATION_ERRORCODE]: this.errorResponse?.errorCode,
            [trackingConstants.GEOLOCATION_ERRORMESSAGE]: this.errorResponse?.errorMsg,
        });
    }

    private isEpcotEnabled(): boolean {
        return this.epcotConfigService.isEnabled(EpcotModule.QuickBet);
    }

    private isQuickbetShowing(): boolean {
        const hasQuickBet = document.getElementsByClassName('quick-bet__body');

        return !!hasQuickBet;
    }

    private getDialogClassNames(): string {
        if (this.isEpcotEnabled() && this.isQuickbetShowing()) {
            return 'geo-comply-error-dialog has-quickbet';
        }

        return 'geo-comply-error-dialog';
    }

    private logGeoLocationError(startGDSDate: Date, errorMessage: GDSResponse | any): void {
        if (this.geolocationConfig.logGenericFailureResults) {
            this.logger.error(
                `GDS api url: ${this.geolocationConfig.gdsUrl}, ran for: ${new Date().valueOf() - startGDSDate.valueOf()}ms, error: ${JSON.stringify(errorMessage)}, ssotoken: ${this.user.ssoToken?.substring(0, 100)}`,
            );
        }
    }
}
