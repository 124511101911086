import { Injectable } from '@angular/core';

import { ShareMyBetConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { ApiService } from '@frontend/sports/common/core/feature/http';
import { ShortUriResponse } from '@frontend/sports/types/models/short-uri';
import { NavigationService } from '@frontend/vanilla/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EpcotConfigService } from '../common/epcot-config.service';
import { CookieProvider } from '../cookie-provider/cookie-provider.service';
import { ModalRef } from '../modal/common/modal-ref';
import { ButtonType, ContentData, DialogSettings, DialogType } from '../modal/dialog/modal-dialog.model';
import { ModalDialogService } from '../modal/dialog/modal-dialog.service';
import { PickUriParams } from '../navigation-core/pick-uri.model';
import { PickUriService } from '../navigation-core/pick-uri.service';
import { trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';
import { UserService } from '../user/services/user.service';
import { ShareMyBetCookie, ShareMyBetTracking } from './share-my-bet.models';
import { IPlacedBetInfo } from './share-my-bet.state';

@Injectable()
export class ShareMyBetService {
    constructor(
        private shareMyBetConfig: ShareMyBetConfig,
        private apiService: ApiService,
        private pickUriService: PickUriService,
        private modalDialogService: ModalDialogService,
        private navigationService: NavigationService,
        private sitecore: Sitecore,
        private userService: UserService,
        private trackingService: TrackingService,
        private cookieProvider: CookieProvider,
        private epcotConfigService: EpcotConfigService,
    ) {}

    shortenUri(betInfo: IPlacedBetInfo): Observable<string> {
        const uriParam: PickUriParams = {
            type: betInfo.betType,
            addPage: true,
        };

        if (this.userService.accountId) {
            uriParam.userId = this.userService.accountId;
        }

        if (betInfo.betslipId) {
            uriParam.betslipId = betInfo.betslipId;
        }

        const options = this.pickUriService.generate(betInfo.picksParams, uriParam);
        const uri = encodeURIComponent(`${this.navigationService.location.host()}${options}`);

        return this.apiService.get<ShortUriResponse>('shortUri/shortenUri', { uri }, { showSpinner: true }).pipe(
            map((urlResponse: ShortUriResponse) => {
                const url = urlResponse.uri;
                const culture = this.navigationService.location.culture;
                const shortUri = `${this.navigationService.location.baseUrl()}/${culture}/share-my-bet/${url?.split('/').pop()}`;

                return shortUri;
            }),
            catchError(() => {
                this.showUriExpiredDialog(); // TODO: Change with general error message?

                return of('');
            }),
        );
    }

    isOnboardingShown(): boolean {
        return !!this.cookieProvider.getCookie(ShareMyBetCookie.Onboarding);
    }

    setOnboardingShown(): void {
        this.cookieProvider.setCookie(ShareMyBetCookie.Onboarding, '1');
    }

    isComplianceShown(): boolean {
        return !!this.cookieProvider.getCookie(ShareMyBetCookie.Compliance) || !!this.cookieProvider.getCookie(ShareMyBetCookie.Complience); // Complience, Needs to be removed after the next release
    }

    setComplianceShown(): void {
        this.cookieProvider.setCookie(ShareMyBetCookie.Compliance, '1', { expires: this.shareMyBetConfig.complianceCookieExpiryDays });
    }

    getComplianceDialog(): ModalRef {
        const translations = this.sitecore.modalDialogs.shareMyBetInterceptor;

        const contentData: ContentData = {
            type: DialogType.Action,
            firstParagraph: translations.DialogText,
            buttons: [
                {
                    type: ButtonType.Cancel,
                    text: translations.DialogButtonTextReject,
                    buttonConfig: {
                        kind: this.epcotConfigService.isEnabled() ? 'secondary' : 'tertiary',
                        variant: 'outline',
                        size: 'large',
                    },
                },
                {
                    type: ButtonType.Ok,
                    text: translations.DialogButtonTextAccept,
                    buttonConfig: {
                        kind: 'primary',
                        variant: 'filled',
                        size: 'large',
                    },
                },
            ],
        };

        const settings: DialogSettings = {
            isModalDialog: false,
            cssClass: 'share-my-bet__compliance',
        };

        return this.modalDialogService.openMessage(contentData, settings);
    }

    showNotSupportedDialog(): void {
        const notSupported = this.sitecore.modalDialogs.notSupported;

        const contentData = {
            type: DialogType.Warning,
            firstParagraph: notSupported.DialogText,
            buttons: [
                {
                    type: ButtonType.Ok,
                    text: notSupported.DialogButtonText,
                },
            ],
        };

        const settings: DialogSettings = {
            isModalDialog: false,
            cssClass: 'share-my-bet__not-supported',
        };

        this.modalDialogService.openMessage(contentData, settings);
    }

    showUriExpiredDialog(refbet: string = ShareMyBetTracking.NotApplicable): void {
        this.doReceiverErrorTracking(ShareMyBetTracking.ErrorLoaded, refbet);

        const linkExpired = this.sitecore.modalDialogs.linkExpired;

        const contentData = {
            title: linkExpired.DialogTitle,
            type: DialogType.Warning,
            firstParagraph: linkExpired.DialogText,
            buttons: [
                {
                    type: ButtonType.Ok,
                    text: linkExpired.DialogButtonText,
                },
            ],
        };

        const settings: DialogSettings = {
            isModalDialog: false,
            cssClass: `share-my-bet__link-expired`,
        };

        this.modalDialogService.openMessage(contentData, settings).result.then((buttonType: ButtonType) => {
            if (buttonType === ButtonType.Ok) {
                this.doReceiverErrorTracking(ShareMyBetTracking.ErrorOkButton, refbet);
            }
        });
    }

    doSenderTracking(actionEvent: string, location: string, betslipId?: string): void {
        const urlClicked = `${this.userService.accountId}|${betslipId}`;
        const trackData = {
            [trackingConstants.COMPONENT_CATEGORY_EVENT]: ShareMyBetTracking.BetSharing,
            [trackingConstants.COMPONENT_LABEL_EVENT]: ShareMyBetTracking.ShareMyBet,
            [trackingConstants.COMPONENT_ACTION_EVENT]: actionEvent,
            [trackingConstants.COMPONENT_POSITION_EVENT]: ShareMyBetTracking.ShareBetLink,
            [trackingConstants.COMPONENT_LOCATION_EVENT]: location,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: ShareMyBetTracking.UserClicksButton,
            [trackingConstants.COMPONENT_URL_CLICKED]:
                actionEvent === ShareMyBetTracking.ShareMyBetClick ? ShareMyBetTracking.NotApplicable : urlClicked,
        };

        this.trackingService.triggerEventWithCleaning(trackingConstants.EVENT_TRACKING, trackData);
    }

    setRefbet(refbet: string): void {
        this.cookieProvider.setCookie(ShareMyBetCookie.TrackingRefBet, refbet.replace('|', '-'));
    }

    private doReceiverErrorTracking(actionEvent: string, refbet: string): void {
        const trackData = {
            [trackingConstants.COMPONENT_CATEGORY_EVENT]: ShareMyBetTracking.BetSharing,
            [trackingConstants.COMPONENT_LABEL_EVENT]: ShareMyBetTracking.LinkExpiredError,
            [trackingConstants.COMPONENT_ACTION_EVENT]: actionEvent,
            [trackingConstants.COMPONENT_POSITION_EVENT]: ShareMyBetTracking.NotApplicable,
            [trackingConstants.COMPONENT_LOCATION_EVENT]: ShareMyBetTracking.NotApplicable,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: ShareMyBetTracking.LinkExpiredError,
            [trackingConstants.COMPONENT_URL_CLICKED]: refbet,
        };

        this.trackingService.triggerEventWithCleaning(trackingConstants.EVENT_TRACKING, trackData);
    }
}
