<ng-container *ngIf="shareMyBetState$ | async as shareMyBet">
    <ng-container *ngIf="shareMyBet.sharingStates[betslipId] as state">
        <div class="share-my-bet__copy-dialog" *ngIf="state.isCopyLinkDialogOpen">
            <div *ngIf="withHeader" class="share-my-bet__copy-header" [ngClass]="{ expanded: isToolbarExpanded() }">
                <span class="share-my-bet__copy-title">{{ sitecore.shareMyBet.messages.CopyTitle }}</span>
                <span class="share-my-bet__copy-close theme-ex" (click)="closeDialog(betslipId)"></span>
            </div>
            <div class="share-my-bet__copy-text">{{ sitecore.shareMyBet.messages.CopyMessage }}</div>
            <input type="text" class="share-my-bet__short-uri-text" [value]="state.uri" readonly />
            <div class="share-my-bet__copy-button" (click)="copy(betslipId, state)">{{ getCopyText(state.isCopied) }}</div>
        </div>
    </ng-container>
</ng-container>
