import { Injectable } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { template, templateSettings } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class TimerUtilsService {
    constructor(private sitecore: Sitecore) {}

    getEventStartingInText(eventStartDate: number): string {
        const timeDiff = Math.floor((eventStartDate - Date.now()) / 60000);

        if (timeDiff >= 60) {
            return this.sitecore.globalMessages.NotStarted;
        }
        if (timeDiff > 1) {
            return this.sitecore.globalMessages.StartingInMinutes.replace(/{{delta}}/, timeDiff.toString());
        }
        if (timeDiff === 1) {
            return this.sitecore.globalMessages.StartingInMinute.replace(/{{delta}}/, '1');
        }

        return this.sitecore.globalMessages.StartsNow;
    }

    getEventStartingInFullText(eventStartDate: number): string {
        const timeDiff = Math.floor((eventStartDate - Date.now()) / 60000);
        templateSettings.interpolate = /{([\s\S]+?)}/g;
        let format = this.sitecore.globalMessages.StartsNowFormatted;
        if (timeDiff >= 60) {
            format = this.sitecore.globalMessages.NotStarted;
        }
        if (timeDiff > 0) {
            format = this.sitecore.globalMessages.TimerStartingInFormatted.replace(/{{delta}}/, timeDiff.toString());
        }

        const interpolate = template(format);

        return interpolate({ date: timeDiff });
    }
}
