import { ButtonConfig } from '../../common/button-config.models';
import { IDialogTracking } from '../../tracking/tracking.models';

export interface IConfirmationDialogComponentModel {
    message: string;
    buttons: IConfirmationDialogButton[];
    tracking?: IDialogTracking;
    icon?: string;
    cssClass?: string;
}

export interface IConfirmationDialogModel extends IConfirmationDialogComponentModel {
    title: string;
    backdropClass?: string;
}

export interface IConfirmationDialogButton {
    buttonType: ConfirmationDialogButtonType;
    buttonTitle: string;
    // TODO Buttons Design System: make buttonConfig non nullable when all buttons are migrated to DS
    buttonConfig?: ButtonConfig;
    cssClass?: string;
}

export enum ConfirmationDialogButtonType {
    Ok = 'Ok',
    Cancel = 'Cancel',
}

export interface IConfirmationDialogInput {
    contentModel: IConfirmationDialogModel;
}
