import { pprops } from '@frontend/sports/common/base-utils';
import { ParsedUrl } from '@frontend/vanilla/core';
import { createAction, props } from '@ngrx/store';

export class ShareMyBetApiActions {
    private static readonly ADD_BETSLIP_ID = '@share-my-bet/ADD_BETSLIP_ID';
    private static readonly HANDLE_SHARE_BET = '@share-my-bet/HANDLE_SHARE_BET';

    static addBetslipId = createAction(ShareMyBetApiActions.ADD_BETSLIP_ID, props<{ betslipId: string }>());
    static handleShareBet = createAction(ShareMyBetApiActions.HANDLE_SHARE_BET, pprops<{ pickCount: number; uri: ParsedUrl }>());
}
