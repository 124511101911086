import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Odds, OddsFormat } from '@bpos';

@Injectable({ providedIn: 'root' })
@Pipe({
    name: 'msMyBetsOddsPipe',
})
export class MyBetsOddsPipe implements PipeTransform {
    transform(odds: Odds | undefined): string {
        if (!odds) {
            return '-';
        }

        if (odds.oddsFormat === OddsFormat.American) {
            const value = odds.american!;

            return (value > 0 ? '+' : '') + value;
        }
        if (odds.oddsFormat === OddsFormat.British) {
            const { numerator, denominator } = odds.british;

            return `${numerator}/${denominator}`;
        }

        return odds.european!.toFixed(3).replace(/0$/, '');
    }
}
