import { Component, Input } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ShareMyBetActions } from './share-my-bet.actions';
import { ShareMyBetTracking } from './share-my-bet.models';
import { ShareMyBetService } from './share-my-bet.service';
import { IShareMyBetRootState, IShareMyBetState, ISharingState, shareMyBetStateSelector } from './share-my-bet.state';

@Component({
    selector: 'ms-share-my-bet-copy',
    templateUrl: './share-my-bet-copy.html',
})
export class ShareMyBetCopyComponent {
    @Input() betslipId: string;
    @Input() location: string;
    @Input() withHeader: boolean;

    shareMyBetState$: Observable<IShareMyBetState>;

    constructor(
        public sitecore: Sitecore,
        private shareMyBetService: ShareMyBetService,
        private store: Store<IShareMyBetRootState>,
    ) {
        this.shareMyBetState$ = store.select(shareMyBetStateSelector);
    }

    copy(betslipId: string, sharingState: ISharingState): void {
        if (sharingState.isCopied) {
            return;
        }

        this.shareMyBetService.doSenderTracking(ShareMyBetTracking.CopyShareLinkClick, this.location, betslipId);

        this.store.dispatch(ShareMyBetActions.copyShortUri({ payload: { betslipId, uri: sharingState.uri } }));
    }

    closeDialog(betslipId: string): void {
        this.store.dispatch(ShareMyBetActions.closeCopyLinkDialog({ betslipId }));
    }

    getCopyText(isCopied: boolean): string {
        return isCopied ? this.sitecore.shareMyBet.messages.Copied : this.sitecore.shareMyBet.messages.Copy;
    }

    isToolbarExpanded(): boolean {
        const element = document.getElementById(this.betslipId);

        if (!element) {
            return false;
        }

        return element.getElementsByClassName('my-bets-toolbar')[0].classList.contains('expanded');
    }
}
