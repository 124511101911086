import { objectEntries } from '@frontend/sports/common/base-utils';
import { createReducer, on } from '@ngrx/store';
import produce from 'immer';

import { ShareMyBetApiActions } from '../share-my-bet-api/actions';
import { ShareMyBetActions } from './share-my-bet.actions';
import { ISharingState, defaultShareMyBetState } from './share-my-bet.state';

export const shareMyBetReducer = createReducer(
    defaultShareMyBetState,
    on(ShareMyBetActions.share, (state, action) => ({
        ...state,
        sharingStates: {
            ...state.sharingStates,
            [action.payload.betInfo.betslipId]: {
                uri: action.payload.uri,
                betInfo: action.payload.betInfo,
                isCopied: false,
                isCopyLinkDialogOpen: false,
            },
        },
    })),
    on(ShareMyBetActions.openCopyLinkDialog, (state, action) =>
        produce(state, (draft) => {
            draft.sharingStates[action.betslipId].isCopyLinkDialogOpen = true;
        }),
    ),
    on(ShareMyBetActions.closeCopyLinkDialog, (state, action) =>
        produce(state, (draft) => {
            draft.sharingStates[action.betslipId].isCopyLinkDialogOpen = false;
            draft.sharingStates[action.betslipId].isCopied = false;
        }),
    ),
    on(ShareMyBetActions.copyShortUri, (state, action) =>
        produce(state, (draft) => {
            draft.sharingStates[action.payload.betslipId].isCopied = true;
        }),
    ),
    on(ShareMyBetActions.removeCopied, (state, action) =>
        produce(state, (draft) => {
            draft.sharingStates[action.betslipId].isCopied = false;
        }),
    ),
    on(ShareMyBetActions.receiveBet, (state, action) => ({
        ...state,
        receivingState: {
            pickCount: action.pickCount,
            showImportMessage: true,
        },
    })),
    on(ShareMyBetActions.removeReceiveBetMessage, (state, action) => ({
        ...state,
        receivingState: {
            pickCount: 0,
            showImportMessage: false,
        },
    })),
    on(ShareMyBetApiActions.addBetslipId, (state, action) => ({
        ...state,
        betslipId: action.betslipId,
    })),
    on(ShareMyBetActions.destroyState, (state, action) =>
        produce(state, (draft) => {
            draft.betslipId = '';
            draft.sharingStates[action.betslipId].isCopied = false;
            draft.sharingStates[action.betslipId].isCopyLinkDialogOpen = false;
        }),
    ),
    on(ShareMyBetActions.populateFromStorage, (state, action) => ({
        ...state,
        sharingStates: objectEntries(action.payload.sharingStates).reduce(
            (aggr, current) => {
                aggr[current.key] = {
                    uri: current.value.uri,
                    betInfo: {
                        ...current.value.betInfo,
                        picksParams: current.value.betInfo.picksParams.map((pp) => ({ ...pp })),
                    },
                    isCopied: false,
                    isCopyLinkDialogOpen: false,
                };

                return aggr;
            },
            {} as { [key: string]: ISharingState },
        ),
    })),
);
