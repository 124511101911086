import { Injectable } from '@angular/core';

import { NOT_APPLICABLE, trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';
import { ArcRestrictionTrackingConstants, ArcRestrictionTrackingData, TrackingEvent } from './arc.model';

@Injectable()
export class ArcRestrictionTrackingService {
    constructor(private trackingService: TrackingService) {}

    trackSaferGambalingMessage(trackingEvent: string, trackingData: ArcRestrictionTrackingData): void {
        this.trackingService.track(trackingEvent, {
            [trackingConstants.COMPONENT_CATEGORY_EVENT]: ArcRestrictionTrackingConstants.CategoryEvent,
            [trackingConstants.COMPONENT_LABEL_EVENT]: ArcRestrictionTrackingConstants.LabelEvent,
            [trackingConstants.COMPONENT_ACTION_EVENT]: trackingData.action,
            [trackingConstants.COMPONENT_POSITION_EVENT]: trackingData.position,
            [trackingConstants.COMPONENT_LOCATION_EVENT]: trackingData.source,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: ArcRestrictionTrackingConstants.SaferGamblingEventDetails,
            [trackingConstants.COMPONENT_URL_CLICKED]: NOT_APPLICABLE,
        });
    }

    trackPlayBreakMessage(trackingData: ArcRestrictionTrackingData): void {
        this.trackingService.track(trackingConstants.EVENT_CONTENT_VIEW, {
            [trackingConstants.COMPONENT_CATEGORY_EVENT]: ArcRestrictionTrackingConstants.CategoryEvent,
            [trackingConstants.COMPONENT_LABEL_EVENT]: ArcRestrictionTrackingConstants.LabelEvent,
            [trackingConstants.COMPONENT_ACTION_EVENT]: TrackingEvent.Load,
            [trackingConstants.COMPONENT_POSITION_EVENT]: trackingData.position,
            [trackingConstants.COMPONENT_LOCATION_EVENT]: trackingData.source,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: ArcRestrictionTrackingConstants.PlayBreakEventDetails,
            [trackingConstants.COMPONENT_URL_CLICKED]: NOT_APPLICABLE,
        });
    }

    trackFindOutMoreLink(trackingData: ArcRestrictionTrackingData): void {
        this.trackingService.track(trackingConstants.EVENT_TRACKING, {
            [trackingConstants.COMPONENT_CATEGORY_EVENT]: ArcRestrictionTrackingConstants.CategoryEvent,
            [trackingConstants.COMPONENT_LABEL_EVENT]: ArcRestrictionTrackingConstants.LabelEvent,
            [trackingConstants.COMPONENT_ACTION_EVENT]: TrackingEvent.Click,
            [trackingConstants.COMPONENT_POSITION_EVENT]: ArcRestrictionTrackingConstants.FindOutMoreLinkPositionEvent,
            [trackingConstants.COMPONENT_LOCATION_EVENT]: trackingData.source,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: ArcRestrictionTrackingConstants.SaferGamblingEventDetails,
            [trackingConstants.COMPONENT_URL_CLICKED]: NOT_APPLICABLE,
        });
    }
}
