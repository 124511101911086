import { SlipState } from '@bpos/v1/my-bets';

import { EPSDetailedLogger } from '../../logging/eps-detailed-logger.service';
import { LoggerFactory } from '../../logging/logger-factory.service';
import { UserService } from '../../user/services/user.service';
import { MyBetsConstants } from '../models/bet-types';
import { EarlyPayoutPossibility, EarlyPayoutsWithBetslip } from '../models/early-payout';
import { MyBetsBetslipBase } from '../models/my-bets-viewmodels';
import { EarlyPayoutSubmitService } from './early-payout-submit.service';
import { EarlyPayoutSubscriptionMapperService } from './early-payout-subscription-mapper.service';
import { EarlyPayoutSubscriptionService } from './early-payout-subscription.service';
import { EarlyPayoutUtils } from './early-payout-utils';
import { EarlyPayoutService } from './early-payout.service';
import { MyBetsEarlyPayoutBaseService } from './my-bets-earlypayout-base.service';
import { EarlyPayoutHelpers } from './my-bets-earlypayout-helpers';

export class MyBetsEarlyPayoutService extends MyBetsEarlyPayoutBaseService {
    constructor(
        earlyPayoutService: EarlyPayoutService,
        epSubService: EarlyPayoutSubscriptionService,
        epHelpers: EarlyPayoutHelpers,
        loggerFactory: LoggerFactory,
        msUser: UserService,
        earlyPayoutSubmitService: EarlyPayoutSubmitService,
        epsLogger: EPSDetailedLogger,
        earlyPayoutSubscriptionMapperService: EarlyPayoutSubscriptionMapperService,
        private earlyPayoutUtils: EarlyPayoutUtils,
    ) {
        super(
            earlyPayoutService,
            epSubService,
            epHelpers,
            loggerFactory,
            msUser,
            earlyPayoutSubmitService,
            epsLogger,
            earlyPayoutSubscriptionMapperService,
        );
    }

    isPotentialEarlyPayoutable(betslip: MyBetsBetslipBase): boolean {
        return (
            betslip.state === SlipState.Open &&
            (betslip.isSingleBet || betslip.typeAsName === MyBetsConstants.TypeMulti) &&
            this.earlyPayoutUtils.onlyAllowedBetTypes(betslip)
        );
    }

    async prepareEarlyPayouts(
        earlyPayouts: EarlyPayoutPossibility[],
        potentialEPbetslips: MyBetsBetslipBase[],
        callback: (ep: EarlyPayoutsWithBetslip) => void,
    ): Promise<void> {
        try {
            const enhancedEarlyPayouts = await this.enhanceEligibleBetslips(earlyPayouts);
            this.mapEarlyPayouts(enhancedEarlyPayouts, potentialEPbetslips, this.callbackFunction);
        } catch (error) {
            this.logger.error(error, 'Failed to enhance eligible betslips.');
            this.mapEarlyPayouts(earlyPayouts, potentialEPbetslips, this.callbackFunction);
        }
    }
}
