<ng-container *ngIf="(isUserOnPlayBreak$ | async) && !(featureLocation === FeatureLocation.PostBetSlipMessage); else betslipMessageContainer">
    <div class="playbreak-header">{{ sitecore.betslip.messages.ARCPlayBreakHeader }}</div>
    <div class="playbreak-message">
        <i class="theme-arc playbreak-icon"></i>
        <div class="playbreak-message-content">
            <span> {{ sitecore.betslip.messages.ARCPlayBreakMessage }} </span>
        </div>
    </div>
</ng-container>

<ng-template #betslipMessageContainer>
    <ng-container *ngIf="isMessageFrequency && betslipMessage !== undefined">
        <div class="safergambling-group" (click)="toggle()">
            <i class="theme-arc safergambling-icon"></i>
            <div class="safergambling-header">{{ sitecore.betslip.arcBetslipMessages.ArcSaferGamblingHeader }}</div>
            <i class="icon" [ngClass]="expanded ? 'theme-up' : 'theme-down'"></i>
        </div>
        <ng-container *ngIf="expanded">
            <div class="safergambling-message-content">
                <span [innerHTML]="betslipMessage | msTrust: 'html'"></span>
                <div
                    *ngIf="featureLocation === FeatureLocation.PostBetSlipMessage"
                    class="find-out-more-link"
                    [innerHTML]="sitecore.betslip.arcBetslipMessages.FindOutMore | msTrust: 'html'"
                    (click)="trackHelpLink()"></div>
            </div>
        </ng-container>
    </ng-container>
</ng-template>
