<div class="helpboxheader">
    <ng-container *msShowInEpcot="{ showInEpcot: false }">
        <ng-container *ngTemplateOutlet="headerBadge" />
    </ng-container>

    <div class="helpboxheader-title-container">
        <span class="helpboxheader-title">{{ helpBoxData().headerTitle }}</span>
        @if (!disableInfoIcon()) {
            <div class="helpboxheader-link" [innerHTML]="helpBoxData().infoIconLink" (click)="onInfoIconClick()"></div>
        }
    </div>
    <ng-container *msShowInEpcot="{ showInEpcot: true }">
        <ng-container *ngTemplateOutlet="headerBadge" />
    </ng-container>
    <div (click)="onCloseButtonClick()" class="helpboxheader-close-button" *msShowInEpcot="{ showInEpcot: true }">
        {{ sitecore.globalMessages.Close }}
    </div>
    <div (click)="onCloseButtonClick()" class="helpboxheader-close-button theme-ex" *msShowInEpcot="{ showInEpcot: false }"></div>
</div>

<div class="text" [innerHTML]="helpBoxData().explanationText | msTrust: 'html'"></div>

<ng-content />

<ng-template #headerBadge>
    <div class="helpboxheader-badge">
        <ms-help-box-badge [boxType]="boxType()" [isShown] />
    </div>
</ng-template>
