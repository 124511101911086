import { Injectable } from '@angular/core';

import { NativeAlertsConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { Alert } from '@frontend/sports/types/components/native-alert';

import { AlertMessage } from './model';

@Injectable({ providedIn: 'root' })
export class AlertConfigService {
    constructor(
        private sitecore: Sitecore,
        private nativeAlertsConfig: NativeAlertsConfig,
    ) {}

    readonly minuteInMiliseconds = 60000;

    getBySport(sportId: number): AlertMessage[] {
        const alertMessages: AlertMessage[] = [];
        const nativeAlertsMessagesConfig = this.sitecore.nativeAlerts?.sports;
        const nativeAlertsConfig = this.nativeAlertsConfig;

        if (!nativeAlertsConfig.enabled) {
            return alertMessages;
        }

        const sportConfigs = nativeAlertsConfig.sports.filter((x) => x.id === sportId);

        if (sportConfigs.length === 0) {
            return alertMessages;
        }

        const sportConfig = sportConfigs[0];
        const sports = nativeAlertsMessagesConfig?.filter((x) => x.name.toLowerCase() === sportConfig.name.toLowerCase());

        let messages: {} | undefined = {};
        if (sports?.length) {
            messages = sports[0].nativeAlerts;
        }

        if (!messages) {
            return alertMessages;
        }

        for (const alert of sportConfig.alerts) {
            if (messages[alert.name]) {
                alertMessages.unshift({
                    ids: this.extractIds(alert),
                    key: alert.name,
                    name: messages[alert.name],
                    isTop: Boolean(alert.isTop),
                });
            }
        }

        return alertMessages;
    }

    getTopIdsBySport(sportId: number): number[] {
        let ids: number[] = [];

        const sportConfig = this.nativeAlertsConfig.sports.find((x) => x.id === sportId);
        if (sportConfig) {
            ids = sportConfig.alerts.filter((alert) => alert.isTop).reduce((array, alert) => array.concat(this.extractIds(alert)), [] as number[]);
        }

        return ids;
    }

    private extractIds = (alert: Alert): number[] => {
        if (!alert.id && alert.containedAlerts) {
            return alert.containedAlerts.filter((x) => x.id !== null).map((x) => x.id as number);
        }

        return !alert.id ? [] : [alert.id];
    };

    isSportEnabled(sportId: number): boolean {
        const sportConfigs = this.nativeAlertsConfig.sports.filter((x) => x.id === sportId);

        return sportConfigs.length > 0;
    }

    isEnabled(): boolean {
        return this.nativeAlertsConfig.enabled;
    }

    get fetchingInterval(): number {
        return this.nativeAlertsConfig.fetchingInterval * this.minuteInMiliseconds;
    }
}
